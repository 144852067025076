
  <nav class="subnav">
    <div class="prev">
     
    </div>
    <h2>Share Your Story</h2>
    <div class="next">
      <a [routerLink]="['/social','healthcare-professional']">
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'circle-arrow-right']"></fa-icon></span
      ></a>
    </div>
  </nav>
  <div class="relative">
  <div *ngIf="isLoading">
    <app-loading-spinner [inline]="1"></app-loading-spinner>
  </div>

  <div class="container relative pb-3 fh">
    <div class="row">
      <div class="col">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="['/social', 'forum']">Forum</a>
            </li>
            <li class="breadcrumb-item">
              <a [routerLink]="['/social', 'forum', 'category', category.forum_category_id]">{{ category.title }}</a>
            </li>
          </ol>
        </nav>

        <h3 class="mt-1 mb-3 sm-title">New Topic</h3>

        
      </div>
    </div>
    <div class="row">
      <div class="col">
        <form class="topic-form" (ngSubmit)="onSubmit()" #form="ngForm">

          <div class="form-group">
            <label for="title"
              >Topic Title</label
            >
          <input
            type="text"
            class="form-control"
            placeholder="Write your topic title..."
            name="title"
            id="title"
            rows="4"
            ngModel
            #title="ngModel"
            required
            maxlength="200"
            minlength="3"
            autocomplete="off"
          />
          </div>
          <div class="form-group">
            <label for="post"
            >Post</label
          >
          <textarea
            class="form-control"
            placeholder="Write your post..."
            name="post"
            id="post"
            rows="4"
            ngModel
            #post="ngModel"
            required
            maxlength="65535"
            minlength="3"
            autocomplete="off"
          ></textarea>
          </div>
          <div class="text-end">
            <button class="btn btn-primary" [disabled]="!post.valid">
              Add Topic
              <span class="icon-wrap"
                ><fa-icon
                  [icon]="['far', 'paper-plane-top']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
