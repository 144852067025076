import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeago',
})
export class TimeagoPipe implements PipeTransform {
  transform(date?: string, ...args: unknown[]): string {
    return moment(date).fromNow();
  }
}
