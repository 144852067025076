export class UserStrengthWorkout {
    constructor(
      public user_strength_workout_id: number,
      public user_id: number,
      public workout_date: string,
      public level:number,
      public weight: number,
      public set1_reps: string,
      public set2_reps: string,
      public set3_reps: string,
      public difficulty: number,
      public exercises: any,
      public created: string,
      public modified: string,
    ) {}
  }
