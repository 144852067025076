<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <div class="prev">
    <a href=""
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-left']"></fa-icon></span
    ></a>
  </div>
  <h2>Overview</h2>
  <div class="next">
    <a [routerLink]="['/activity', 'move', 'level', '1']">
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-right']"></fa-icon></span
    ></a>
  </div>
</nav>
<div class="container container-inner">
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="card mt-3 bg-dark text-white mb-3">
        <img
          src="/assets/img/placeholder-breathe.jpg"
          class="card-img-top"
          alt="..."
        />
        <div class="card-body text-center">
          <h3 class="card-title card-title-lg">Strengthen lungs for surgery</h3>
          <p class="card-text">
            Reduce the risk of postoperative complications through IMT training,
            which consists of twice daily practice to increase your load level.
          </p>

          <hr />
          <ng-container *ngIf="currentLevel">
            <h4 class="card-subtitle card-subtitle-lg">Current Load</h4>
            <div
              class="card-total card-total-light card-total-lg card-total-nb"
            >
              <span>{{ currentLevel }}</span> /
              <span class="opacity-75">106</span>
            </div>
          </ng-container>
        </div>
      </div>
      <button class="btn btn-primary w-100" [routerLink]="['/activity','breathe','add-entry']">
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'circle-plus']"></fa-icon
        ></span>
        Add Entry
      </button>
    </div>
    <div class="col-12 col-md-6">
      <h4 class="text-center mt-4 mb-2">LAST 7 DAYS SUMMARY</h4>
      <div class="card bg-light">
        <div class="card-body">
          <div class="d-flex justify-content-center mb-2" *ngIf="currentLevel">
            <div class="stat stat-flex">
              <div class="stat-title-lg text-end">
                Current <br />
                Load
              </div>
              <div class="stat-data-lg mx-2 text-dark-green">
                {{ currentLevel }}
              </div>
              <div class="stat-title-lg text-center" *ngIf="levelChange">
                {{ levelChange.label }} FROM <br />
                <span class="stat-title-subtext text-dark-green"
                  >{{ levelChange.prev }} to {{ levelChange.current }} (<ng-container *ngIf="+levelChange.change>0">+</ng-container>{{
                    levelChange.change
                  }})</span
                >
              </div>
            </div>
          </div>
          <div class="days-indicator days-indicator-lg text-center">
            <div class="day" *ngFor="let day of breatheWeekData">
              <div class="day-label">
                {{ day.date | customDate: "dddd" | slice: 0:1 }}
              </div>
              <div class="day-status-col day-status-lg bg-primary">
                <div class="text-center">
                  <div class="day-status-subtitle">AM</div>
                  <div class="icon-wrap" *ngIf="day.am.breaths > 0">
                    <fa-icon [icon]="['fas', 'check']"></fa-icon>
                  </div>
                  <div class="day-status-title" *ngIf="day.am.breaths < 1 && day.date <= today">
                    -
                  </div>
                  <div class="day-status-title" *ngIf="day.date > today">&nbsp;</div>
                </div>
                <div class="text-center">
                  <div class="day-status-subtitle">PM</div>
                  <div class="icon-wrap" *ngIf="day.pm.breaths > 0">
                    <fa-icon [icon]="['fas', 'check']"></fa-icon>
                  </div>
                  <div class="day-status-title" *ngIf="day.pm.breaths < 1 && day.date <= today">
                    -
                  </div>
                  <div class="day-status-title" *ngIf="day.date > today">&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="card-icon text-center">
            <span class="icon-wrap text-dark-green"
              ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
            ></span>
          </div>
          <p class="card-text text-center text-dark">
            Train with your IMT device twice a day. Load increases after 30
            comfortable breaths.
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6" *ngIf="breatheLast7Days">
      <h4 class="text-center mt-4 mb-2">Recent Entries</h4>
      <div class="card bg-light">
        <div class="card-body">
          <table class="entries-table">
            <thead>
              <tr>
                <th></th>
                <th>AM</th>
                <th>PM</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let entry of breatheLast7Days;">
              <tr *ngIf="entry.am.breaths>0 || entry.pm.breaths>0" >
                <td>
                  <span class="et-cell text-dark-green"
                        ><span class="et-total">{{
                          entry.date | customDate: "DD"
                        }}</span
                        ><span class="et-label">{{
                          entry.date | customDate: "MMM"
                        }}</span>
                      </span>
                </td>
                <td>
                  <span class="et-cell">
                    <span class="et-cell-row" *ngIf="entry.am.breaths>0">
                      <app-difficulty-icon [difficulty]="+entry.am.intensity/2"></app-difficulty-icon>
                      <span
                        ><span class="et-total">{{entry.am.resistance}}</span
                        ><span class="et-label">load</span></span
                      >
                    </span>
                  </span>
                </td>
                <td>
                  <span class="et-cell">
                    <span class="et-cell-row" *ngIf="entry.pm.breaths>0">
                      <app-difficulty-icon [difficulty]="+entry.pm.intensity/2"></app-difficulty-icon>
                      <span
                        ><span class="et-total">{{entry.pm.resistance}}</span
                        ><span class="et-label">load</span></span
                      >
                    </span>
                  </span>
                </td>
                <td>
                  <span class="et-cell"
                    ><a href="">
                      <span class="icon-wrap text-secondary"
                        ><fa-icon
                          [icon]="['fas', 'circle-chevron-right']"
                        ></fa-icon></span></a
                  ></span>
                </td>
              </tr>

            </ng-container>
              
            </tbody>
          </table>
        </div>
        <div class="card-footer text-center relative text-dark bg-secondary">
          <a href="" class="stretched-link"
            >View all Entries &nbsp;
            <span class="icon-wrap more-link text-white"
              ><fa-icon
                [icon]="['fas', 'circle-chevron-right']"
              ></fa-icon></span
          ></a>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <h4 class="text-center mt-4 mb-2">Load Progression</h4>
      <div class="card bg-light">
        <div class="card-body">
          <tabset [justified]="true">
            <tab heading="Tier 1" id="tab1">
              <table class="entries-table entries-table-alt mt-4">
                <thead>
                  <tr>
                    <th></th>
                    <th>CURRENT LOAD</th>
                    <th></th>
                    <th>Next Load</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span class="et-cell">
                        <span class="icon-wrap text-white"
                          ><fa-icon [icon]="['fas', 'user']"></fa-icon
                        ></span>
                      </span>
                    </td>
                    <td>
                      <span class="et-cell">
                        <span class="et-total">34</span></span
                      >
                    </td>
                    <td>
                      <span class="et-cell">
                        <span class="icon-wrap text-dark-green"
                          ><fa-icon [icon]="['fas', 'arrow-right']"></fa-icon
                        ></span>
                      </span>
                    </td>
                    <td>
                      <span class="et-cell">
                        <span class="et-total">36</span></span
                      >
                    </td>
                    <td>
                      <span class="et-cell"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </tab>
            <tab heading="Tier 2" id="tab2"> </tab>
            <tab heading="Tier 3" id="tab3"> </tab>
            <tab heading="Tier 4" id="tab4"> </tab>
          </tabset>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <h4 class="text-center mt-4 mb-2">What is Breathe Well?</h4>
      <div class="card mt-3 bg-primary text-dark">
        <img
          src="/assets/img/placeholder-steps.jpg"
          class="card-img-top"
          alt="..."
        />

        <div class="card-footer text-center relative text-dark">
          <a [routerLink]="['/activity','breathe','resources']" class="stretched-link"
            >View &nbsp;
            <span class="icon-wrap more-link text-dark"
              ><fa-icon
                [icon]="['fas', 'circle-chevron-right']"
              ></fa-icon></span
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>
