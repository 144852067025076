<div class="sidebar-bg"></div>
<div class="sidebar" role="navigation" aria-label="Desktop side nav">
  <div class="sidebar-content" *ngIf="userData">
    <div class="logo">
      <img src="/assets/img/logo-prepwell-light.svg" alt="logo" class="logo" />
    </div>
    <form class="search-form goal-form mt-3" name="searchForm">
      <div class="form-group">
        <label for="q" class="sr-only">Search</label>

        <input
          type="text"
          class="form-control"
          placeholder="Search"
          name="q"
          id="q"
          #q="ngModel"
          ngModel
          (keyup.enter)="onSearch(q)"
        />
        <button
          (click)="onSearch(q)"
          class="btn-transparent btn btn-primary-outline"
        >
          <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'search']"></fa-icon
            ><span class="sr-only">Search</span></span
          >
        </button>
      </div>
    </form>

    <div class="ms-auto btn-group user-menu-group" dropdown>
      <a id="user-dropdown-btn-sv" dropdownToggle>
        <img src="{{avatarBase + userData.avatar}}" alt="user avatar" class="avatar rounded" width="40" height="40" *ngIf="userData.avatar">
        <span class="avatar-icon icon-wrap" *ngIf="!userData.avatar">
      <fa-icon [icon]="['fal', 'user']"></fa-icon>
    </span>
        <span class="user-name">{{userData.first_name + ' ' + userData.last_name}} </span>
        <span class="icon-wrap float-end"
          ><fa-icon [icon]="['far', 'chevron-down']"></fa-icon
        ></span>
        <span class="sr-only">User Menu</span>
      </a>
      <ul
        id="dropdown-user"
        *dropdownMenu
        class="dropdown-menu"
        role="menu"
        aria-labelledby="user-dropdown-btn-sb"
      >
        <li role="menuitem">
          <a class="dropdown-item" [routerLink]="['/update-details']"
            >Update Details</a
          >
        </li>
        <li role="menuitem">
          <a class="dropdown-item" [routerLink]="['/upload-avatar']"
            >Upload Avatar</a
          >
        </li>
        <li role="menuitem">
          <a class="dropdown-item" [routerLink]="['/change-password']"
            >Change Password</a
          >
        </li>
        <li role="menuitem">
          <a class="dropdown-item" [routerLink]="['']" (click)="onLogout()"
            >Logout</a
          >
        </li>
      </ul>
    </div>
    <nav class="sidebar-nav">
      <ul class="nav flex-column">
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="['/dashboard']"
            routerLinkActive="active"
          >
            <span class="nav-icon icon-wrap">
              <fa-icon [icon]="['fal', 'tasks']"></fa-icon>
            </span>
            <span class="nav-link-title">Dashboard</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="['/activity']"
            routerLinkActive="active"
          >
            <span class="nav-icon icon-wrap">
              <fa-icon [icon]="['fas', 'heartbeat']"></fa-icon>
            </span>
            <span class="nav-link-title">Move Well</span>
          </a>
          <ul class="nav flex-column fade-in animate-250">
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/activity', 'steps']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                Prep Steps
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/activity', 'fit']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                Prep Fit
              </a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/activity', 'move']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                Prep Strong
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/activity', 'breathe']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                Breathe Well
              </a>
            </li>
          </ul>
        </li>

        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="['/modules']"
            routerLinkActive="active"
          >
            <span class="nav-icon icon-wrap">
              <fa-icon [icon]="['fas', 'user-chart']"></fa-icon>
            </span>
            <span class="nav-link-title">Modules</span>
          </a>
          <ul class="nav flex-column fade-in animate-250">
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/modules', 'i-quit']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                I-Quit
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/modules', 'drinking-guide']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                Drink Guide
              </a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/modules', 'feel-well']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                Feel Well
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/modules', 'sleep-well']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                Sleep Well
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/modules', 'eat-well']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                Eat Well
              </a>
            </li>
          </ul>
        </li>

        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="['/more']"
            routerLinkActive="active"
          >
            <span class="nav-icon icon-wrap">
              <fa-icon [icon]="['fal', 'ellipsis-h']"></fa-icon
            ></span>
            <span class="nav-link-title">More</span>
          </a>
          <ul class="nav flex-column fade-in animate-250">
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/more', 'app']"
                routerLinkActive="active"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                Install Web App
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/more', 'about']"
                routerLinkActive="active"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                About
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/more', 'terms']"
                routerLinkActive="active"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                Terms and Conditions
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/more', 'privacy']"
                routerLinkActive="active"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                Privacy Policy
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</div>
