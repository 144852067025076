<div
  class="container mobile-footer-padding content-container"
  *ngIf="selectedExercises"
>
  <div class="row mt-0" *ngIf="step == 0">
    <div class="col">
      <div *ngIf="selectedExercises">
        <h2 class="text-center mb-3">Level {{ level }}</h2>

        <div class="form-group text-center mb-4">
          <label for="date mb-1">When did you complete this workout?</label>
          <div class="form-group-inner">
            <input
              type="text"
              id="date"
              class="form-control form-control-stub"
              [(ngModel)]="bsDate"
              #date="ngModel"
              name="date"
              placeholder="DD/MM/YYYY"
              required
              date
              bsDatepicker
              autocomplete="off"
              [maxDate]="maxDate"
              [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY',
                containerClass: 'theme-prepwell',
                showWeekNumbers: false
              }"
            />
            <div class="alert alert-danger" *ngIf="!date.valid && date.touched">
              Please enter a date above
            </div>
          </div>
        </div>

        <div class="text-center">
          <p>Which session did you do:</p>

          <div class="btn-group mb-3" role="group" aria-label="Basic example">
            <button
              type="button"
              class="btn btn-primary"
              (click)="onSetSession('A')"
            >
              Session A
            </button>
            <button
              type="button"
              class="btn btn-primary"
              (click)="onSetSession('B')"
            >
              Session B
            </button>
          </div>
        </div>
        <div *ngIf="selectedExercises.length > 0">
          <p>
            This session involves 3 sets of 8 reps of the following exercises:
          </p>
          <ul>
            <li *ngFor="let exercise of selectedExercises">
              {{ exercise.title }}
            </li>
          </ul>
          <button
            class="btn btn-primary w-100 btn-lg mt-4"
            (click)="step = step + 1"
            [disabled]="selectedExercises.length < 1"
          >
            Enter Workout
          </button>
        </div>
      </div>
    </div>
  </div>
  <div>
    <!-- the workout -->
    <div
      class="row mt-0"
      *ngIf="step > 0 && step < 4 && selectedExercises.length > 0"
    >
      <div class="col">
        <form action="" class="card-form">
          <h2 class="text-center mb-4">Set {{ set }}/3</h2>

          <div class="card bg-light mt-2">
            <div class="card-body">
              <ng-container *ngFor="let exercise of selectedExercises">
                <div class="row mt-0 align-items-center">
                  <div class="col col-7 text-left">
                    <label class="label-lg">
                      {{ exercise.title }}
                    </label>
                    <div class="instruction">Enter your rep score</div>
                  </div>
                  <div class="col col-3 text-end">
                    <input
                      type="text"
                      pattern="\d*"
                      maxlength="2"
                      class="form-control text-center"
                      name="reps_{{ exercise.strength_exercise_id }}"
                      id="reps_{{ exercise.strength_exercise_id }}"
                      [(ngModel)]="
                        repData['set' + set][exercise.strength_exercise_id].reps
                      "
                      placeholder="0"
                      #repDataEl
                      #repDataElV="ngModel"
                      autofocus="true"
                    />
                  </div>
                  <div class="col col-2">
                    <label>Reps</label>
                  </div>
                </div>

                <div class="form-group text-center mb-3 mt-2">
                  <div class="col-12">
                    <label for="intensity">Intensity</label>
                    <div class="form-group-inner">
                      <div class="stat stat-flex">
                        <div class="stat-data-lg">
                          {{
                            repData['set' + set][exercise.strength_exercise_id].intensity
                          }}
                        </div>
                        <div class="stat-title-lg">Intensity label</div>
                      </div>
                      <div class="temp-slider">
                        <ngx-slider
                          [(value)]="
                          repData['set' + set][exercise.strength_exercise_id].intensity
                          "
                          [options]="options"
                        ></ngx-slider>
                      </div>

                      <p class="card-text mt-2">
                        Drag the cursor above to select your intensity
                      </p>
                    </div>
                  </div>
                </div>
                <hr />
              </ng-container>
            </div>
          </div>

          <button
            class="btn btn-primary w-100 btn-lg mt-4"
            (click)="onNextStep()"
          >
            <span *ngIf="step < 3">Next Set</span>
            <span *ngIf="step === 3">Complete Workout</span>
          </button>
        </form>
      </div>
    </div>

    <div class="row mt-0" *ngIf="step == 4 && this.error">
      <h3 class="text-center">There was an error</h3>
      <div class="alert alert-danger">{{ error }}</div>
      <button class="btn btn-primary" (click)="step = step - 1">Back</button>
    </div>
    <div class="row mt-0" *ngIf="step == 4 && !this.error">
      <div class="col">
        <div class="submit-confirm">
          <h3>Excellent!</h3>
          <span class="icon-wrap icon-wrap-submitted"
            ><fa-icon [icon]="['far', 'thumbs-up']"></fa-icon
          ></span>
          <h4>Workout Details</h4>

          <div class="row">
            <div class="col-md-4 col-12" *ngFor="let _set of [1, 2, 3]">
              <div class="card card-widget mt-4 bg-light">
                <div class="card-body">
                  <div class="row mt-0">
                    <div class="col text-left">
                      <h3 class="card-title card-title-u">
                        SET {{ _set }}
                        <span class="float-end"
                          >{{ countReps(_set) }} Reps</span
                        >
                      </h3>
                    </div>
                  </div>
                  <div class="row text-left mt-0">
                    <div class="col small">
                      <span *ngFor="let exercise of selectedExercises">
                        <p class="mb-1">
                          {{ exercise.title }}
                          <span class="float-end"
                            >{{
                              repData["set" + _set][
                                exercise.strength_exercise_id
                              ].reps
                            }}
                            Reps</span
                          >
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card card-widget mt-4 bg-light">
                <div class="card-body">
                  <div class="d-flex justify-content-center mb-2">
                    <div class="stat stat-flex">
                      <div
                        class="icon-wrap card-icon-sm text-dark-green stat-icon-lg"
                      >
                        <fa-icon [icon]="$any(['fac', 'lunge'])"></fa-icon>
                      </div>
                      <div class="stat-data-lg">{{ countReps(0) }}</div>
                      <div class="stat-title-lg">Total<br />Reps</div>
                    </div>
                  </div>
                  <button
                    class="btn btn-primary"
                    [routerLink]="['/activity', 'move']"
                  >
                    Back to Move Well
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end the workout -->
  </div>
</div>
