

  <nav class="subnav">
    <div class="prev">
     
    </div>
    <h2>Share Your Story</h2>
    <div class="next">
      <a [routerLink]="['/social','healthcare-professional']">
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'circle-arrow-right']"></fa-icon></span
      ></a>
    </div>
  </nav>
<div class="relative">
  

  <div class="container relative pb-3">
    <div *ngIf="isLoading">
      <app-loading-spinner [inline]="1"></app-loading-spinner>
    </div>
    <div class="row">
      <div class="col">

        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/social', 'forum']">Forum</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/social', 'forum', 'category', category.forum_category_id]">{{category.title}}</a></li>
          </ol>
        </nav>
        <h3 class="mt-1 mb-3 sm-title">{{ topic.title }}</h3>
      </div>
    </div>
      <div class="row">
        <div class="col">
          <ul class="forum-posts">

            <li  *ngFor="let post of posts; let i = index;">
              <span class="flex-wrap">
                
                <img src="{{avatarBase + post.avatar}}" alt="user avatar" class="avatar rounded" width="40" height="40" *ngIf="post.avatar"> 
                <span class="avatar-icon icon-wrap" *ngIf="!post.avatar">
                  <fa-icon [icon]="['fal', 'user']"></fa-icon>
                </span>
    
  
                <span class="post-list-user">
                  <span class="post-list-username">{{ post.username }}</span>
                  <span class="post-list-userstatus"><!--Status level--></span>
                </span>
              </span>
               
                <span class="post-list-info">
                  <span class="post-list-info-date">{{ post.created | timeago:true }} at {{post.created | customDate : 'h:mma' | lowercase}}</span> 
                  <span class="post-list-info-num text-end">#{{i+1}}</span>
                </span>
                <span class="post-list-post" *ngIf="post.status==1 || post.status==3">
                  {{ post.post }} 
                </span>
                <span class="post-list-post" *ngIf="post.status==2">
                 This post is currently being moderated.
                </span>
              <hr>
              <div class="row">
                <div class="col"><a class="btn btn-xs btn-warning" [routerLink]="" (click)="onReportPost($event, post)" *ngIf="post.status==1">Report</a></div>
                <div class="col text-end"><a [routerLink]="" (click)="onReply($event, post)">Reply</a></div>
              </div>
            </li>


          </ul>
          
          <h4 class="oversized mt-2 mb-2">Reply to this topic</h4>
          <form class="post-form" (ngSubmit)="onSubmit()" #form="ngForm" id="postForm">
            <label for="post" class="sr-only">Your Reply</label>
            <textarea
              type="text"
              class="form-control"
              placeholder="Write your reply..."
              name="post"
              id="post"
              rows="6"
              ngModel
              #post="ngModel"
              required
              maxlength="65535"
              minlength="3"
              autocomplete="off"
            ></textarea>
            <div class="text-end mt-3">
            <button class="btn btn-primary btn-wide" [disabled]="!post.valid">
              Post Reply
              <span class="icon-wrap"
                ><fa-icon
                  [icon]="['far', 'paper-plane-top']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span>
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
</div>
