<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <div class="prev">
    <a href=""
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-left']"></fa-icon></span
    ></a>
  </div>
  <h2>Overview</h2>
  <div class="next">
    <a [routerLink]="['/activity', 'breathe', 'level', '1']">
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-right']"></fa-icon></span
    ></a>
  </div>
</nav>

<div class="container container-inner">
  <div class="row">
    <div class="col-12">
      <form
        name="entryForm"
        #entryForm="ngForm"
        class="standard-form mt-4"
        [hidden]="submitted"
      >
        <div class="row">
          <div class="col-12">
            <div class="form-group text-center">
              <label for="date">Date and Time</label>
              <div class="form-group-inner">
                <input
                  type="text"
                  id="date"
                  class="form-control form-control-stub"
                  [(ngModel)]="bsDate"
                  #date="ngModel"
                  name="date"
                  placeholder="DD/MM/YYYY"
                  required
                  date
                  bsDatepicker
                  autocomplete="off"
                  [maxDate]="maxDate"
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY',
                    containerClass: 'theme-prepwell',
                    showWeekNumbers: false
                  }"
                />

                <div class="time-picker-container">
                    <timepicker [(ngModel)]="bsDate" [mousewheel]="true" name="time" [max]="getMaxTime()"></timepicker>
                </div>
                
                
                <div
                  class="alert alert-danger"
                  *ngIf="!date.valid && date.touched"
                >
                  Please enter a date above
                </div>
              </div>
            </div>
            
            
            <div class="form-group text-center">
              <label for="duration">Breaths</label>
              <div class="form-group-inner">
               
                <input
                  type="number"
                  name="breaths"
                  class="form-control form-control-stub"
                  placeholder="e.g. 20"
                  id="breaths"
                  [ngModel]
                  #breaths="ngModel"
                  [min]="1"
                  [max]="106"
                  autocomplete="off"
                />

                <p class="card-text mt-2">
                  Please enter the number of breaths above
                </p>
                <div
                  class="alert alert-danger"
                  *ngIf="breaths.touched && breaths.invalid"
                >
                  This must be a number between 1 and 106.
                </div>
              </div>
            </div>
            <div class="form-group text-center">
                <label for="duration">Resistance</label>
                <div class="form-group-inner">
                 
                  <input
                    type="number"
                    name="resistance"
                    class="form-control form-control-stub"
                    placeholder="e.g. 20"
                    id="resistance"
                    [ngModel]
                    #resistance="ngModel"
                    [min]="1"
                    [max]="106"
                    autocomplete="off"
                  />
  
                  <p class="card-text mt-2">
                    Please enter the resistance level above
                  </p>
                  <div
                    class="alert alert-danger"
                    *ngIf="resistance.touched && resistance.invalid"
                  >
                    This must be a number between 1 and 106.
                  </div>
                </div>
              </div>
            <div class="form-group text-center">
              <label for="intensity">Intensity</label>
              <div class="form-group-inner">
                <div class="stat stat-flex">
                  <div class="stat-data-lg">{{ intensity }}</div>
                  <div class="stat-title-lg">Intensity label</div>
                </div>
                <div class="temp-slider">
                  <ngx-slider
                    [(value)]="intensity"
                    [options]="options"
                  ></ngx-slider>
                </div>

                <p class="card-text mt-2">Drag the cursor above to select</p>
              </div>
            </div>
            <div class="form-group text-center">
              <label>&nbsp;</label>
              <div>
                <button
                  (click)="onSubmitForm()"
                  class="btn btn-primary w-100"
                  [disabled]="date.invalid"
                >
                  Submit Entry
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div [hidden]="!submitted">
        <div class="submit-confirm">
          <h3>Excellent!</h3>
          <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'thumbs-up']"></fa-icon
          ></span>
          <h4>Entry Details</h4>
          <div class="entry-details">
            <div>
              <div class="label">Date</div>
              <div class="data">{{date.value | customDate : 'MMMM Do YYYY, h:mm:ss a'}}</div>
            </div>
            <div>
              <div class="label">Breaths</div>
              <div class="data">{{breaths.value}}</div>
            </div>
            <div>
              <div class="label">Resistance</div>
              <div class="data">{{resistance.value}}</div>
            </div>
            <div>
              <div class="label">Intensity</div>
              <div class="data">{{intensity}}</div>
            </div>
          </div>
          <button class="btn btn-primary w-100 mt-4" [routerLink]="['/activity', 'breathe']">Back to Breathe Well</button>
        </div>
      </div>
    </div>
  </div>
</div>
