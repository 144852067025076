<div
  [ngClass]="{
    'container-narrow':
      router.isActive('/register', true)
  }"
>
  <nav
    class="navbar navbar-expand-lg navbar-dark bg-primary navbar-main"
    [ngClass]="{ 'navbar-tall': tallNav }"
    aria-label="Main top navigation"
  >
    <div class="container justify-content-center align-items-start relative-md">
      <button
        (click)="onBack()"
        class="back-link btn btn-outline-primary"
        *ngIf="
          !router.isActive('/dashboard', true) &&
          !router.isActive('/register', true)
        "
      >
        <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
        <span class="sr-only">Back</span>
      </button>

      <a class="navbar-brand mx-auto position-absolute">
        <h1>
          <img
            [ngClass]="{ 'd-sm-none': home }"
            class="d-block"
            *ngIf="!pageTitle || home"
            src="/assets/img/logo-prepwell-dark.svg"
            alt="Logo"
          />
          <span *ngIf="pageTitle" [ngClass]="{ 'd-none d-sm-block': home }">{{
            pageTitle
          }}</span>
        </h1>
      </a>

      <div
        class="collapse navbar-collapse justify-content-end navbar-desktop"
      ></div>
      <div
        class="ms-auto btn-group"
        dropdown
        *ngIf="!router.isActive('/register', true)"
      >
        <button
          class="btn-nav-menu"
          id="user-dropdown-btn"
          (click)="onToggleAsideMenu()"
          type="button"
        >
          <fa-icon [icon]="['far', 'bars']"></fa-icon>
          <span class="sr-only">Menu</span>
        </button>
      </div>
    </div>
  </nav>
</div>
<app-aside-menu
  [hidden]="!showAside"
  (closeMenu)="onCloseMenu($event)"
></app-aside-menu>
