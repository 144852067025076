<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <div class="prev">
    <a href=""
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-left']"></fa-icon></span
    ></a>
  </div>
  <h2>Overview</h2>
  <div class="next"></div>
</nav>

<div class="container container-inner">
  <div class="row">
    <div class="col-12 col-md-6">
        <h4 class="text-center mt-4 mb-2">Update Step Target</h4>
      <div class="card bg-light">
        <div class="card-body">
          <form name="stepTargetForm" #stepTargetForm="ngForm">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="mins" class="form-label text-center w-100">Steps Per Day</label>
                  <input
                    type="number"
                    name="targetSteps"
                    class="form-control"
                    placeholder="e.g. 8000"
                    id="targetSteps"
                    [ngModel]
                    #targetSteps="ngModel"
                    [min]="1"
                    [max]="15000"
                    autocomplete="off"
                  />

                  <div
                    class="alert alert-danger"
                    *ngIf="targetSteps.touched && targetSteps.invalid"
                  >
                    This must be a number between 1 and 15000.
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                (click)="submitStepTarget()"
                class="btn btn-primary btn-sm mt-2 w-100"
                [disabled]="targetSteps.invalid"
              >
                SET TARGET
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
