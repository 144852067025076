<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <div class="prev">
    <a [routerLink]="prev" *ngIf="prev"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-left']"></fa-icon></span
    ></a>
  </div>
  <h2>Level {{ level }}</h2>
  <div class="next">
    <a [routerLink]="next" *ngIf="next">
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-right']"></fa-icon></span
    ></a>
  </div>
</nav>

<div class="container container-inner" *ngIf="userData">
  <div class="row">
    <div class="col-12 col-md-12">
      <div class="card mt-3 bg-dark text-white mb-3">
        <img
          src="/assets/img/placeholder-fit.jpg"
          class="card-img-top card-img-top-fh"
          alt="..."
        />
        <div class="card-body text-center">
          <div class="card-icon text-center">
            <span class="icon-wrap"
              ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
            ></span>
          </div>
          <p class="card-text" *ngIf="level">
            {{ levelData[level].target }}
          </p>
          <div *ngIf="level == +userData.fit_level">
            <hr />
            <div class="card-progress" *ngIf="progress != null">
              <div class="progress bg-dark border border-white">
                <div
                  class="progress-bar bg-white"
                  role="progressbar"
                  style="width: {{ progress }}%"
                  [attr.aria-valuenow]="progress"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>

            <h4
              class="card-subtitle card-subtitle-lg mt-2"
              *ngIf="cardioMinsWeekForTarget != null"
            >
              {{ cardioMinsWeekForTarget }} Minutes completed
            </h4>
          </div>
        </div>
      </div>
      <button
        class="btn btn-primary w-100"
        [routerLink]="['/activity', 'fit', 'add-entry']"
        *ngIf="level == +userData.fit_level"
      >
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'circle-plus']"></fa-icon
        ></span>
        Add Entry
      </button>
    </div>

    <ng-container *ngIf="level == userData.fit_level">
      <div class="col-12 col-md-6">
        <h4 class="text-center mt-4 mb-2">LAST 7 DAYS SUMMARY</h4>
        <div class="card bg-light">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-2">
              <div class="stat stat-flex">
                <div
                  class="icon-wrap card-icon-sm text-dark-green stat-icon-lg"
                >
                  <fa-icon [icon]="['far', 'stopwatch']"></fa-icon>
                </div>
                <div class="stat-data-lg">{{ minsRemaining }}</div>
                <div class="stat-title-lg">
                  Mins <br />
                  Required
                </div>
              </div>
            </div>

            <div class="days-indicator days-indicator-lg text-center">
              <div class="day" *ngFor="let day of cardioWeekData">
                <div class="day-label">
                  {{ day.date | customDate : "dddd" | slice : 0 : 1 }}
                </div>

                <div
                  class="day-status-lg bg-dark text-white"
                  *ngIf="day.date <= today && day.minutes > 0"
                >
                  <div class="day-status-title">{{ day.minutes }}</div>
                  <div class="day-status-subtitle">Mins</div>
                </div>
                <div
                  class="day-status-lg bg-dark text-white"
                  *ngIf="day.date <= today && day.minutes < 1"
                >
                  <div class="day-status-title">-</div>
                </div>
                <div
                  class="day-status-lg bg-dark text-white"
                  *ngIf="day.date > today"
                ></div>
              </div>
            </div>
            <p class="card-text text-center mt-2 px-2">
              <span *ngIf="+userData.fit_level == 1">
                <strong>{{ cardioMinsWeekForTarget }} out of 150</strong>
                minutes completed at a moderate intensity over the past 7 days.
              </span>

              <span *ngIf="+userData.fit_level == 2">
                <strong>{{ cardioMinsWeekForTarget }} out of 75</strong>
                minutes completed at a vigorous intensity over the past 7 days.
              </span>

              <span *ngIf="+userData.fit_level == 3">
                <strong>{{ cardioMinsWeekForTarget }} out of 150</strong>
                minutes completed at a vigorous intensity over the past 7 days.
              </span>
            </p>
          </div>
          <div class="card-footer text-center relative text-dark bg-primary">
            <a
              [routerLink]="['/activity', 'fit', 'add-entry']"
              class="stretched-link"
              >Add Entry &nbsp;
              <span class="icon-wrap more-link text-dark"
                ><fa-icon
                  [icon]="['fas', 'circle-chevron-right']"
                ></fa-icon></span
            ></a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6" *ngIf="cardioMinsWeek">
        <h4 class="text-center mt-4 mb-2">Last 7 days Entries</h4>
        <div class="card bg-light">
          <div class="card-body">
            <table class="entries-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Low</th>
                  <th>Mod</th>
                  <th>High</th>
                  <th>Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let entry of cardioLast7Days">
                  <tr *ngIf="entry.minutes > 0">
                    <td>
                      <span class="et-cell text-dark-green"
                        ><span class="et-total">{{
                          entry.date | customDate : "DD"
                        }}</span
                        ><span class="et-label">{{
                          entry.date | customDate : "MMM"
                        }}</span>
                      </span>
                    </td>
                    <td>
                      <span class="et-cell"
                        ><span class="et-total">{{ entry.low }}</span
                        ><span class="et-label">mins</span></span
                      >
                    </td>
                    <td>
                      <span class="et-cell"
                        ><span class="et-total">{{ entry.mod }}</span
                        ><span class="et-label">mins</span></span
                      >
                    </td>
                    <td>
                      <span class="et-cell"
                        ><span class="et-total">{{ entry.high }}</span
                        ><span class="et-label">mins</span></span
                      >
                    </td>
                    <td>
                      <span class="et-cell text-dark-green"
                        ><span class="et-total">{{ entry.minutes }}</span
                        ><span class="et-label">mins</span></span
                      >
                    </td>
                    <td>
                      <span class="et-cell"
                        ><a href="">
                          <span class="icon-wrap text-secondary"
                            ><fa-icon
                              [icon]="['fas', 'circle-chevron-right']"
                            ></fa-icon></span></a
                      ></span>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="card-footer text-center relative text-dark bg-primary">
            <a href="" class="stretched-link"
              >View all Entries &nbsp;
              <span class="icon-wrap more-link text-white"
                ><fa-icon
                  [icon]="['fas', 'circle-chevron-right']"
                ></fa-icon></span
            ></a>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="level != userData.fit_level">


      <div class="col-12 col-md-6" *ngIf="level< +userData.fit_level">
        <h4 class="text-center mt-4 mb-2">Level Completed</h4>
        <div class="card bg-primary">
          <div class="card-body">
            <div class="card-icon text-center text-dark card-icon-lg">
              <span class="icon-wrap"
                ><fa-icon [icon]="['far', 'check']"></fa-icon
              ></span>
            </div>
            <p class="card-text text-center">
              You have completed Level {{ level }}.
            </p>
          </div>
        </div>
      </div>


      <div class="col-12 col-md-6" *ngIf="level> +userData.fit_level">
        <h4 class="text-center mt-4 mb-2">Level Locked</h4>
        <div class="card bg-light">
          <div class="card-body">
            <div class="card-icon text-center text-dark card-icon-lg">
              <span class="icon-wrap"
                ><fa-icon [icon]="['far', 'lock']"></fa-icon
              ></span>
            </div>
            <p class="card-text">
              Level {{ level }} will become unlocked once you have completed
              Level {{ level - 1 }}.
            </p>
          </div>
        </div>
      </div>


    </ng-container>
  </div>
</div>
