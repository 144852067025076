<div class="container-inner">
  <h1 class="text-center my-3">Setup Activity Tracker</h1>


  <div  *ngIf="!trackerSyncStatus">
    <app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
  </div>

  <div *ngIf="trackerSyncStatus">
    <div *ngIf="trackerSyncStatus.status == 'success'">
        <div *ngIf="trackerSyncStatus.status=='success'">Your device is and we are receiving data.  You have {{ trackerSyncStatus.data.steps}} steps today. </div>
    </div>
    <div *ngIf="trackerSyncStatus.status != 'success' && trackerStatus">
        <div *ngIf="trackerStatus.status=='success'">Your device is connected</div>
        <div *ngIf="trackerStatus.status=='disconnected'" class="text-center">
            <a href="{{trackerStatus.url}}" class="btn btn-primary btn-lg">Connect Garmin</a>
        </div>
    </div>
  </div>
</div>
