export class UserDrink {
    constructor(
      public user_drink_id: number,
      public user_id: number,
      public date_recorded: string,
      public units: number,
      public drinks: number,
      public drink_details: string,
      public created: string,
      public modified: string,
    ) {}
  }
