<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <div class="prev">
    <a href=""
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-left']"></fa-icon></span
    ></a>
  </div>
  <h2>Overview</h2>
  <div class="next"></div>
</nav>

<div class="container container-inner">
  <div class="row">
    <div class="col-12 col-md-12">
      <div class="card mt-3 bg-dark text-white">
        <img
          src="/assets/img/placeholder-steps.jpg"
          class="card-img-top card-img-top-fh"
          alt="..."
        />
        <div class="card-body text-center">
          <h3 class="card-title card-title-lg">Keep moving and keep active</h3>
          <p class="card-text">
            Increase your baseline level of physical activity before surgery by
            building up to walk 10,000 steps a day by increasing your daily
            steps target by 1,500 steps each week.
          </p>
          <hr />
          <h4 class="card-subtitle card-subtitle-lg mt-3">Today's Steps</h4>
          <div
            class="card-total card-total-light card-total-lg card-total-nb"
            *ngIf="stepTarget"
          >
            <span class="text-primary">{{ todaySteps | number }}</span> /
            <span class="opacity-75">{{ stepTarget.target | number }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-6"
      *ngIf="userData && userData.device_type == 'manual'"
    >
      <h4 class="text-center mt-4 mb-2">Update Steps</h4>
      <div class="card bg-light mt-3">
        <div class="card-body">
          <form name="stepForm" #stepForm="ngForm">
            <div class="row">
              <div class="col-4">
                <div class="form-group text-center">
                  <label for="steps">Steps</label>
                  <input
                    type="number"
                    name="steps"
                    class="form-control form-control-sm"
                    placeholder="e.g. 8000"
                    id="steps"
                    [ngModel]="todaySteps"
                    #steps="ngModel"
                    [min]="1"
                    [max]="50000"
                    autocomplete="off"
                  />

                  <div
                    class="alert alert-danger"
                    *ngIf="steps.touched && steps.invalid"
                  >
                    This must be a number between 1 and 50000.
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group text-center">
                  <label for="date">Date</label>

                  <input
                    type="text"
                    id="date"
                    class="form-control form-control-sm"
                    [ngModel]="bsDate"
                    #date="ngModel"
                    name="date"
                    placeholder="DD/MM/YYYY"
                    required
                    date
                    bsDatepicker
                    autocomplete="off"
                    [maxDate]="maxDate"
                    [bsConfig]="{
                      dateInputFormat: 'DD/MM/YYYY',
                      containerClass: 'theme-prepwell',
                      showWeekNumbers: false
                    }"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="!date.valid && date.touched"
                  >
                    Please enter a date above
                  </div>
                </div>
              </div>

              <div class="col-4">
                <div class="form-group text-center">
                  <label>&nbsp;</label>
                  <div>
                    <button
                      (click)="submitStepForm()"
                      class="btn btn-primary"
                      [disabled]="steps.invalid"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <h4 class="text-center mt-4 mb-2">LAST 7 DAYS SUMMARY</h4>
      <div class="card bg-light" *ngIf="stepWeekData">
        <div class="card-body">
          <div class="d-flex justify-content-center mb-2">
            <div class="stat stat-flex">
              <div
                class="icon-wrap card-icon-sm icon-circle icon-circle-lg bg-primary text-dark"
              >
                <fa-icon [icon]="['far', 'walking']"></fa-icon>
              </div>
              <div class="stat-data-lg">{{ stepDaysHit }}</div>
              <div class="stat-title-lg">
                DAILY TARGETS <br />
                ACHIEVED
              </div>
            </div>
          </div>
          <div class="days-indicator days-indicator-lg text-center">
            <div class="day" *ngFor="let day of stepWeekData">
              <div class="day-label">
                {{ day.date | customDate : "dddd" | slice : 0 : 1 }}
              </div>

              <div
                class="day-status-lg bg-primary"
                *ngIf="+day.steps >= +stepTarget.target && day.date <= today"
              >
                <div class="icon-wrap">
                  <fa-icon [icon]="['far', 'check']"></fa-icon>
                </div>
                <div class="day-status-subtitle">{{ day.steps | number }}</div>
              </div>

              <div
                class="day-status-lg bg-dark text-white"
                *ngIf="+day.steps < +stepTarget.target && day.date < today"
              >
                <div class="day-status-title">{{ day.steps | number }}</div>
                <div class="day-status-subtitle">Steps</div>
              </div>

              <div
                class="day-status-lg bg-white"
                *ngIf="+day.steps < +stepTarget.target && day.date == today"
              >
                <div class="day-status-title">{{ day.steps | number }}</div>
                <div class="day-status-subtitle">Steps</div>
              </div>

              <div class="day-status-lg bg-white" *ngIf="day.date > today">
                <div class="day-status-title"></div>
                <div class="day-status-subtitle">-</div>
              </div>
            </div>
          </div>
          <hr />
          <div class="text-center card-strapline">
            <span class="icon-wrap"
              ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
            ></span>
            <span>{{ stepTarget.target | number }} steps a day</span>
          </div>
          <p class="card-info text-center">
            Target updated on
            {{ stepTarget.created | customDate : "ddd Do MMM" }}
          </p>
          <hr />
          <div class="row">
            <div class="col">
              <div class="stat">
                <div class="stat-title">
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'shoe-prints']"></fa-icon></span
                  >Total Steps
                </div>
                <div class="stat-data">
                  <strong>{{ totalSteps | number }}</strong
                  >/{{ stepTarget.target * 7 }}
                </div>
              </div>
            </div>
            <div class="col">
              <div class="stat" *ngIf="bestDay">
                <div class="stat-title">
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'star']"></fa-icon></span
                  >Best Day
                </div>
                <div class="stat-data">
                  <strong>{{ bestDay.steps | number }}</strong> steps on
                  {{ bestDay.date_recorded | customDate : "ddd Do MMM" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <h4 class="text-center mt-4 mb-2">What is Prep Steps?</h4>
      <div class="card mt-3 bg-primary text-dark">
        <img
          src="/assets/img/placeholder-steps.jpg"
          class="card-img-top"
          alt="..."
        />

        <div class="card-footer text-center relative text-dark">
          <a
            [routerLink]="['/activity', 'steps', 'resources']"
            class="stretched-link"
            >View &nbsp;
            <span class="icon-wrap more-link text-dark"
              ><fa-icon
                [icon]="['fas', 'circle-chevron-right']"
              ></fa-icon></span
          ></a>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <h4 class="text-center mt-4 mb-2">Recent Achievements</h4>
      <div class="card mt-3 bg-primary text-dark rounded">
        <div class="card-body bg-light">
          <div class="award-container">
            <div class="award">
              <div class="award-data">
                <div class="award-icon">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'walking']"></fa-icon>
                  </span>
                </div>
                <div class="award-total">25</div>
              </div>
              <div class="award-info">DAYS HIT <br />TARGET</div>
            </div>
            <div class="award award-square">
              <div class="award-data">
                <div class="award-icon">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'walking']"></fa-icon>
                  </span>
                </div>
                <div class="award-total">5</div>
              </div>
              <div class="award-info">DAYS Target <br />streak</div>
            </div>
          </div>
        </div>

        <div class="card-footer text-center relative text-dark">
          <a href="" class="stretched-link"
            >View &nbsp;
            <span class="icon-wrap more-link text-dark"
              ><fa-icon
                [icon]="['fas', 'circle-chevron-right']"
              ></fa-icon></span
          ></a>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <h4 class="text-center mt-4 mb-2">Prep Steps Settings</h4>
      <div class="card mt-3 bg-light rounded">
        <div class="card-body">
          <ul class="link-list">
            <li>
              <a [routerLink]="['/activity', 'tracker']">
                <span class="icon-wrap more-link"
                  ><fa-icon [icon]="['fas', 'watch-fitness']"></fa-icon
                ></span>
                <span class="link-label">Fitness Tracker</span>
                <span
                  class="badge bg-light text-dark"
                  *ngIf="userData && userData.device_type != 'manual'"
                  ><span class="icon-wrap more-link"
                    ><fa-icon [icon]="['far', 'check']"></fa-icon
                  ></span>
                  {{ userData.device_type | titlecase }} Active</span
                >
                
                  <span class="icon-wrap more-link duo-primary-dark">
                    <fa-icon [icon]="['fad', 'circle-chevron-right']"></fa-icon
                  ></span>
              </a>
            </li>
            <li>
              <a [routerLink]="['/activity', 'steps', 'target']">
                <span class="icon-wrap more-link"
                  ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
                ></span>
                <span class="link-label">Daily Steps Target</span>

                <span class="icon-wrap more-link duo-primary-dark">
                  <fa-icon [icon]="['fad', 'circle-chevron-right']"></fa-icon
                ></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
