import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { UserStrengthWorkoutService } from 'src/app/services/user-strength-workout.service';

@Component({
  selector: 'app-move-level',
  templateUrl: './move-level.component.html',
  styleUrls: ['./move-level.component.scss'],
})
export class MoveLevelComponent implements OnInit {
  isLoading = false;
  userData: any;
  allWorkouts: any;
  moveWeekData: any;
  moveDaysHit = 0;
  level:any;
  next?:any;
  prev?:any;
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public today = this.dateHelperService.getToday();

  moveLast7Days:any;
  levelData: any = {
    1: {
      target:
        'At least 150 minutes in total of moderate intensity aerobic training over a 7 day period.',
    },
    2: {
      target:
        'At least 75 minutes of vigorous intensity aerobic training logged over 7 days.',
    },
    3: {
      target:
        'Three sessions of 45-50 minutes vigorous aerobic training per week.',
    },
  };
  constructor(
    private dateHelperService: DateHelperService,
    private userService: UserService,
    private userStrengthWorkoutService: UserStrengthWorkoutService,
    private route:ActivatedRoute
  ) {}

  ngOnInit(): void {

    this.route.params.subscribe((params) => {
      this.level = this.route.snapshot.params.level;
      switch (+this.level) {
        case 1:
          this.prev = ['/activity', 'move'];
          this.next = ['/activity', 'move', 'level', '2'];
          break;

        case 2:
          this.prev = ['/activity', 'move', 'level', '1'];
          this.next = ['/activity', 'move', 'level', '3'];
          break;

        case 3:
          this.prev = ['/activity', 'move', 'level', '2'];
          this.next = null;
          break;
      }
    });

    this.isLoading = true;
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
    });

    forkJoin({
      allWorkouts: this.userStrengthWorkoutService.fetchAll(),
    }).subscribe(
      (responseData) => {
        this.allWorkouts = responseData.allWorkouts;

        this.moveWeekData = this.userStrengthWorkoutService.generateWeekArray(
          this.currentWeek,
          this.allWorkouts
        );
        this.moveDaysHit = 0;
        this.moveWeekData.forEach((day: any) => {
          if(day.total_reps>=144){this.moveDaysHit++;}
        });


        this.moveLast7Days = this.userStrengthWorkoutService.generatePastSevenDaysArray(
          this.allWorkouts
        );

        this.isLoading = false;
      },
      (error) => {}
    );

    
  }
}
