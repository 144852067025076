import { ForumService } from './../forum.service';
import { ForumCategory } from './../forumCategory.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-forum-topic-add',
  templateUrl: './forum-topic-add.component.html',
  styleUrls: ['./forum-topic-add.component.scss']
})
export class ForumTopicAddComponent implements OnInit {

  isLoading = false;
  error:any;
  private errorSub?: Subscription;
  categoryId!: number;
  category!: ForumCategory;

  @ViewChild('form', { static: false }) topicform?: NgForm;

  constructor(private forumService: ForumService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.categoryId = this.route.snapshot.params.id;
    this.errorSub = this.forumService.error.subscribe((errorMessage) => {
      this.error = errorMessage;
    });


    forkJoin({
      category: this.forumService.fetchCategory(this.categoryId),
    }
    ).subscribe(
      (responseData) => {
        this.category = responseData.category;
      },
      (error) => {
        this.error = error.message;
      }
    );
  }

  onSubmit() {
    this.forumService
    .createTopic(
      this.categoryId,
      this.topicform?.value.title,
      this.topicform?.value.post,
    )
    .subscribe(
      (responseData) => {
        // grab the latest posts
        console.log(responseData);
        this.topicform?.reset();
        this.router.navigate(['/social', 'forum', 'topic', responseData.body?.forum_topic_id]);
      },
      (error) => {
        this.error = error.message;
      }
    );
  }

}
