import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { UserDrinksService } from 'src/app/services/user-drinks.service';
import { UserEatWellService } from 'src/app/services/user-eat-well.service';
import { UserFeelsService } from 'src/app/services/user-feels.service';
import { UserSleepsService } from 'src/app/services/user-sleeps.service';
import { UserSmokingService } from 'src/app/services/user-smoking.service';

@Component({
  selector: 'app-modules-index',
  templateUrl: './modules-index.component.html',
  styleUrls: ['./modules-index.component.scss']
})
export class ModulesIndexComponent implements OnInit {
  isLoading = false;
  userData: any;
  allEatWells:any;
  eatWellWeekData:any;
  weekScore = 0;

  allSmokings:any;
  smokingWeekData:any;
  smokingTotals:any;
  smokingLastWeekData:any;
  smokingTotalsLastWeek:any;
  smokingChanges:any;


  allDrinks:any;
  drinkWeekData:any;
  drinkTotals:any;
  drinkLastWeekData:any;
  drinkTotalsLastWeek:any;
  drinkChanges:any;



  allFeels:any;
  feelWeekData:any;
  feelTotal:any;
  feelLastWeekData:any;
  feelTotalLastWeek:any;
  feelChange:any;

  allSleeps:any;
  sleepWeekData:any;
  sleepTotal:any;
  sleepLastWeekData:any;
  sleepTotalLastWeek:any;
  sleepChange:any;

  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public lastWeek: Date[] = this.dateHelperService.getLastWeek();
  public today = this.dateHelperService.getToday();

  constructor(
    private dateHelperService: DateHelperService,
    private userService: UserService,
    private router: Router,
    private userEatWellService: UserEatWellService,
    private userSmokingService:UserSmokingService,
    private userDrinkService:UserDrinksService,
    private userFeelService:UserFeelsService,
    private userSleepService:UserSleepsService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        this.isLoading = false;
      }
    });

    forkJoin({
      allEatWells: this.userEatWellService.fetchAll(),
      allSmokings: this.userSmokingService.fetchAll(),
      allDrinks: this.userDrinkService.fetchAll(),
      allFeels: this.userFeelService.fetchAll(),
      allSleeps: this.userSleepService.fetchAll()
    }).subscribe(
      (responseData) => {
        this.allEatWells = responseData.allEatWells;

        this.eatWellWeekData = this.userEatWellService.generateWeekArray(
          this.currentWeek,
          this.allEatWells
        );
        //
        this.allSmokings = responseData.allSmokings;
        
        this.smokingWeekData = this.userSmokingService.generateWeekArray(
          this.currentWeek,
          this.allSmokings
        );


        this.smokingLastWeekData = this.userSmokingService.generateWeekArray(
          this.lastWeek,
          this.allSmokings
        );


        this.smokingTotals = this.userSmokingService.geterateWeekTotals(this.smokingWeekData);
        this.smokingTotalsLastWeek = this.userSmokingService.geterateWeekTotals(this.smokingLastWeekData);
       
        this.smokingChanges = {};
        this.smokingChanges.totalCigarettes = this.smokingTotals.totalCigarettes - this.smokingTotalsLastWeek.totalCigarettes;
        this.smokingChanges.smokeFreeDays = this.smokingTotals.smokeFreeDays - this.smokingTotalsLastWeek.smokeFreeDays;

        //
        this.allDrinks = responseData.allDrinks;
        
        this.drinkWeekData = this.userDrinkService.generateWeekArray(
          this.currentWeek,
          this.allDrinks
        );

        this.drinkLastWeekData = this.userDrinkService.generateWeekArray(
          this.lastWeek,
          this.allDrinks
        );
        this.drinkTotals = this.userDrinkService.geterateWeekTotals(this.drinkWeekData);
        this.drinkTotalsLastWeek = this.userDrinkService.geterateWeekTotals(this.drinkLastWeekData);
       
        this.drinkChanges = {};
        this.drinkChanges.totalUnits = this.drinkTotals.totalUnits - this.drinkTotalsLastWeek.totalUnits;
        this.drinkChanges.drinkFreeDays = this.drinkTotals.drinkFreeDays - this.drinkTotalsLastWeek.drinkFreeDays;

      
        //
        this.allFeels = responseData.allFeels;
        
        this.feelWeekData = this.userFeelService.generateWeekArray(
          this.currentWeek,
          this.allFeels
        );

        this.feelLastWeekData = this.userFeelService.generateWeekArray(
          this.lastWeek,
          this.allFeels
        );
        this.feelTotal = this.userFeelService.getWeekScore(this.feelWeekData);
        this.feelTotalLastWeek = this.userFeelService.getWeekScore(this.feelLastWeekData);
        this.feelChange =this.feelTotal.average - this.feelTotalLastWeek.average;


        //
        this.allSleeps = responseData.allSleeps;
        
        this.sleepWeekData = this.userSleepService.generateWeekArray(
          this.currentWeek,
          this.allSleeps
        );

        this.sleepLastWeekData = this.userSleepService.generateWeekArray(
          this.lastWeek,
          this.allSleeps
        );
        this.sleepTotal = this.userSleepService.getWeekScore(this.sleepWeekData);
        this.sleepTotalLastWeek = this.userSleepService.getWeekScore(this.sleepLastWeekData);
        this.sleepChange =this.sleepTotal.average - this.sleepTotalLastWeek.average;


        this.isLoading = false;
      },
      (error) => {}
    );
  }
}
