export class UserEatWell {
    constructor(
      public user_eat_well_id: number,
      public user_id: number,
      public date_recorded: string,
      public fruit_veg: number,
      public protein: number,
      public fat: number,
      public carbs: number,
      public hydration: number,
      public created: string,
      public modified: string,
    ) {}
  }
