import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { UserTaskStatusService } from 'src/app/services/user-task-status.service';
import Swal from 'sweetalert2';
import { UserService } from '../../auth/service/user.service';
import { UserBreathWorkout } from '../../models/user-breath-workout.model';
import { UserCardioWorkout } from '../../models/user-cardio-workout.model';
import { DateHelperService } from '../../services/date-helper.service';
import { UserBreathWorkoutService } from '../../services/user-breath-workout.service';
import { UserCardioService } from '../../services/user-cardio.service';
import { UserStepService } from '../../services/user-step.service';
import { UserStrengthWorkoutService } from '../../services/user-strength-workout.service';
import { UserTargetService } from '../../services/user-target.service';

@Component({
  selector: 'app-activity-index',
  templateUrl: './activity-index.component.html',
  styleUrls: ['./activity-index.component.scss'],
})
export class ActivityIndexComponent implements OnInit {
  isLoading = false;
  allSteps: any;
  todaySteps = 0;
  stepTarget: any;
  stepWeekData: any;
  daysToSurgery = 0;
  currentWeekNumber = 0;
  userData: any;

  allBreathWorkouts?: UserBreathWorkout[];
  breatheWeekData: any;
  breatheLast7Days: any;
  currentBreathLevel: any;
  breathLevelChange: any;

  allCardioWorkouts?: UserCardioWorkout[];
  cardioWeekData: any;
  cardioMinsWeek = 0;
  cardioProgress = 0;
  cardioMinsWeekForTarget = 0;
  cardioMinsWeekModerate = 0;
  cardioMinsWeekVigorous = 0;

  allMoveWorkouts: any;
  moveWeekData: any;

  allTaskStatuses: any;

  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public today = this.dateHelperService.getToday();

  @ViewChild('stepTargetForm', { static: false }) stepTargetForm?: NgForm;
  constructor(
    private userStepService: UserStepService,
    private userTargetService: UserTargetService,
    private userService: UserService,
    private router: Router,
    private dateHelperService: DateHelperService,
    private userBreathWorkoutService: UserBreathWorkoutService,
    private userCardioService: UserCardioService,
    private userStrengthWorkoutService: UserStrengthWorkoutService,
    private userTaskStatusService: UserTaskStatusService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        this.currentWeekNumber = +userData.current_week;
        if (userData.surgery_date) {
          var a = moment(moment().format('YYYY-MM-DD'));
          var b = moment(userData.surgery_date);
          this.daysToSurgery = b.diff(a, 'days');
        }
      }
    });

    //
    forkJoin({
      userTargets: this.userTargetService.fetchAll(),
      allSteps: this.userStepService.fetchAll(),
      allBreathWorkouts: this.userBreathWorkoutService.fetchAll(),
      allCardioWorkouts: this.userCardioService.fetchAll(),
      allMoveWorkouts: this.userStrengthWorkoutService.fetchAll(),
      allUserTaskStatuses: this.userTaskStatusService.fetchAll(),
    }).subscribe(
      (responseData) => {
        this.allSteps = responseData.allSteps;
        this.stepTarget = this.userTargetService.getLatestTarget(
          responseData.userTargets,
          'step'
        );
        this.stepWeekData = this.userStepService.generateWeekArray(
          this.currentWeek,
          this.allSteps
        );
        this.todaySteps = this.userStepService.getDaySteps(
          moment().format('YYYY-MM-DD'),
          this.allSteps
        );

        // Prep Fit
        this.allCardioWorkouts = responseData.allCardioWorkouts;

        this.cardioWeekData = this.userCardioService.generateWeekArray(
          this.currentWeek,
          this.allCardioWorkouts
        );
        this.cardioMinsWeek = 0;
        this.cardioWeekData.forEach((day: any) => {
          this.cardioMinsWeek += +day.minutes;
          this.cardioMinsWeekModerate += day.moderateMinutes;
          this.cardioMinsWeekVigorous += day.vigorousMinutes;
        });

        switch (+this.userData.fit_level) {
          case 1:
            this.cardioMinsWeekForTarget +=
              this.cardioMinsWeekModerate + this.cardioMinsWeekVigorous;
            break;

          case 2:
            this.cardioMinsWeekForTarget += this.cardioMinsWeekVigorous;
            break;

          case 3:
            this.cardioMinsWeekForTarget += this.cardioMinsWeekVigorous;
            break;
        }

        switch (+this.userData.fit_level) {
          case 1:
            this.cardioProgress =
              ((this.cardioMinsWeekModerate + this.cardioMinsWeekVigorous) /
                150) *
              100;
            break;
          case 2:
            this.cardioProgress = (this.cardioMinsWeekVigorous / 75) * 100;
            break;
        }
        // end Prep Fit

        // Prep Strong
        this.allMoveWorkouts = responseData.allMoveWorkouts;
        this.moveWeekData = this.userStrengthWorkoutService.generateWeekArray(
          this.currentWeek,
          this.allMoveWorkouts
        );
        // end Prep Strong

        // Breathe Well
        this.allBreathWorkouts = responseData.allBreathWorkouts;

        this.breatheWeekData = this.userBreathWorkoutService.generateWeekArray(
          this.currentWeek,
          this.allBreathWorkouts
        );

        this.breatheLast7Days =
          this.userBreathWorkoutService.generatePastSevenDaysArray(
            this.allBreathWorkouts
          );
        this.currentBreathLevel = this.userBreathWorkoutService.getCurrentLevel(
          this.allBreathWorkouts
        );
        this.breathLevelChange = this.userBreathWorkoutService.getLevelChange(
          this.allBreathWorkouts
        );
        // End Breathe Well

        this.allTaskStatuses = responseData.allUserTaskStatuses;
        // have they read the move well activity?
        let readMoveWell = this.userTaskStatusService.checkTaskStatus(
          this.allTaskStatuses,
          'MoveWellIntro'
        );

        if (!readMoveWell) {
          // forward on
          this.router.navigate(['/activity', 'resources', 1]);
        }
        this.isLoading = false;
      },
      (error) => {}
    );
  }

  submitStepTarget() {
    this.userTargetService
      .create('step', this.stepTargetForm?.value.targetSteps, 'step')
      .subscribe((responseData) => {
        // refresh it
        Swal.fire({
          icon: 'success',
          title: 'Target Updated',
          text: 'Your target was successfully updated',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        this.router
          .navigateByUrl('/fooboo', { skipLocationChange: true })
          .then(() => this.router.navigate(['/dashboard']));
      });
  }
}
