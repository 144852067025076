import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserSmoking } from '../models/user-smoking.model';
import { DateHelperService } from './date-helper.service';

@Injectable({
  providedIn: 'root',
})
export class UserSmokingService {
  error = new Subject<string>();
  slug = '/user_smokings';
  resourceName = 'user_smokings';
  weeklyTarget = {
    threshold: 1,
    days: 3,
  };
  allWorkouts?: Observable<UserSmoking[]> | null = null;
  workoutsBetween?: any;

  constructor(
    private http: HttpClient,
    private dateHelperService: DateHelperService
  ) {}

  fetchBetween(startDate: Date, endDate: Date): Observable<UserSmoking[]> {
    if (!this.workoutsBetween) {
      this.workoutsBetween = {};
    }

    if (
      !this.workoutsBetween[
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
          '_' +
          this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      ]
    ) {
      let searchParams = new HttpParams();

      searchParams = searchParams.append(
        'startDate',
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD')
      );
      searchParams = searchParams.append(
        'endDate',
        this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      );
      this.workoutsBetween[
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
          '_' +
          this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      ] = this.http
        .get<any>(environment.apiUrl + this.slug, {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserSmoking[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.workoutsBetween[
      this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
        '_' +
        this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
    ];
  }
  fetchAll(): Observable<UserSmoking[]> {
    if (!this.allWorkouts) {
      this.allWorkouts = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserSmoking[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                // parse the exercise data
                if (item.exercises) {
                  item.exercises = JSON.parse(item.exercises);
                }
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allWorkouts;
  }

  clearCache() {
    this.allWorkouts = null;
    this.workoutsBetween = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const item = new UserSmoking(
            +responseData.user_smoking_id,
            +responseData.user_id,
            responseData.date_recorded,
            +responseData.cigarettes,
            +responseData.nicotine_replacement,
            +responseData.cessation,
            responseData.created,
            responseData.modified
          );
          return item;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    date_recorded: string,
    cigarettes: number,
    nicotine_replacement: number,
    cessation: number
  ) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      cigarettes,
      nicotine_replacement,
      cessation,
    };
    return this.http.post<UserSmoking>(
      environment.apiUrl + this.slug,
      payload,
      {
        observe: 'response',
      }
    );
  }

  update(
    id: number,
    date_recorded: string,
    cigarettes: number,
    nicotine_replacement: number,
    cessation: number
  ) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      cigarettes,
      nicotine_replacement,
      cessation,
    };
    return this.http.patch<UserSmoking>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }

  generateWeekArray(currentWeek: any, data: any) {
    const weekArray: any = [];
    currentWeek.forEach((day: any) => {
      let currentDay = {
        date: moment(day).format('YYYY-MM-DD'),
        cigarettes: 0,
        nicotine_replacement: 0,
        cessation: 0,
        hasData: false,
        entryId:0
      };
      data.forEach((item: UserSmoking) => {
        if (item.date_recorded === currentDay.date) {
          currentDay.cigarettes = +item.cigarettes;
          currentDay.nicotine_replacement = +item.nicotine_replacement;
          currentDay.cessation = +item.cessation;
          currentDay.hasData = true;
          currentDay.entryId = item.user_smoking_id
        }
      });
      weekArray.push(currentDay);
    });
    return weekArray;
  }

  getWeekScore(weekArray: any) {
    const weekScore = weekArray.reduce((accumulator: number, object: any) => {
      return accumulator + object.score;
    }, 0);
    return weekScore;
  }

  geterateWeekTotals(weekArray: any) {
    let totalCigarettes = 0;
    let smokeFreeDays = 0;
    let daysEntered = 0;
    weekArray.forEach((day: any) => {
      totalCigarettes+= +day.cigarettes;
      if(day.hasData){daysEntered++;}
      if(+day.cigarettes == 0 && moment(day.date).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD') && day.hasData){
        smokeFreeDays++;
      }
    });
    return { totalCigarettes, smokeFreeDays, daysEntered };
  }
  

  onlyUnique(value: any, index: number, self: any) {
    return self.indexOf(value) === index;
  }

  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
