import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { UserSleepsService } from 'src/app/services/user-sleeps.service';
import { UserTaskStatusService } from 'src/app/services/user-task-status.service';

@Component({
  selector: 'app-sleep-well-index',
  templateUrl: './sleep-well-index.component.html',
  styleUrls: ['./sleep-well-index.component.scss']
})
export class SleepWellIndexComponent implements OnInit {
  isLoading = false;
  userData: any;
  allEntries:any;
  sleepWellWeekData:any;
  weekScore = 0;

  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public today = this.dateHelperService.getToday();
  allTaskStatuses:any;

  constructor(
    private dateHelperService: DateHelperService,
    private userService: UserService,
    private router: Router,
  private userSleepWellService: UserSleepsService,
  private userTaskStatusService:UserTaskStatusService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        this.isLoading = false;
      }
    });

    
    forkJoin({
      allEntries: this.userSleepWellService.fetchAll(),
      allUserTaskStatuses: this.userTaskStatusService.fetchAll()
    }).subscribe(
      (responseData) => {
        this.allEntries = responseData.allEntries;

        this.sleepWellWeekData = this.userSleepWellService.generateWeekArray(
          this.currentWeek,
          this.allEntries
        );

        this.weekScore = this.userSleepWellService.getWeekScore(this.sleepWellWeekData);
       
        this.allTaskStatuses = responseData.allUserTaskStatuses;
        let readResources = this.userTaskStatusService.checkTaskStatus(
          this.allTaskStatuses,
          'SleepWellIntro'
        );
        if (!readResources) {
          this.router.navigate(['/modules', 'sleep-well', 'resources']);
        }

        this.isLoading = false;
      },
      (error) => {}
    );
  }
}
