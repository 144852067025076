import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin, interval } from 'rxjs';
import Swal from 'sweetalert2';
import { UserService } from '../auth/service/user.service';
import { UserBreathWorkout } from '../models/user-breath-workout.model';
import { UserCardioWorkout } from '../models/user-cardio-workout.model';
import { ActivityTrackerService } from '../services/activity-tracker.service';
import { DateHelperService } from '../services/date-helper.service';
import { UserBreathWorkoutService } from '../services/user-breath-workout.service';
import { UserCardioService } from '../services/user-cardio.service';
import { UserDrinksService } from '../services/user-drinks.service';
import { UserEatWellService } from '../services/user-eat-well.service';
import { UserFeelsService } from '../services/user-feels.service';
import { UserSleepsService } from '../services/user-sleeps.service';
import { UserSmokingService } from '../services/user-smoking.service';
import { UserStepService } from '../services/user-step.service';
import { UserStrengthWorkoutService } from '../services/user-strength-workout.service';
import { UserTargetService } from '../services/user-target.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  isLoading = false;
  allSteps: any;
  todaySteps = 0;
  stepTarget: any;
  stepWeekData: any;
  daysToSurgery = 0;
  currentWeekNumber = 0;
  userData: any;

  allBreathWorkouts?: UserBreathWorkout[];
  breatheWeekData: any;
  breatheLast7Days: any;
  currentBreathLevel: any;
  breathLevelChange: any;

  allCardioWorkouts?: UserCardioWorkout[];
  cardioWeekData: any;
  cardioMinsWeek = 0;
  cardioProgress = 0;

  allMoveWorkouts: any;
  moveWeekData: any;
  moveProgress = 0;
  moveWeekWorkouts = 0;

  allEatWells: any;
  eatWellWeekData: any;
  eatWellTotals:any;
  weekScore = 0;

  allSmokings: any;
  smokingWeekData: any;
  smokingTotals: any;
  smokingLastWeekData: any;
  smokingTotalsLastWeek: any;
  smokingChanges: any;

  allDrinks: any;
  drinkWeekData: any;
  drinkTotals: any;
  drinkLastWeekData: any;
  drinkTotalsLastWeek: any;
  drinkChanges: any;

  allFeels: any;
  feelWeekData: any;
  feelTotal: any;
  feelLastWeekData: any;
  feelTotalLastWeek: any;
  feelChange: any;

  allSleeps: any;
  sleepWeekData: any;
  sleepTotal: any;
  sleepLastWeekData: any;
  sleepTotalLastWeek: any;
  sleepChange: any;

  public lastWeek: Date[] = this.dateHelperService.getLastWeek();

  sugeryDateProgress = 0;

  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public today = this.dateHelperService.getToday();

  @ViewChild('stepTargetForm', { static: false }) stepTargetForm?: NgForm;
  constructor(
    private userStepService: UserStepService,
    private userTargetService: UserTargetService,
    private userService: UserService,
    private router: Router,
    private dateHelperService: DateHelperService,
    private userBreathWorkoutService: UserBreathWorkoutService,
    private userCardioService: UserCardioService,
    private userStrengthWorkoutService: UserStrengthWorkoutService,
    private userEatWellService: UserEatWellService,
    private userSmokingService: UserSmokingService,
    private userDrinkService: UserDrinksService,
    private userFeelService: UserFeelsService,
    private userSleepService: UserSleepsService,
    private activityTrackerService:ActivityTrackerService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        this.currentWeekNumber =
          moment().diff(userData.date_registered, 'week') + 1;

        if (userData.surgery_date) {
          var a = moment(moment().format('YYYY-MM-DD'));
          var b = moment(userData.surgery_date);
          this.daysToSurgery = b.diff(a, 'days');

          let totalDaysToSurgery = b.diff(
            moment(userData.date_registered),
            'days'
          );

          this.sugeryDateProgress = Math.round(
            ((totalDaysToSurgery - this.daysToSurgery) / totalDaysToSurgery) *
              100
          );
        }

        // sync data
        if(this.userData.device_type == 'garmin'){
          this.activityTrackerService.syncTracker('garmin').subscribe((responseData)=>{
            //console.log('activity checked')
          });
          }
      }
    });

    //
    forkJoin({
      userTargets: this.userTargetService.fetchAll(),
      allSteps: this.userStepService.fetchAll(),
      allBreathWorkouts: this.userBreathWorkoutService.fetchAll(),
      allCardioWorkouts: this.userCardioService.fetchAll(),
      allMoveWorkouts: this.userStrengthWorkoutService.fetchAll(),
      allEatWells: this.userEatWellService.fetchAll(),
      allSmokings: this.userSmokingService.fetchAll(),
      allDrinks: this.userDrinkService.fetchAll(),
      allFeels: this.userFeelService.fetchAll(),
      allSleeps: this.userSleepService.fetchAll(),
    }).subscribe(
      (responseData) => {
        this.allSteps = responseData.allSteps;
        this.stepTarget = this.userTargetService.getLatestTarget(
          responseData.userTargets,
          'step'
        );
        this.stepWeekData = this.userStepService.generateWeekArray(
          this.currentWeek,
          this.allSteps
        );
        this.todaySteps = this.userStepService.getDaySteps(
          moment().format('YYYY-MM-DD'),
          this.allSteps
        );

        // Prep Fit
        this.allCardioWorkouts = responseData.allCardioWorkouts;

        this.cardioWeekData = this.userCardioService.generateWeekArray(
          this.currentWeek,
          this.allCardioWorkouts
        );
        this.cardioMinsWeek = 0;
        this.cardioWeekData.forEach((day: any) => {
          this.cardioMinsWeek += +day.minutes;
        });

        switch (+this.userData.fit_level) {
          case 1:
            this.cardioProgress = (this.cardioMinsWeek / 150) * 100;
            break;
        }
        // end Prep Fit

        // Prep Strong
        this.allMoveWorkouts = responseData.allMoveWorkouts;
        this.moveWeekData = this.userStrengthWorkoutService.generateWeekArray(
          this.currentWeek,
          this.allMoveWorkouts
        );
        this.moveWeekWorkouts = 0;
        this.moveWeekData.forEach((workout: any) => {
          if (workout.total_reps > 0) {
            this.moveWeekWorkouts++;
          }
        });

        this.moveProgress = (this.moveWeekWorkouts / 2) * 100;
        if (this.moveProgress > 100) {
          this.moveProgress = 100;
        }

        // end Prep Strong

        // Breathe Well
        this.allBreathWorkouts = responseData.allBreathWorkouts;

        this.breatheWeekData = this.userBreathWorkoutService.generateWeekArray(
          this.currentWeek,
          this.allBreathWorkouts
        );

        this.breatheLast7Days =
          this.userBreathWorkoutService.generatePastSevenDaysArray(
            this.allBreathWorkouts
          );
        this.currentBreathLevel = this.userBreathWorkoutService.getCurrentLevel(
          this.allBreathWorkouts
        );
        this.breathLevelChange = this.userBreathWorkoutService.getLevelChange(
          this.allBreathWorkouts
        );
        // End Breathe Well

        // modules

        this.allEatWells = responseData.allEatWells;

        this.eatWellWeekData = this.userEatWellService.generateWeekArray(
          this.currentWeek,
          this.allEatWells
        );


        this.eatWellTotals = this.userEatWellService.geterateWeekTotals(
          this.eatWellWeekData
        );


        //
        this.allSmokings = responseData.allSmokings;

        this.smokingWeekData = this.userSmokingService.generateWeekArray(
          this.currentWeek,
          this.allSmokings
        );

        this.smokingLastWeekData = this.userSmokingService.generateWeekArray(
          this.lastWeek,
          this.allSmokings
        );

        this.smokingTotals = this.userSmokingService.geterateWeekTotals(
          this.smokingWeekData
        );
        this.smokingTotalsLastWeek = this.userSmokingService.geterateWeekTotals(
          this.smokingLastWeekData
        );

        this.smokingChanges = {};
        this.smokingChanges.totalCigarettes =
          this.smokingTotals.totalCigarettes -
          this.smokingTotalsLastWeek.totalCigarettes;
        this.smokingChanges.smokeFreeDays =
          this.smokingTotals.smokeFreeDays -
          this.smokingTotalsLastWeek.smokeFreeDays;

        //
        this.allDrinks = responseData.allDrinks;

        this.drinkWeekData = this.userDrinkService.generateWeekArray(
          this.currentWeek,
          this.allDrinks
        );

        this.drinkLastWeekData = this.userDrinkService.generateWeekArray(
          this.lastWeek,
          this.allDrinks
        );
        this.drinkTotals = this.userDrinkService.geterateWeekTotals(
          this.drinkWeekData
        );
        this.drinkTotalsLastWeek = this.userDrinkService.geterateWeekTotals(
          this.drinkLastWeekData
        );

        this.drinkChanges = {};
        this.drinkChanges.totalUnits =
          this.drinkTotals.totalUnits - this.drinkTotalsLastWeek.totalUnits;
        this.drinkChanges.drinkFreeDays =
          this.drinkTotals.drinkFreeDays -
          this.drinkTotalsLastWeek.drinkFreeDays;

        //
        this.allFeels = responseData.allFeels;

        this.feelWeekData = this.userFeelService.generateWeekArray(
          this.currentWeek,
          this.allFeels
        );

        this.feelLastWeekData = this.userFeelService.generateWeekArray(
          this.lastWeek,
          this.allFeels
        );
        this.feelTotal = this.userFeelService.getWeekScore(this.feelWeekData);
        this.feelTotalLastWeek = this.userFeelService.getWeekScore(
          this.feelLastWeekData
        );
        this.feelChange =
          this.feelTotal.average - this.feelTotalLastWeek.average;

        //
        this.allSleeps = responseData.allSleeps;

        this.sleepWeekData = this.userSleepService.generateWeekArray(
          this.currentWeek,
          this.allSleeps
        );

        this.sleepLastWeekData = this.userSleepService.generateWeekArray(
          this.lastWeek,
          this.allSleeps
        );
        this.sleepTotal = this.userSleepService.getWeekScore(
          this.sleepWeekData
        );
        this.sleepTotalLastWeek = this.userSleepService.getWeekScore(
          this.sleepLastWeekData
        );
        this.sleepChange =
          this.sleepTotal.average - this.sleepTotalLastWeek.average;

        // end modules

        this.isLoading = false;
      },
      (error) => {}
    );
  }

  submitStepTarget() {
    this.userTargetService
      .create('step', this.stepTargetForm?.value.targetSteps, 'step')
      .subscribe((responseData) => {
        // refresh it
        Swal.fire({
          icon: 'success',
          title: 'Target Updated',
          text: 'Your target was successfully updated',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        this.router
          .navigateByUrl('/fooboo', { skipLocationChange: true })
          .then(() => this.router.navigate(['/dashboard']));
      });
  }
}
