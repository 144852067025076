

  <nav class="subnav">
    <div class="prev">
     
    </div>
    <h2>Share Your Story</h2>
    <div class="next">
      <a [routerLink]="['/social','healthcare-professional']">
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'circle-arrow-right']"></fa-icon></span
      ></a>
    </div>
  </nav>
<div class="relative  bg-light fh">
  <div *ngIf="isLoading">
    <app-loading-spinner [inline]="1"></app-loading-spinner>
  </div>

  <div class="container relative  pb-3">
      <div class="row">
        <div class="col">

          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a [routerLink]="['/social', 'forum']">Forum</a></li>
            </ol>
          </nav>

         

          <div class="row">
            <div class="col">
              <h3 class="mt-1 mb-3 sm-title">{{ category?.title }}</h3>
            </div>
            <div class="col text-end">
              <a
            [routerLink]="[
              '/social',
              'forum',
              'topic-add',
              category?.forum_category_id
            ]"
            class="btn btn-primary"
            >New Topic  <span class="icon-wrap"
            ><fa-icon
              [icon]="['far', 'paper-plane-top']"
              [fixedWidth]="true"
            ></fa-icon
          ></span></a
          >
            </div>
          </div>

          
        </div>
      </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col">
        <ul class="forum-list">
          <li *ngFor="let topic of topics">
            <a [routerLink]="['/social', 'forum', 'topic', topic.forum_topic_id]">

              <img src="{{avatarBase + topic.avatar}}" alt="user avatar" class="avatar rounded" width="40" height="40" *ngIf="topic.avatar"> 
                <span class="avatar-icon icon-wrap" *ngIf="!topic.avatar">
                  <fa-icon [icon]="['fal', 'user']"></fa-icon>
                </span>
              <span class="forum-list-details">
                <span class="forum-list-title">{{ topic.title }}</span>
                <span class="forum-list-username">{{topic.username}}</span>
                <span class="forum-list-info">
                  Replies: {{topic.replies}}  |  {{ topic.modified | timeago:true }} at {{topic.modified | customDate : 'h:mma' | lowercase}}
                </span>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="container relative  pt-3 pb-3">
  <div class="row">
    <div class="col">

    </div>
    <div class="col text-end">
      <a
    [routerLink]="[
      '/social',
      'forum',
      'topic-add',
      category?.forum_category_id
    ]"
    class="btn btn-primary"
    >New Topic  <span class="icon-wrap"
    ><fa-icon
      [icon]="['far', 'paper-plane-top']"
      [fixedWidth]="true"
    ></fa-icon
  ></span></a
  >
    </div>
  </div>
</div>

</div>
