<div class="container-inner">
    <div class="row">
        <div class="col-12 col-md-12">
            <div class="card mt-3 bg-primary text-dark relative">
                <img
                  src="/assets/img/placeholder-programme-1.jpg"
                  class="card-img-top"
                  alt="..."
                />
        
                <div class="card-footer text-white  text-center">
                  <a [routerLink]="['/programme','task-list']" class="stretched-link"
                    >Task List &nbsp;
                    <span class="icon-wrap more-link text-white"
                      ><fa-icon
                        [icon]="['fas', 'circle-chevron-right']"
                      ></fa-icon></span
                  ></a>
                </div>
              </div>
        </div>
        <div class="col-12 col-md-12">
            <div class="card mt-3 bg-primary text-dark">
                <img
                  src="/assets/img/placeholder-programme-2.jpg"
                  class="card-img-top"
                  alt="..."
                />
        
                <div class="card-footer text-center relative text-dark">
                  <a [routerLink]="['/programme','progress']"  class="stretched-link"
                    >Progress &nbsp;
                    <span class="icon-wrap more-link text-white"
                      ><fa-icon
                        [icon]="['fas', 'circle-chevron-right']"
                      ></fa-icon></span
                  ></a>
                </div>
              </div>
        </div>
        <div class="col-6">
            <div class="col-12 col-md-12">
                <div class="card mt-3 bg-primary text-dark">
                    <img
                      src="/assets/img/placeholder-programme-3.jpg"
                      class="card-img-top"
                      alt="..."
                    />
            
                    <div class="card-footer text-center relative text-dark">
                      <a href="" class="stretched-link"
                        >Task List &nbsp;
                        <span class="icon-wrap more-link text-white"
                          ><fa-icon
                            [icon]="['fas', 'circle-chevron-right']"
                          ></fa-icon></span
                      ></a>
                    </div>
                  </div>
            </div>
        </div>
        <div class="col-6">
            <div class="col-12 col-md-12">
                <div class="card mt-3 bg-primary text-dark">
                    <img
                      src="/assets/img/placeholder-programme-4.png"
                      class="card-img-top"
                      alt="..."
                    />
            
                    <div class="card-footer text-center relative text-dark">
                      <a href="" class="stretched-link"
                        >Task List &nbsp;
                        <span class="icon-wrap more-link text-white"
                          ><fa-icon
                            [icon]="['fas', 'circle-chevron-right']"
                          ></fa-icon></span
                      ></a>
                    </div>
                  </div>
            </div>
        </div>
    </div>
</div>