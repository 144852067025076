import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { UserEatWell } from 'src/app/models/user-eat-well.model';
import { UserEatWellService } from 'src/app/services/user-eat-well.service';

@Component({
  selector: 'app-eat-well-edit-entry',
  templateUrl: './eat-well-edit-entry.component.html',
  styleUrls: ['./eat-well-edit-entry.component.scss']
})
export class EatWellEditEntryComponent implements OnInit {
  isLoading = false;
  entryDate = new Date();
  maxDate = new Date();
  userData: any;
  submitted = false;
  allEntries:any;
  entryId:any;
  userEatWell:UserEatWell | undefined;

  @ViewChild('entryForm', { static: false }) entryForm?: NgForm;
  constructor(
    private userEatWellService: UserEatWellService,
    private router: Router,
    private userService: UserService,
    private el: ElementRef,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
    });



    this.route.params.subscribe((params) => {
      this.entryId = params.entryId;
      this.isLoading = true;
      forkJoin({
        userEatWell: this.userEatWellService.fetch(this.entryId),
      }).subscribe(
        (responseData) => {
          this.isLoading = false;
          this.userEatWell = responseData.userEatWell;
          this.entryDate = moment(this.userEatWell.date_recorded).toDate();
        },
        (error) => {
         // this.errors.push(error.message);
        }
      );
    });
  }

  onSubmitForm() {
    this.userEatWellService
      .update(
        this.entryId,
        moment(this.entryForm?.value.date).format('YYYY-MM-DD'),
        +this.entryForm?.value.fruit_veg.fruit_veg,
        +this.entryForm?.value.protein.protein,
        +this.entryForm?.value.fat.fat,
        +this.entryForm?.value.carbs.carbs,
        +this.entryForm?.value.hydration.hydration,
      )
      .subscribe((responseData) => {
        window.scroll(0, 0);
        this.submitted = true;
        /*
        // refresh it
        Swal.fire({
          icon: 'success',
          title: 'Workout Added',
          text: 'Your workout was added',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/activity', 'fit', 'level', this.userData.fit_level]));*/
      });
  }

    // validattion stuff
    public scrollToFirstInvalidControl() {
      const firstInvalidControl: HTMLElement =
        this.el.nativeElement.querySelector('form .ng-invalid');
  
      if (firstInvalidControl) {
        firstInvalidControl.focus();
        window.scroll({
          top: this.getTopOffset(firstInvalidControl),
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  
    public getTopOffset(controlEl: HTMLElement): number {
      const labelOffset = 150;
      return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }
  
    @HostListener('window:beforeunload')
    canDeactivate(): Observable<any> | any {
      // insert logic to check if there are pending changes here;
      // returning true will navigate without confirmation
      // returning false will show a confirm dialog before navigating away
      if (this.submitted) {
        return true;
      } else if (
        !this.entryForm?.value.fruit_veg.fruit_veg &&
        !this.entryForm?.value.protein.protein &&
        !this.entryForm?.value.fat.fat &&
        !this.entryForm?.value.carbs.carbs &&
        !this.entryForm?.value.hydration.hydration
      ) {
        return true;
      }
    }
}
