import { Options } from '@angular-slider/ngx-slider';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { UserService } from 'src/app/auth/service/user.service';
import { UserBreathWorkoutService } from 'src/app/services/user-breath-workout.service';

@Component({
  selector: 'app-breathe-add-entry',
  templateUrl: './breathe-add-entry.component.html',
  styleUrls: ['./breathe-add-entry.component.scss']
})
export class BreatheAddEntryComponent implements OnInit {
  isLoading = false;
  bsDate = new Date();
  maxDate = new Date();
  userData:any;
  submitted = false;
  exercises:any;
  durations = [
    '1-5', '10', '15','20','25','30','35','40','45','50','55','60+'
  ];
  intensity: number = 5;
  options: Options = {
    floor: 0,
    ceil: 10,
    hideLimitLabels:true,
    hidePointerLabels:true,
    showTicks:true,
    getTickColor: (value: number): string => {
      if (value ==1) {
        return '#1D8670';
      }
      if (value == 2) {
        return '#608F62';
      }
      if (value == 3) {
        return '#879359';
      }
      if (value == 4) {
        return '#B39950';
      }
      if (value == 5) {
        return '#DB9E47';
      }
      if (value == 6) {
        return '#EF9742';
      }
      if (value == 7) {
        return '#ED853F';
      }
      if (value == 8) {
        return '#EA723D';
      }
      if (value == 9) {
        return '#E8603B';
      }
      if (value == 10) {
        return '#E54B38';
      }
      return '#cccccc';
    }
  };

  @ViewChild('entryForm', { static: false }) entryForm?: NgForm;
  constructor(
    private userBreathWorkoutService: UserBreathWorkoutService,
    private router: Router,
    private userService:UserService
  ) {}

  ngOnInit(): void {

    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
    });

  }

  onSubmitForm() {
    this.userBreathWorkoutService
      .create(
        moment(this.entryForm?.value.date).format('YYYY-MM-DD  HH:mm:ss'),
        +this.entryForm?.value.breaths,
        +this.entryForm?.value.resistance,
        +this.intensity
      )
      .subscribe((responseData) => {
        window.scroll(0,0);
        this.submitted = true;
        /*
        // refresh it
        Swal.fire({
          icon: 'success',
          title: 'Workout Added',
          text: 'Your workout was added',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/activity', 'fit', 'level', this.userData.fit_level]));*/
      });
  }
  getMaxTime(){
    if(moment(this.bsDate).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
      return new Date();
    }
    else{
      let maxTime = new Date();
      maxTime.setHours(23);
      maxTime.setMinutes(59);
      return maxTime;
    }
  }
}
