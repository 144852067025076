import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { UserEatWellService } from 'src/app/services/user-eat-well.service';
import { UserTaskStatusService } from 'src/app/services/user-task-status.service';

@Component({
  selector: 'app-i-quit-index',
  templateUrl: './i-quit-index.component.html',
  styleUrls: ['./i-quit-index.component.scss']
})
export class IQuitIndexComponent implements OnInit {
  isLoading = false;
  userData: any;
  allEntries:any;
  eatWellWeekData:any;
  weekScore = 0;
  allTaskStatuses:any;

  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public today = this.dateHelperService.getToday();

  constructor(
    private dateHelperService: DateHelperService,
    private userService: UserService,
    private router: Router,
    private userEatWellService: UserEatWellService,
    private userTaskStatusService:UserTaskStatusService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        this.isLoading = false;
      }
    });

    forkJoin({
      allEntries: this.userEatWellService.fetchAll(),
      allUserTaskStatuses: this.userTaskStatusService.fetchAll()
    }).subscribe(
      (responseData) => {
        this.allEntries = responseData.allEntries;

        this.eatWellWeekData = this.userEatWellService.generateWeekArray(
          this.currentWeek,
          this.allEntries
        );

        this.weekScore = this.userEatWellService.getWeekScore(this.eatWellWeekData);
       
        this.isLoading = false;

        this.allTaskStatuses = responseData.allUserTaskStatuses;
        let readResources = this.userTaskStatusService.checkTaskStatus(
          this.allTaskStatuses,
          'IQuitIntro'
        );
        if (!readResources) {
          this.router.navigate(['/modules', 'i-quit', 'resources']);
        }
      },
      (error) => {}
    );
  }
}
