import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/auth/service/user.service';
import { ActivityTrackerService } from 'src/app/services/activity-tracker.service';

@Component({
  selector: 'app-tracker',
  templateUrl: './tracker.component.html',
  styleUrls: ['./tracker.component.scss'],
})
export class TrackerComponent implements OnInit {
  userData: any;
  isLoading = false;

  trackerStatus: any;
  trackerSyncStatus: any;
  constructor(
    private userService: UserService,
    private activityTrackerService: ActivityTrackerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.route.queryParams.subscribe((params) => {
      let oauth_token = params.oauth_token;
      let oauth_verifier = params.oauth_verifier;

      //this.userService.userData.subscribe((userData) => {
      // if (userData) {

      // sync the data?

      this.activityTrackerService
        .syncTracker('garmin')
        .subscribe((responseData) => {
          this.trackerSyncStatus = responseData;

          if (this.trackerSyncStatus != 'success') {
            this.activityTrackerService
              .setupTracker('garmin', oauth_token, oauth_verifier)
              .subscribe((responseData) => {
                this.trackerStatus = responseData;
              });
          }
        });
      // console.log(this.trackerSyncStatus);

      // ?oauth_token=2d859a46-83cf-441a-ad51-98c444a5c29f&oauth_verifier=mXqUzJRKRf
      // }
      //});
    });
  }
}
