<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <div class="prev">
    <a href=""
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-left']"></fa-icon></span
    ></a>
  </div>
  <h2>Overview</h2>
  <div class="next">
    <a [routerLink]="['/activity', 'move', 'level', '1']">
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-right']"></fa-icon></span
    ></a>
  </div>
</nav>
<div class="container container-inner" *ngIf="userData">
  <div class="row">
    <div class="col-12 col-md-12">
      <div class="card mt-3 bg-dark text-white mb-3">
        <img
          src="/assets/img/placeholder-move.jpg"
          class="card-img-top card-img-top-fh"
          alt="..."
        />
        <div class="card-body text-center">
          <h3 class="card-title card-title-lg">Get Stronger For Surgery</h3>
          <p class="card-text">
            Increase muscular strength and endurance to support functional movements after surgery by completing strength training workouts for each the three programme levels.
          </p>
          <hr />
          <h4 class="card-subtitle card-subtitle-lg">Current Level</h4>
          <div class="card-total card-total-light card-total-lg card-total-nb">
            <span>{{userData.move_level}}</span> / <span class="opacity-75">3</span>
          </div>
        </div>
      </div>
      <button class="btn btn-primary w-100" [routerLink]="['/activity','move','quick-add-entry']">
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'circle-plus']"></fa-icon
        ></span>
        Add a Workout Entry
      </button>
    </div>
    <div class="col-12 col-md-6" *ngIf="userData">


      <div
        class="card bg-primary mt-3"
        *ngIf="userData.move_level == 1 && canProgress"
      >
        <div class="card-body">
          <h3 class="card-title card-title-lg text-center">
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'lock-keyhole-open']"></fa-icon
            ></span>
            Level 2 Unlocked
          </h3>
          <p class="card-text text-center text-dark">
            Would you like to proceed to the next level of Prep Strong?
          </p>
        </div>
        <div class="card-footer text-center relative text-dark bg-primary">
          <a (click)="onProgress(2)" class="stretched-link"
            >Proceed &nbsp;
            <span class="icon-wrap more-link text-white"
              ><fa-icon
                [icon]="['fas', 'circle-chevron-right']"
              ></fa-icon></span
          ></a>
        </div>
      </div>

      <div
      class="card bg-primary mt-3"
      *ngIf="userData.move_level == 2 && canProgress"
    >
      <div class="card-body">
        <h3 class="card-title card-title-lg text-center">
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'lock-keyhole-open']"></fa-icon
          ></span>
          Level 3 Unlocked
        </h3>
        <p class="card-text text-center text-dark">
          Would you like to proceed to the next level of Prep Strong?
        </p>
      </div>
      <div class="card-footer text-center relative text-dark bg-primary">
        <a (click)="onProgress(3)" class="stretched-link"
          >Proceed &nbsp;
          <span class="icon-wrap more-link text-white"
            ><fa-icon
              [icon]="['fas', 'circle-chevron-right']"
            ></fa-icon></span
        ></a>
      </div>
    </div>



      <h4 class="text-center mt-4 mb-2">Level {{userData.move_level}} - In Progress</h4>
      <div class="card bg-light">
        <div class="card-body text-center">
          
          <div class="days-indicator days-indicator-lg">
            


            <div class="day" *ngFor="let day of moveWeekData">
                <div class="day-label">
                  {{ day.date | customDate: "dddd" | slice: 0:1 }}
                </div>
              <div class="day-status" [ngClass]="{'bg-primary':day.total_reps>=144}"><span class="dot" *ngIf="day.total_reps>0 && day.total_reps<144"></span></div>
            </div>



          </div>
          <hr class="mt-3" />

          <div class="card-icon text-center">
            <span class="icon-wrap text-dark"
              ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
            ></span>
          </div>
          <p class="card-text text-center text-dark">
            Complete at least 2 workouts over 7 days and comfortably do 3 sets of 8 repetitions during those workouts.
          </p>

        </div>
        <div class="card-footer text-center relative text-dark bg-primary">
          <a [routerLink]="['/activity', 'move', 'level', '1']" class="stretched-link"
            >View &nbsp;
            <span class="icon-wrap more-link text-dark"
              ><fa-icon
                [icon]="['fas', 'circle-chevron-right']"
              ></fa-icon></span
          ></a>
        </div>
      </div>

      <ng-container *ngIf="userData.move_level == 1 && !canProgress">
      <h4 class="text-center mt-4 mb-2">Level 2 - Locked</h4>
      <div class="card bg-light">
        <div class="card-body">
          <div class="card-icon text-center">
            <span class="icon-wrap text-dark-green"
              ><fa-icon [icon]="['far', 'lock']"></fa-icon
            ></span>
          </div>
          <p class="card-text text-center text-dark">
            Complete at least 2 workouts over 7 days and comfortably do 3 sets of 8 repetitions during those workouts.
          </p>
        </div>
        <div class="card-footer text-center relative text-dark bg-primary">
          <a href="" class="stretched-link"
            >View &nbsp;
            <span class="icon-wrap more-link text-dark"
              ><fa-icon
                [icon]="['fas', 'circle-chevron-right']"
              ></fa-icon></span
          ></a>
        </div>
      </div>
    </ng-container>


    <ng-container *ngIf="userData.move_level <3 && !canProgress">
        <h4 class="text-center mt-4 mb-2">Level 3 - Locked</h4>
        <div class="card bg-light">
          <div class="card-body">
            <div class="card-icon text-center">
              <span class="icon-wrap text-dark-green"
                ><fa-icon [icon]="['far', 'lock']"></fa-icon
              ></span>
            </div>
            <p class="card-text text-center text-dark">
              Complete at least 2 workouts over 7 days and comfortably do 3 sets of 8 repetitions during those workouts.
            </p>
          </div>
          <div class="card-footer text-center relative text-dark bg-primary">
            <a href="" class="stretched-link"
              >View &nbsp;
              <span class="icon-wrap more-link text-dark"
                ><fa-icon
                  [icon]="['fas', 'circle-chevron-right']"
                ></fa-icon></span
            ></a>
          </div>
        </div>
    </ng-container>
      </div>
    <div class="col-12 col-md-6">
      <h4 class="text-center mt-4 mb-2">What is Prep Strong?</h4>
      <div class="card mt-3 bg-primary text-dark">
        <img
          src="/assets/img/placeholder-steps.jpg"
          class="card-img-top"
          alt="..."
        />

        <div class="card-footer text-center relative text-dark">
          <a  [routerLink]="['/activity','move','resources']"  class="stretched-link"
            >View &nbsp;
            <span class="icon-wrap more-link text-dark"
              ><fa-icon
                [icon]="['fas', 'circle-chevron-right']"
              ></fa-icon></span
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>
