<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <h2>Smoking Tracker</h2>
</nav>

<div class="container container-inner" *ngIf="userData">
  <button
    class="btn btn-primary w-100 mt-4"
    [routerLink]="['/modules', 'i-quit', 'add-entry']"
  >
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'circle-plus']"></fa-icon
    ></span>
    Add A Daily Entry
  </button>

  <div class="row">
    <div class="col-12 col-md-6">
      <div class="weeks-nav mt-4 mb-1" *ngIf="allWeeksData && showWeek > 0">
        <div class="prevnext">
          <button
            (click)="onPrevWeek()"
            *ngIf="showWeek > 1"
            class="btn btn-link"
          >
            <span class="icon-wrap"
              ><fa-icon
                [icon]="['far', 'chevron-circle-left']"
                aria-label="Previous week"
              ></fa-icon
            ></span>
          </button>
        </div>
        <div class="weeks-nav-title">
          <h4 class="text-center" *ngIf="userWeek">WEEK {{ showWeek }}</h4>
          <div>
            {{
              allWeeksData[userWeek - showWeek][0].date
                | customDate : "ddd Do MMM"
            }}
            -
            {{
              allWeeksData[userWeek - showWeek][6].date
                | customDate : "ddd Do MMM"
            }}
          </div>
        </div>
        <div class="prevnext">
          <button
            (click)="onNextWeek()"
            *ngIf="showWeek < userWeek"
            class="btn btn-link"
          >
            <span class="icon-wrap"
              ><fa-icon
                [icon]="['far', 'chevron-circle-right']"
                aria-label="Next week"
              ></fa-icon
            ></span>
          </button>
        </div>
      </div>

      <div class="card bg-dark text-white">
        <div class="card-body">
          <table class="tracker-table">
            <thead>
              <tr>
                <th></th>
                <th *ngFor="let day of allWeeksData[userWeek - showWeek]">
                  {{ day.date | customDate : "ddd" }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['far', 'smoking']"></fa-icon
                  ></span>
                </td>
                <td *ngFor="let day of allWeeksData[userWeek - showWeek]">
                  <span
                    class="circle circle-sm circle-lighten"
                    [ngClass]="{ 'bg-white text-dark': day.cigarettes == 0 }"
                    *ngIf="day.hasData"
                    >{{ day.cigarettes }}</span
                  >
                  <span *ngIf="day.date > today || !day.hasData">-</span>
                </td>
              </tr>
            </tbody>
          </table>

          <div
            class="tracker-stats mt-2 mb-2"
            *ngIf="allWeeksData[userWeek - showWeek]"
          >
            <div
              class="card-total card-total-inline card-total-light card-total-lg card-total-nb bg-white text-dark"
            >
              <div class="card-total-title">Cigarettes Smoked</div>
              <span>{{
                getWeekCigarettes(allWeeksData[userWeek - showWeek])
              }}</span>
            </div>
            <div
              class="card-total card-total-inline card-total-light card-total-lg card-total-nb bg-white text-dark"
            >
              <div class="card-total-title">Smoke Free Days</div>
              <span>{{
                getWeekFreeDays(allWeeksData[userWeek - showWeek])
              }}</span>
            </div>
          </div>

          <hr />
          <div class="text-center">
            <button
              class="btn btn-link btn-link-nd text-white"
              (click)="showBreakdown = !showBreakdown"
            >
              Full Breakdown
              <span class="icon-wrap"
                ><fa-icon
                  *ngIf="showBreakdown"
                  [icon]="['fas', 'chevron-circle-up']"
                ></fa-icon
                ><fa-icon
                  *ngIf="!showBreakdown"
                  [icon]="['fas', 'chevron-circle-down']"
                ></fa-icon
              ></span>
            </button>

            <div class="breakdown" *ngIf="showBreakdown">
              <table class="tracker-table tracker-table-sm">
                <thead>
                  <tr>
                    <th></th>
                    <th>Smoked</th>
                    <th>Nicotine Replacement</th>
                    <th>Councelling</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let day of allWeeksData[userWeek - showWeek]">
                    <td>
                      {{ day.date | customDate : "ddd" }}
                    </td>

                    <td>{{day.cigarettes}}</td>
                    <td>{{day.nicotine_replacement}}</td>
                    <td>{{day.cessation}}</td>
                    <td>
                      <span
                        class="circle circle-sm circle-lighten"
                        [ngClass]="{
                          'bg-white text-dark': day.cigarettes == 0
                        }"
                        *ngIf="day.hasData"
                        >{{ day.cigarettes }}</span
                      >
                    </td>

                    <td width="18">
                      <span
                        class="icon-wrap"
                        dropdown
                        [container]="'body'"
                        *ngIf="day.hasData"
                      >
                        <button
                          dropdownToggle
                          type="button"
                          class="btn text-white"
                          aria-controls="dropdown-basic"
                        >
                          <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                        </button>
                        <ul
                          *dropdownMenu
                          class="dropdown-menu dropdown-menu-right"
                          role="menu"
                          aria-labelledby="button-basic"
                        >
                          <li role="menuitem">
                            <a
                              class="dropdown-item"
                              [routerLink]="[
                                '/modules',
                                'i-quit',
                                'edit-entry',
                                day.entryId
                              ]"
                              >Edit</a
                            >
                          </li>
                          <li role="menuitem">
                            <a
                              class="dropdown-item"
                              (click)="onDeleteEntry(day.entryId)"
                              >Delete</a
                            >
                          </li>
                        </ul>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>{{getWeekCigarettes(allWeeksData[userWeek - showWeek])}} Cigarettes</td>
                    <td>{{getWeekNicotine(allWeeksData[userWeek - showWeek])}} Days</td>
                    <td>{{getWeekCessation(allWeeksData[userWeek - showWeek])}} Days</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <h3 class="title-xl text-center mt-3">All Time</h3>

      <div class="card bg-light">
        <div class="card-body">
          <h4 class="text-center">Cigarettes Smoked</h4>
          <div class="stat">
            <div class="days-indicator days-indicator-md text-center">
              <div
                class="day"
                *ngFor="
                  let week of allWeeksData.slice().reverse();
                  let i = index
                "
              >
                <div class="day-label">W{{ i + 1 }}</div>
                <div class="day-status-lg day-status-circle bg-dark">
                  <div class="day-status-title">
                    {{ getWeekCigarettes(week) }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h4 class="text-center mt-4">Smoke Free Days</h4>
          <div class="stat">
            <div class="days-indicator days-indicator-md text-center">
              <div
                class="day"
                *ngFor="
                  let week of allWeeksData.slice().reverse();
                  let i = index
                "
              >
                <div class="day-label">W{{ i + 1 }}</div>
                <div class="day-status-lg day-status-circle bg-dark">
                  <div class="day-status-title">
                    {{ getWeekFreeDays(week) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
