import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { UserSleepsService } from 'src/app/services/user-sleeps.service';
import { UserTaskStatusService } from 'src/app/services/user-task-status.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sleep-well-tracker',
  templateUrl: './sleep-well-tracker.component.html',
  styleUrls: ['./sleep-well-tracker.component.scss']
})
export class SleepWellTrackerComponent implements OnInit {
  isLoading = false;
  userData: any;
  allEntries: any;
  sleepWeekData: any;
  allTimeStats: any;
  allWeeksData: any = [];
  weekScore = 0;
  showBreakdown = false;
  userWeek = 0;
  showWeek = 0;
  allTaskStatuses:any;

  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public today = this.dateHelperService.getToday();
  constructor(
    private dateHelperService: DateHelperService,
    private userService: UserService,
    private router: Router,
    public userSleepService:UserSleepsService,
    private userTaskStatusService:UserTaskStatusService
  ) {

   
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        this.isLoading = false;
      }
    });

    forkJoin({
      allEntries: this.userSleepService.fetchAll(),
      allUserTaskStatuses: this.userTaskStatusService.fetchAll()
    }).subscribe(
      (responseData) => {
        this.allEntries = responseData.allEntries;

        this.sleepWeekData = this.userSleepService.generateWeekArray(
          this.currentWeek,
          this.allEntries
        );


       // this.allTimeStats = this.userSleepService.getStats(this.allEntries);

        this.allWeeksData.push(this.sleepWeekData);
        let endOfWeek = this.currentWeek[6];
        let weeksRun = 0;
        this.userService.userData.subscribe((userData) => {
          if (userData) {
            while (moment(endOfWeek).subtract(1, 'week') > moment(this.userData.date_registered) && weeksRun<100) {
              weeksRun++;
              let prevWeek = this.dateHelperService.getWeekFor(
                moment(endOfWeek).subtract(1, 'week')
              );
              let prevWeekData = this.userSleepService.generateWeekArray(
                prevWeek,
                this.allEntries
              );
              this.allWeeksData.push(prevWeekData);
              endOfWeek = prevWeek[6];
            }
            this.userWeek = this.allWeeksData.length;
            this.showWeek = this.allWeeksData.length;
          }
        });

        this.weekScore = this.userSleepService.getWeekScore(
          this.allWeeksData[this.userWeek - this.showWeek]
        );
        this.allTaskStatuses = responseData.allUserTaskStatuses;
        let readResources = this.userTaskStatusService.checkTaskStatus(
          this.allTaskStatuses,
          'SleepWellIntro'
        );
        if (!readResources) {
          this.router.navigate(['/modules', 'sleep-well', 'resources']);
        }

        this.isLoading = false;
      },
      (error) => {}
    );
  }

  

  
  onPrevWeek(){

    if(this.showWeek>1){
      this.showWeek--;
      this.weekScore = this.userSleepService.getWeekScore(
        this.allWeeksData[this.userWeek - this.showWeek]
      );
    }
    
  }

  onNextWeek(){
    if(this.showWeek<this.userWeek){
      this.showWeek++;
      this.weekScore = this.userSleepService.getWeekScore(
        this.allWeeksData[this.userWeek - this.showWeek]
      );
    }
  }


  onDeleteEntry(entryId: number) {
    Swal.fire({
      title: "Are you sure?",
      text:  "You won't be able to undo this",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText:'Cancel',
      confirmButtonText:'Yes, delete it',
    }).then((result) => {
      if (result.value) {
        this.userSleepService.delete(entryId).subscribe(
          (responseData) => {
            Swal.fire("Your entry has been deleted", "success");

            let deleteIndex = -1;
            this.allEntries.forEach((entry:any, workoutIndex:any) => {
              if (+entry.user_eat_well_id === +entryId) {
                deleteIndex = workoutIndex;
              }
            });

            if (deleteIndex != -1) {
              // splice it
              this.allEntries.splice(deleteIndex, 1);
            }

            // redirect
            this.router.navigateByUrl('/nothing', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/modules', 'sleep-well', 'tracker']);
          })


          },
          (error) => {
            this.isLoading = false;
            Swal.fire('Error', 'An unknown error has occured, please contact us', 'error');
          }
        );
      }
    });
  }

}
