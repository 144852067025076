<div class="aside-menu-wrap">
    <div class="navbar navbar-expand-lg navbar-dark bg-primary navbar-main">
      <div class="container justify-content-center align-items-start relative-md">
        <div class="mr-auto nhs-logo"></div>
        <a class="navbar-brand mx-auto position-absolute">
          <h1>
            <span>Menu</span>
          </h1>
        </a>
        <div
          class="collapse navbar-collapse justify-content-end navbar-desktop"
        ></div>
        <div class="ms-auto btn-group" dropdown>
          <button
            class="btn-nav-menu"
            type="button"
            (click)="onCloseMenu($event)"
          >
            <fa-icon [icon]="['far', 'bars']"></fa-icon>
            <span class="sr-only">Menu</span>
          </button>
        </div>
      </div>
    </div>
  
    <div class="aside-menu">
      <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
      <div class="container" *ngIf="userData">
        <ul class="task-list mt-3 task-list-aside-user">
          <li>
            <span class="flex-wrap">
              <img
                src="{{ avatarBase + userData?.avatar }}"
                alt="user avatar"
                class="avatar rounded"
                width="40"
                height="40"
                *ngIf="userData?.avatar"
              />
              <span class="num" *ngIf="!userData?.avatar">
                <span class="icon-wrap">
                  <fa-icon [icon]="['fal', 'user']"></fa-icon>
                </span>
              </span>
              <a class="task" [routerLink]="['/more', 'about']">
                <span class="title"
                  >{{ userData?.first_name + " " + userData?.last_name }}
                </span>
              </a></span
            >
            <ul class="nav flex-column aside-sub">
              <li class="nav-item">
                <a
                  class="nav-link"
                  [routerLink]="['/update-details']"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  Update Details
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  [routerLink]="['/upload-avatar']"
                  [queryParams]="{ custom: '1' }"
                  routerLinkActive="active"
                >
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  Upload Avatar
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  [routerLink]="['/change-password']"
                  routerLinkActive="active"
                >
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  Change Password
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['']" (click)="onLogout()">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  Logout
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <hr />
        <ul class="task-list mt-3">
          <li>
            <span class="flex-wrap">
              <span class="num">
                <span class="icon-wrap">
                  <fa-icon
                    [icon]="['far', 'mobile']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              <a class="task" [routerLink]="['/more', 'app']">
                <span class="title">Install Web App</span>
                <span class="status">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
                  </span>
                </span> </a
            ></span>
          </li>
          <li>
            <span class="flex-wrap">
              <span class="num">
                <span class="icon-wrap">
                  <fa-icon
                    [icon]="['far', 'info-circle']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              <a class="task" [routerLink]="['/more', 'about']">
                <span class="title">About</span>
                <span class="status">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
                  </span>
                </span> </a
            ></span>
          </li>
          <li>
            <span class="flex-wrap">
              <span class="num">
                <span class="icon-wrap">
                  <fa-icon
                    [icon]="['far', 'balance-scale']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              <a class="task" [routerLink]="['/more', 'terms']">
                <span class="title">Terms &amp; Conditions</span>
                <span class="status">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
                  </span>
                </span> </a
            ></span>
          </li>
          <li>
            <span class="flex-wrap">
              <span class="num">
                <span class="icon-wrap">
                  <fa-icon
                    [icon]="['far', 'fingerprint']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              <a class="task" [routerLink]="['/more', 'privacy']">
                <span class="title">Privacy Policy</span>
                <span class="status">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
                  </span>
                </span> </a
            ></span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  