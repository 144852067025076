import { ForumPost } from './forumPost.model';
import { ForumTopic } from './formTopic.model';
import { ForumCategory } from './forumCategory.model';
import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ForumService {
  error = new Subject<string>();

  constructor(private http: HttpClient) {}

  fetchCategories() {
    return this.http
      .get<any>(environment.apiUrl + '/forum_categories', {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const categoryArray: ForumCategory[] = [];
          responseData['_embedded'].forum_categories.forEach((forumCategory:any) => {
            categoryArray.push(forumCategory);
          });
          return categoryArray;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchCategory(categoryId: number) {
    return this.http
    .get<any>(environment.apiUrl + '/forum_categories/' + categoryId, {
      responseType: 'json',
    })
    .pipe(
      map((responseData) => {
        return responseData;
      }),
      catchError((errorRes) => {
        return throwError(errorRes);
      })
    );
  }

  fetchTopics( categoryId: number) {

    let params = new HttpParams();
    params = params.append('category_id', categoryId.toString());
    return this.http
      .get<any>(environment.apiUrl + '/forum_topics', {
        responseType: 'json',
        params,
      })
      .pipe(
        map((responseData) => {
          const topicArray: ForumTopic[] = [];
          responseData['_embedded'].forum_topics.forEach((forumTopic:any) => {
            topicArray.push(forumTopic);
          });
          return topicArray;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }


  fetchTopic(topicId: number) {
    return this.http
    .get<any>(environment.apiUrl + '/forum_topics/' + topicId, {
      responseType: 'json',
    })
    .pipe(
      map((responseData) => {
        return responseData;
      }),
      catchError((errorRes) => {
        return throwError(errorRes);
      })
    );
  }
  fetchPosts( topicId: number) {
    let params = new HttpParams();
    params = params.append('topic_id', topicId.toString());
    return this.http
      .get<any>(environment.apiUrl + '/forum_posts', {
        responseType: 'json',
        params,
      })
      .pipe(
        map((responseData) => {
          const postsArray: ForumPost[] = [];
          responseData['_embedded'].forum_posts.forEach((forumPost:any) => {
            postsArray.push(forumPost);
          });
          return postsArray;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  createTopic(categoryId:number, title:string, post:string){
    const postData = {
      categoryId,
      title,
      post
    };
    return this.http.post<ForumTopic>(
      environment.apiUrl + '/forum_topics',
      postData,
      {
        observe: 'response',
      }
    );
  }

  postReply(post:any, topicId:number){
    const postData = {
      post,
      topic_id: topicId
    };
    return this.http.post<ForumPost>(
      environment.apiUrl + '/forum_posts',
      postData,
      {
        observe: 'response',
      }
    );
  }

  reportPost(post:any){
    const postData = {
      post
    };
    return this.http.patch<{ name: string }>(
      environment.apiUrl + '/forum_posts/' + post.forum_post_id,
      {status: 2},
      {
        observe: 'response',
      }
    );
  }
}
