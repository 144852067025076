export class UserFeel {
    constructor(
      public user_feel_id: number,
      public user_id: number,
      public date_recorded: string,
      public mood: number,
      public worry: number,
      public stress: number,
      public created: string,
      public modified: string,
    ) {}
  }
