import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HomeComponent } from './home/home.component';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from 'ngx-cookieconsent';
import { LoginComponent } from './auth/login/login.component';

// chat
import { ExpertComponent } from './chat/expert/expert.component';
import { SupportComponent } from './chat/support/support.component';
import { ForumComponent } from './chat/forum/forum.component';
import { ForumCategoryComponent } from './chat/forum/forum-category/forum-category.component';
import { ForumTopicComponent } from './chat/forum/forum-topic/forum-topic.component';
import { ForumTopicAddComponent } from './chat/forum/forum-topic-add/forum-topic-add.component';

import { interceptorProviders } from './interceptors';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
/*export function provideSwal() {
  return import('sweetalert2/src/sweetalert2.js'); // instead of import('sweetalert2')
}*/

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import {
  config,
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';

import {
  faChevronRight as falChevronRight,
  faChevronLeft as falChevronLeft,
  faArrowAltRight as falArrowAltRight,
  faLockAlt as falLockAlt,
  faStar as falStar,
  faStopwatch as falStopwatch,
  faHeadSideBrain as falHeadSideBrain,
  faWeight as falWeight,
  faSmokingBan as falSmokingBan,
  faPaperPlane as falPaperPlane,
  faUser as falUser,
  faKey as falKey,
  faTasks as falTasks,
  faChartLine as falChartLine,
  faBookReader as falBookReader,
  faUserMdChat as falUserMdChat,
  faEllipsisH as falEllipsisH,
  faBullseyePointer as falBullseyePointer,
  faVideo as falVideo,
  faFileAlt as falFileAlt,
  faMousePointer as falMousePointer,
  faAngleDown as falAngleDown,
  faAngleUp as falAngleUp,
  faHashtag as falHashtag,
  faDumbbell as falDumbbell,
  faThermometerHalf as falThermometerHalf,
  faThermometerEmpty as falThermometerEmpty,
  faThermometerFull as falThermometerFull,
  faHeadSideCough as falHeadSideCough,
  faCouch as falCouch,
  faLungs as falLungs,
  faSnooze as falSnooze,
  faCommentAltLines as falCommentsAltLines,
  faHeadset as falHeadset,
  faTrash as falTrash,
  faSadTear as falSadTear,
  faBoxTissue as falBoxTissue,
  faHeart as falHeart,
  faSmile as falSmile,
  faBolt as falBolt,
  faComments as falComments,
  faCircle as falCircle,
  faSquare as falSquare,
  faInfoCircle as falInfoCircle,
  faUsers as falUsers,
  faHospitalAlt as falHospitalAlt,
  faBalanceScale as falBalanaceScale,
  faFingerprint as falFingerprint,
  faMobile as falMobile,
  faSpinner as falSpinner,
  faArrowRight as falArrowRight,
  faArrowLeft as falArrowLeft,
  faHourglassStart as falHourglassStart,
  faFlagCheckered as falFlagCheckered,
  // faArrowCircleRight as falArrowCircleRight
  // faRedoAlt as falRedoAlt
  // faBars as falBars
} from '@fortawesome/pro-light-svg-icons';
import {
  faCheck as farCheck,
  faTimes as farTimes,
  faBars as farBars,
  faRedoAlt as farRedoAlt,
  faArrowCircleRight as farArrowCircleRight,
  faArrowCircleLeft as farArrowCircleLeft,
  faChevronLeft as farChevronLeft,
  faChevronDown as farChevronDown,
  faCircle as farCircle,
  faArrowAltRight as farArrowAltRight,
  faArrowAltLeft as farArrowAltLeft,
  faArrowsAltV as farArrowsAltV,
  faCalendarAlt as farCalendarAlt,
  faFrownOpen as farFrownOpen,
  faUtensils as farUtensils,
  faHeadSideBrain as farHeadSideBrain,
  faMeh as farMeh,
  faWalking as farWalking,
  faFileCertificate as farFileCertificate,
  faFileDownload as farFileDownload,
  faSearch as farSearch,
  faVideo as farVideo,
  faFileAlt as farFileAlt,
  faMousePointer as farMousePointer,
  faCameraAlt as farCameraAl,
  faMicrophoneStand as farMicrophoneStand,
  faThermometerHalf as farThermometerHalf,
  faBullseyeArrow as farBullseyeArrow,
  faInfoCircle as farInfoCircle,
  faUsers as farUsers,
  faHospitalAlt as farHospitalAlt,
  faBalanceScale as farBalanaceScale,
  faFingerprint as farFingerprint,
  faPenAlt as farPenAlt,
  faMobile as farMobile,
  faHeadSideCough as farHeadSideCough,
  faCouch as farCouch,
  faLungs as farLungs,
  faSnooze as farSnooze,
  faLock as farLock,
  faStopwatch as farStopwatch,
  faFaceSmile as farFaceSmile,
  faPaperPlaneTop as farPaperPlaneTop,
  faThumbsUp as farThumbsUp,
  faTired as farTired,
  faFrown as farFrown,
  faSmile as farSmile,
  faGrinStars as farGrinStars,
  faCaretDown as farCaretDown,
  faStars as farStars,
  faBallotCheck as farBallotCheck,
  faFileChartPie as farFileChartPie,
  faRepeat as farRepeat,
  faCarrot as farCarrot,
  faEgg as farEgg,
  faPeanut as farPeanut,
  faBaguette as farBaguette,
  faToilet as farToilet,
  faBanSmoking as farBanSmoking,
  faSmoking as farSmoking,
  faNoteSticky as farNoteSticky,
  faComments as farComments,
  faCircleMinus as farCircleMinus,
  faCirclePlus as farCirclePlus,
  faBeerFoam as farBeerFoam,
  faChampagneGlass as farChampagneGlass,
  faSadCry as farSadCry,
  faArrowUp as farArrowUp,
  faFaceMeh as farFaceMeh,
  faFaceLaugh as farFaceLaugh,
  faFacePensive as farFacePensive,
  faFaceWorried as farFaceWorried,
  faFaceSmileRelaxed as farFaceSmileRelaxed,
  faFaceFearful as farFaceFearful,
  faFaceFlushed as farFaceFlushed,
  faCaretUp as farCaretUp,
  faChevronRight as farChevronRight,
  faChevronCircleRight as farChevronCircleRight,
  faChevronCircleLeft as farChevronCircleLeft,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faArrowUp as fasArrowUp,
  faArrowDown as fasArrowDown,
  faCircle as fasCircle,
  faCheckCircle as fasCheckCircle,
  faArrowAltRight as fasArrowAltRight,
  faCheckSquare as fasCheckSquare,
  faHome as fasHome,
  faCalendarAlt as fasCalendarAlt,
  faCommentsAlt as fasCommentsAlt,
  faHeartbeat as fasHeartbeat,
  faUserChart as fasUserChart,
  faWalking as fasWalking,
  faChevronRight as fasChevronRight,
  faChevronLeft as fasChevronLeft,
  faCircleChevronRight as fasCircleChevronRight,
  faCircleChevronLeft as fasCircleChevronLeft,
  faCircleChevronDown as fasCircleChevronDown,
  faCircleChevronUp as fasCircleChevronUp,
  faRunning as fasRunning,
  faLungs as fasLungs,
  faUser as fasUser,
  faCircleArrowLeft as fasCircleArrowLeft,
  faCircleArrowRight as fasCircleArrowRight,
  faArrowLeft as fasArrowLeft,
  faShoePrints as fasShoePrints,
  faStar as fasStar,
  faCirclePlus as fasCirclePlus,
  faCirclePlay as fasCirclePlay,
  faCheck as fasCheck,
  faArrowRight as fasArrowRight,
  faCaretUp as fasCaretUp,
  faCaretDown as fasCaretDown,
  faLock as fasLock,
  faCutlery as fasCutlery,
  faCrown as fasCrown,
  faGlassHalf as fasGlassHalf,
  faWineGlass as fasWineGlass,
  faWineBottle as fasWineBottle,
  faMartiniGlass as fasMartiniGlass,
  faChampagneGlass as fasChampagneGlass,
  faFaceSmileRelaxed as fasFaceSmileRelaxed,
  faFaceSleeping as fasFaceSleeping,
  faEllipsisV as fasEllipsisV,
  faLockKeyholeOpen as fasLockKeyholeOpen,
  faWatchFitness as fasWatchFitness
} from '@fortawesome/pro-solid-svg-icons';

import {
  faLunge as facLunge,
  faCanRegular as facCanRegular,
  faCanSuperStrength as facCanSuperStrength,
} from './custom-icons';

import{

  faCirclePlay as fadCirclePlay,
  faCircleChevronRight as fadCircleChevronRight
}
from  '@fortawesome/pro-duotone-svg-icons';
// end custom icons

import { ForgottenPasswordComponent } from './auth/forgotten-password/forgotten-password.component';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { RegisterComponent } from './auth/register/register.component';
import { NavbarComponent } from './navbar/navbar.component';
import { AsideMenuComponent } from './aside-menu/aside-menu.component';
import { AddToHomescreenComponent } from './add-to-homescreen/add-to-homescreen.component';
import { FooterNavComponent } from './footer-nav/footer-nav.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MoreComponent } from './more/more.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';

// bootstrap
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

import { DashboardComponent } from './dashboard/dashboard.component';
import { UpdateDetailsComponent } from './auth/update-details/update-details.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';

import { CheckPasswordDirective } from './directives/check-password.directive';
import { UploadAvatarComponent } from './auth/upload-avatar/upload-avatar.component';
import { OfflineComponent } from './offline/offline.component';
import { MfaComponent } from './auth/mfa/mfa.component';
import { StepsIndexComponent } from './activity/steps/steps-index/steps-index.component';
import { FitIndexComponent } from './activity/fit/fit-index/fit-index.component';
import { FitLevelComponent } from './activity/fit/fit-level/fit-level.component';
import { MoveIndexComponent } from './activity/move/move-index/move-index.component';
import { MoveLevelComponent } from './activity/move/move-level/move-level.component';
import { MoveExerciseComponent } from './activity/move/move-exercise/move-exercise.component';
import { BreatheIndexComponent } from './activity/breathe/breathe-index/breathe-index.component';
import { ActivityIndexComponent } from './activity/activity-index/activity-index.component';
import { ProgrammeIndexComponent } from './programme/programme-index/programme-index.component';
import { TaskListComponent } from './programme/task-list/task-list.component';
import { ProgrammeProgressComponent } from './programme/programme-progress/programme-progress.component';
import { SocialIndexComponent } from './social/social-index/social-index.component';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { TimeagoPipe } from './pipes/timeago.pipe';
import { ModulesIndexComponent } from './modules/modules-index/modules-index.component';
import { StepsTargetComponent } from './activity/steps/steps-target/steps-target.component';
import { FitAddWorkoutComponent } from './activity/fit/fit-add-workout/fit-add-workout.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MoveAddWorkoutComponent } from './activity/move/move-add-workout/move-add-workout.component';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { MoveQuickAddWorkoutComponent } from './activity/move/move-quick-add-workout/move-quick-add-workout.component';
import { BreatheAddEntryComponent } from './activity/breathe/breathe-add-entry/breathe-add-entry.component';
import { DifficultyIconComponent } from './shared/loading-spinner/difficulty-icon/difficulty-icon.component';
import { EatWellIndexComponent } from './modules/eat-well/eat-well-index/eat-well-index.component';
import { EatWellAddEntryComponent } from './modules/eat-well/eat-well-add-entry/eat-well-add-entry.component';
import { IQuitIndexComponent } from './modules/i-quit/i-quit-index/i-quit-index.component';
import { IQuitAddEntryComponent } from './modules/i-quit/i-quit-add-entry/i-quit-add-entry.component';
import { DrinkingGuideIndexComponent } from './modules/drinking-guide/drinking-guide-index/drinking-guide-index.component';
import { DrinkingGuideAddEntryComponent } from './modules/drinking-guide/drinking-guide-add-entry/drinking-guide-add-entry.component';
import { FeelWellIndexComponent } from './modules/feel-well/feel-well-index/feel-well-index.component';
import { FeelWellAddEntryComponent } from './modules/feel-well/feel-well-add-entry/feel-well-add-entry.component';
import { SleepWellIndexComponent } from './modules/sleep-well/sleep-well-index/sleep-well-index.component';
import { SleepWellAddEntryComponent } from './modules/sleep-well/sleep-well-add-entry/sleep-well-add-entry.component';
import { EatWellTrackerComponent } from './modules/eat-well/eat-well-tracker/eat-well-tracker.component';
import { EatWellEditEntryComponent } from './modules/eat-well/eat-well-edit-entry/eat-well-edit-entry.component';
import { ResourceIndexComponent } from './resources/resource-index/resource-index.component';
import { ResourcePageComponent } from './resources/resource-page/resource-page.component';
import { GetThumbnailPipe } from './pipes/get-thumbnail.pipe';
import { TrackerComponent } from './activity/tracker/tracker.component';
import { DrinkingGuideTrackerComponent } from './modules/drinking-guide/drinking-guide-tracker/drinking-guide-tracker.component';
import { DrinkingGuideEditEntryComponent } from './modules/drinking-guide/drinking-guide-edit-entry/drinking-guide-edit-entry.component';
import { IQuitTrackerComponent } from './modules/i-quit/i-quit-tracker/i-quit-tracker.component';
import { IQuitEditEntryComponent } from './modules/i-quit/i-quit-edit-entry/i-quit-edit-entry.component';
import { FeelWellTrackerComponent } from './modules/feel-well/feel-well-tracker/feel-well-tracker.component';
import { FeelWellEditEntryComponent } from './modules/feel-well/feel-well-edit-entry/feel-well-edit-entry.component';
import { SleepWellTrackerComponent } from './modules/sleep-well/sleep-well-tracker/sleep-well-tracker.component';
import { SleepWellEditEntryComponent } from './modules/sleep-well/sleep-well-edit-entry/sleep-well-edit-entry.component';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain,
  },
  palette: {
    popup: {
      background: '#000',
    },
    button: {
      background: '#rgb(86, 170, 244)',
    },
  },
  theme: 'block',
  type: 'opt-in',
  content: {
    message: 'This website uses cookies for analytics. ',
    dismiss: 'Go away',
    deny: 'No thanks',
    allow: "That's fine",
    link: 'Learn more',
    href: 'https://cookiesandyou.com',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HomeComponent,
    LoginComponent,
    ForgottenPasswordComponent,
    LoadingSpinnerComponent,
    ResetPasswordComponent,
    RegisterComponent,
    NavbarComponent,
    AsideMenuComponent,
    AddToHomescreenComponent,
    FooterNavComponent,
    FooterComponent,
    SidebarComponent,
    MoreComponent,
    HeaderBarComponent,
    DashboardComponent,
    UpdateDetailsComponent,
    ChangePasswordComponent,
    CheckPasswordDirective,
    UploadAvatarComponent,
    OfflineComponent,
    SafeHtmlPipe,
    MfaComponent,
    StepsIndexComponent,
    FitIndexComponent,
    FitLevelComponent,
    MoveIndexComponent,
    MoveLevelComponent,
    MoveExerciseComponent,
    BreatheIndexComponent,
    ActivityIndexComponent,
    ProgrammeIndexComponent,
    TaskListComponent,
    ProgrammeProgressComponent,
    SocialIndexComponent,
    ExpertComponent,
    SupportComponent,
    ForumComponent,
    ForumCategoryComponent,
    ForumTopicComponent,
    ForumTopicAddComponent,
    CustomDatePipe,
    TimeagoPipe,
    ModulesIndexComponent,
    StepsTargetComponent,
    FitAddWorkoutComponent,
    MoveAddWorkoutComponent,
    MoveQuickAddWorkoutComponent,
    BreatheAddEntryComponent,
    DifficultyIconComponent,
    EatWellIndexComponent,
    EatWellAddEntryComponent,
    IQuitIndexComponent,
    IQuitAddEntryComponent,
    DrinkingGuideIndexComponent,
    DrinkingGuideAddEntryComponent,
    FeelWellIndexComponent,
    FeelWellAddEntryComponent,
    SleepWellIndexComponent,
    SleepWellAddEntryComponent,
    EatWellTrackerComponent,
    EatWellEditEntryComponent,
    ResourceIndexComponent,
    ResourcePageComponent,
    GetThumbnailPipe,
    TrackerComponent,
    DrinkingGuideTrackerComponent,
    DrinkingGuideEditEntryComponent,
    IQuitTrackerComponent,
    IQuitEditEntryComponent,
    FeelWellTrackerComponent,
    FeelWellEditEntryComponent,
    SleepWellTrackerComponent,
    SleepWellEditEntryComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    FontAwesomeModule,
    SweetAlert2Module.forRoot(),
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TimepickerModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxSliderModule,
  ],
  providers: [interceptorProviders, PendingChangesGuard],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(
      facLunge,
      falChevronRight,
      falChevronLeft,
      falArrowAltRight,
      farCheck,
      farTimes,
      falLockAlt,
      falStar,
      falStopwatch,
      falHeadSideBrain,
      falWeight,
      falSmokingBan,
      falPaperPlane,
      falUser,
      falKey,
      falTasks,
      falChartLine,
      falBookReader,
      falUserMdChat,
      falEllipsisH,
      falBullseyePointer,
      farBars,
      falVideo,
      falFileAlt,
      falMousePointer,
      farRedoAlt,
      farArrowCircleRight,
      farArrowCircleLeft,
      farChevronLeft,
      farChevronRight,
      farCircle,
      farArrowAltRight,
      fasArrowAltRight,
      fasCheck,
      farArrowsAltV,
      falAngleDown,
      falAngleUp,
      farCalendarAlt,
      falHashtag,
      falDumbbell,
      falThermometerHalf,
      falThermometerEmpty,
      falThermometerFull,
      falHeadSideCough,
      falCouch,
      falLungs,
      falSnooze,
      farFrownOpen,
      farMeh,
      farUtensils,
      farHeadSideBrain,
      falCommentsAltLines,
      falHeadset,
      falTrash,
      fasArrowUp,
      fasArrowDown,
      farWalking,
      farFileCertificate,
      farFileDownload,
      farChevronDown,
      falSadTear,
      fasCircle,
      falBoxTissue,
      falHeart,
      falSmile,
      falBolt,
      falComments,
      fasCheckCircle,
      falCircle,
      fasCheckSquare,
      falSquare,
      farSearch,
      farArrowAltLeft,
      farVideo,
      farFileAlt,
      farMousePointer,
      farCameraAl,
      farMicrophoneStand,
      farThermometerHalf,
      farBullseyeArrow,
      farInfoCircle,
      farUsers,
      farHospitalAlt,
      farBalanaceScale,
      farFingerprint,
      falInfoCircle,
      falUsers,
      falHospitalAlt,
      falBalanaceScale,
      falFingerprint,
      farPenAlt,
      falMobile,
      farMobile,
      farHeadSideCough,
      farCouch,
      farLungs,
      farSnooze,
      falSpinner,
      falArrowRight,
      falArrowLeft,
      fasHome,
      fasCalendarAlt,
      fasCommentsAlt,
      fasHeartbeat,
      fasUserChart,
      fasWalking,
      fasChevronRight,
      fasChevronLeft,
      fasCircleChevronRight,
      fasCircleChevronLeft,
      fasRunning,
      fasLungs,
      falHourglassStart,
      falFlagCheckered,
      fasUser,
      fasCircleArrowLeft,
      fasCircleArrowRight,
      fasArrowLeft,
      fasShoePrints,
      fasStar,
      fasCirclePlus,
      farLock,
      farStopwatch,
      fasCirclePlay,
      fasCheck,
      farFaceSmile,
      fasArrowRight,
      farPaperPlaneTop,
      farThumbsUp,
      farTired,
      farFrown,
      farSmile,
      farGrinStars,
      farCaretDown,
      farStars,
      fasLock,
      farBallotCheck,
      fasCutlery,
      farFileChartPie,
      farRepeat,
      farCarrot,
      farEgg,
      farPeanut,
      farBaguette,
      farToilet,
      farBanSmoking,
      farSmoking,
      farNoteSticky,
      farComments,
      farCircleMinus,
      farCirclePlus,
      farBeerFoam,
      fasCrown,
      fasGlassHalf,
      facCanRegular,
      facCanSuperStrength,
      fasWineGlass,
      fasWineBottle,
      fasMartiniGlass,
      fasChampagneGlass,
      farChampagneGlass,
      farSadCry,
      farArrowUp,
      farFaceMeh,
      farFaceLaugh,
      farFacePensive,
      farFaceWorried,
      farFaceSmileRelaxed,
      farFaceFearful,
      farFaceFlushed,
      fasCaretUp,
      fasCaretDown,
      fasFaceSmileRelaxed,
      fasFaceSleeping,
      fasCircleChevronDown,
      fasCircleChevronUp,
      farChevronCircleRight,
      farChevronCircleLeft,
      fasEllipsisV,
      fasLockKeyholeOpen,
      fadCirclePlay,
      fadCircleChevronRight,
      fasWatchFitness
    );
  }
}
