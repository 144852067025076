import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { UserCardioService } from 'src/app/services/user-cardio.service';

@Component({
  selector: 'app-fit-level',
  templateUrl: './fit-level.component.html',
  styleUrls: ['./fit-level.component.scss'],
})
export class FitLevelComponent implements OnInit {
  isLoading = false;
  level: number = 0;
  minsRemaining?:number;
  prev: any;
  next: any;
  levelData: any = {
    1: {
      target:
        'At least 150 minutes in total of moderate intensity aerobic training over a 7 day period.',
    },
    2: {
      target:
        'At least 75 minutes of vigorous intensity aerobic training logged over 7 days.',
    },
    3: {
      target:
        'Three sessions of 45-50 minutes vigorous aerobic training per week.',
    },
  };
  userData: any;
  allWorkouts: any;
  cardioWeekData: any;
  cardioLast7Days:any;
  cardioMinsWeek = 0;
  cardioLastWeekData: any;
  cardioMinsWeekForTarget = 0;
  cardioMinsWeekModerate = 0;
  cardioMinsWeekVigorous = 0;
  progress = 0;

  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public lastWeek: Date[] = this.dateHelperService.getLastWeek();
  public today = this.dateHelperService.getToday();

  constructor(
    private route: ActivatedRoute,
    private dateHelperService: DateHelperService,
    private userService: UserService,
    private userCardioService: UserCardioService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.level = this.route.snapshot.params.level;
      switch (+this.level) {
        case 1:
          this.prev = ['/activity', 'fit'];
          this.next = ['/activity', 'fit', 'level', '2'];
          break;

        case 2:
          this.prev = ['/activity', 'fit', 'level', '1'];
          this.next = ['/activity', 'fit', 'level', '3'];
          break;

        case 3:
          this.prev = ['/activity', 'fit', 'level', '2'];
          this.next = null;
          break;
      }

      this.isLoading = true;
      this.userService.userData.subscribe((userData) => {
        if (userData) {
          this.userData = userData;
        }
      });

      forkJoin({
        allWorkouts: this.userCardioService.fetchAll(),
      }).subscribe(
        (responseData) => {
          this.allWorkouts = responseData.allWorkouts;

          this.cardioWeekData = this.userCardioService.generateWeekArray(
            this.currentWeek,
            this.allWorkouts
          );
          this.cardioLast7Days = this.userCardioService.generatePastSevenDaysArray(
            this.allWorkouts
          );
          
        this.cardioLastWeekData = this.userCardioService.generateWeekArray(
          this.lastWeek,
          this.allWorkouts
        );
        this.cardioMinsWeek = 0;
        this.cardioWeekData.forEach((day: any) => {
          this.cardioMinsWeek += +day.minutes;
          this.cardioMinsWeekModerate += day.moderateMinutes;
          this.cardioMinsWeekVigorous += day.vigorousMinutes;

        });


          // do it
          switch (+this.userData.fit_level) {
            case 1:
              this.cardioMinsWeekForTarget +=
                this.cardioMinsWeekModerate + this.cardioMinsWeekVigorous;
              break;

            case 2:
              this.cardioMinsWeekForTarget += this.cardioMinsWeekVigorous;
              break;

            case 3:
              this.cardioMinsWeekForTarget += this.cardioMinsWeekVigorous;
              break;
          }

        switch (+this.userData.fit_level) {
          case 1:
            this.progress =
              ((this.cardioMinsWeekModerate + this.cardioMinsWeekVigorous) /
                150) *
              100;
            break;
          case 2:
            this.progress = (this.cardioMinsWeekVigorous / 75) * 100;
            break;
        }

          this.isLoading = false;
        },
        (error) => {}
      );
    });
  }
}
