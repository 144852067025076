import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserDrink } from '../models/user-drink.model';
import { DateHelperService } from './date-helper.service';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

type DrinkType = { id: string; icon: string; iconWeight:string; subIcon?: IconName; subIconWeight?:IconPrefix; subIconPosition?: string; label: string, units:number };

@Injectable({
  providedIn: 'root'
})
export class UserDrinksService {
  error = new Subject<string>();
  slug = '/user_drinks';
  resourceName = 'user_drinks';
  weeklyTarget = {
    threshold: 1,
    days: 3,
  };
  allWorkouts?: Observable<UserDrink[]> | null = null;
  workoutsBetween?: any;

  drinkTypes:DrinkType[] = [
    {id: 'beer', icon:'beer-foam', iconWeight:'far', label:'Pint of regular beer/lager/cider', units:2},
    {id: 'premuimBeer', icon:'beer-foam', iconWeight:'far', subIcon:'crown', subIconWeight:'fas', subIconPosition:'top', label:'Pint of premium beer/lager/cider', units:3},
    {id: 'beerHalf', icon:'glass-half',iconWeight:'fas', label:'Half pint of regular beer/lager/cider', units:1},
    {id: 'premuimBeerHalf', icon:'glass-half',iconWeight:'fas', subIcon:'crown', subIconWeight:'fas', subIconPosition:'top', label:'Half pint of premium beer/lager/cider', units:1.5},
    {id: 'can', icon:'can-regular', iconWeight:'fac', label:'Alcopop or can/bottle of regular lager', units:1.5},
    {id: 'premuimCan', icon:'can-regular', iconWeight:'fac', subIcon:'crown', subIconWeight:'fas', subIconPosition:'top', label:'Can of premium lager or strong beer (440ml)', units:2},
    {id: 'superStrengthCan', icon:'can-super-strength', iconWeight:'fac', label:'Can of super strength (440ml)', units:4},
    {id: 'wineSmall', icon:'wine-glass', iconWeight:'fas', subIcon:'arrow-down', subIconWeight:'fas', subIconPosition:'bottom', label:'Small glass of wine (125ml)', units:1.5},
    {id: 'wineMed', icon:'wine-glass', iconWeight:'fas', label:'Medium glass of wine (175ml)', units:2},
    {id: 'wineLg', icon:'wine-glass', iconWeight:'fas', subIcon:'arrow-up', subIconWeight:'fas', subIconPosition:'top', label:'Large glass of wine (250ml)', units:3},
    {id: 'wineBottle', icon:'wine-bottle', iconWeight:'fas', label:'Bottle of wine', units:9},
    {id: 'spirit', icon:'martini-glass', iconWeight:'fas', label:'1 single measure of spirits (25ml)', units:1},
    {id: 'sherry', icon:'champagne-glass', iconWeight:'fas', label:'1 small glass of sherry', units:1},
    {id: 'apertif', icon:'champagne-glass', iconWeight:'far', subIcon:'cutlery', subIconWeight:'fas', subIconPosition:'top', label:'1 single measure of apertifs', units:1},
  ]

  constructor(
    private http: HttpClient,
    private dateHelperService: DateHelperService
  ) {}

  fetchBetween(startDate: Date, endDate: Date): Observable<UserDrink[]> {
    if (!this.workoutsBetween) {
      this.workoutsBetween = {};
    }

    if (
      !this.workoutsBetween[
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
          '_' +
          this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      ]
    ) {
      let searchParams = new HttpParams();

      searchParams = searchParams.append(
        'startDate',
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD')
      );
      searchParams = searchParams.append(
        'endDate',
        this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      );
      this.workoutsBetween[
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
          '_' +
          this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      ] = this.http
        .get<any>(environment.apiUrl + this.slug, {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserDrink[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.workoutsBetween[
      this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
        '_' +
        this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
    ];
  }
  fetchAll(): Observable<UserDrink[]> {
    if (!this.allWorkouts) {
      this.allWorkouts = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserDrink[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                // parse the exercise data
                if (item.exercises) {
                  item.exercises = JSON.parse(item.exercises);
                }
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allWorkouts;
  }

  clearCache() {
    this.allWorkouts = null;
    this.workoutsBetween = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const item = new UserDrink(
            +responseData.user_drink_id,
            +responseData.user_id,
            responseData.date_recorded,
            +responseData.units,
            +responseData.drinks,
            responseData.drink_details,
            responseData.created,
            responseData.modified
          );
          return item;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    date_recorded: string,
    drinks: number,
    units: number,
    drink_details: string,
  ) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      drinks,
      units,
      drink_details,
    };
    return this.http.post<UserDrink>(
      environment.apiUrl + this.slug,
      payload,
      {
        observe: 'response',
      }
    );
  }

  update(
    id: number,
    date_recorded: string,
    drinks: number,
    units: number,
    drink_details: string,
  ) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      drinks,
      units,
      drink_details,
    };
    return this.http.patch<UserDrink>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }

  generateWeekArray(currentWeek: any, data: any) {
    const weekArray: any = [];
    currentWeek.forEach((day: any) => {
      let currentDay = {
        date: moment(day).format('YYYY-MM-DD'),
        drinks: 0,
        units: 0,
        hasData: 0,
        drink_details:{},
        entryId:0
      };
      data.forEach((item: UserDrink) => {
        if (item.date_recorded === currentDay.date) {
          currentDay.drinks = +item.drinks;
          currentDay.units = +item.units;
          currentDay.hasData = 1;
          currentDay.drink_details = JSON.parse(item.drink_details);
          currentDay.entryId = item.user_drink_id;
        }
      });
      weekArray.push(currentDay);
    });
    return weekArray;
  }

  getWeekScore(weekArray: any) {
    const weekScore = weekArray.reduce((accumulator: number, object: any) => {
      return accumulator + object.score;
    }, 0);
    return weekScore;
  }


  geterateWeekTotals(weekArray: any) {
    let totalUnits = 0;
    let drinkFreeDays = 0;
    let daysEntered = 0;
    weekArray.forEach((day: any) => {
      totalUnits+= +day.units;
      if(day.hasData){
        daysEntered++;
      }
      if(+day.drinks == 0 && moment(day.date).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD') && day.hasData){
        drinkFreeDays++;
      }
    });
    return { totalUnits, drinkFreeDays, daysEntered };
  }

  onlyUnique(value: any, index: number, self: any) {
    return self.indexOf(value) === index;
  }

  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }


  getDrinkTypeVal(typeId:any, typeVal:any){
    let returnVal = '';
    this.drinkTypes.forEach((drinkType:any)=>{
      if(drinkType.id == typeId && drinkType[typeVal]){
        returnVal = drinkType[typeVal];
      }
    });
    return returnVal;
  }
}
