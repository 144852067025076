export class UserBreathWorkout {
    constructor(
      public user_breath_workout_id: number,
      public user_id: number,
      public workout_date: string,
      public breaths: number,
      public resistance: number,
      public intensity: number,
      public created: string,
      public modified: string,
    ) {}
  }
