export class UserSmoking {
    constructor(
      public user_smoking_id: number,
      public user_id: number,
      public date_recorded: string,
      public cigarettes: number,
      public nicotine_replacement: number,
      public cessation: number,
      public created: string,
      public modified: string,
    ) {}
  }
