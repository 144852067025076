import { Options } from '@angular-slider/ngx-slider';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { UserFeelsService } from 'src/app/services/user-feels.service';

@Component({
  selector: 'app-feel-well-add-entry',
  templateUrl: './feel-well-add-entry.component.html',
  styleUrls: ['./feel-well-add-entry.component.scss'],
})
export class FeelWellAddEntryComponent implements OnInit {
  isLoading = false;
  bsDate = new Date();
  maxDate = new Date();
  userData: any;
  submitted = false;
  allEntries: any;

  options: Options = {
    floor: 0,
    ceil: 10,
    hideLimitLabels: true,
    hidePointerLabels: true,
    showTicks: true,
    getTickColor: (value: number): string => {
      if (value == 0) {
        return '#1D4586';
      }
      if (value == 1) {
        return '#385F9D';
      }
      if (value == 2) {
        return '#5277B2';
      }
      if (value == 3) {
        return '#8E7972';
      }
      if (value == 4) {
        return '#C98C55';
      }
      if (value == 5) {
        return '#EE9742';
      }
      if (value == 6) {
        return '#C4934B';
      }
      if (value == 7) {
        return '#A39152';
      }
      if (value == 8) {
        return '#7B8D5B';
      }
      if (value == 9) {
        return '#598B63';
      }
      if (value == 10) {
        return '#1E8670';
      }
      return '#cccccc';
    },
  };
  mood:number = 5;
  worry:number = 5;
  stress:number = 5;


  @ViewChild('entryForm', { static: false }) entryForm?: NgForm;
  constructor(
    private userFeelsService: UserFeelsService,
    private router: Router,
    private userService: UserService,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
    });
  }

  onSubmitForm() {
    this.userFeelsService
      .create(
        moment(this.entryForm?.value.date).format('YYYY-MM-DD'),
        this.mood,
        this.worry,
        this.stress
      )
      .subscribe((responseData) => {
        window.scroll(0, 0);
        this.submitted = true;
      });
  }

  // validattion stuff
  public scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector('form .ng-invalid');

    if (firstInvalidControl) {
      firstInvalidControl.focus();
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  public getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<any> | any {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if (this.submitted) {
      return true;
    } else if (
      !this.entryForm?.value.fruit_veg.fruit_veg &&
      !this.entryForm?.value.protein.protein &&
      !this.entryForm?.value.fat.fat &&
      !this.entryForm?.value.carbs.carbs &&
      !this.entryForm?.value.hydration.hydration
    ) {
      return true;
    }
  }
}
