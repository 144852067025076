import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { UserCardioService } from 'src/app/services/user-cardio.service';
import { UserTaskStatusService } from 'src/app/services/user-task-status.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fit-index',
  templateUrl: './fit-index.component.html',
  styleUrls: ['./fit-index.component.scss'],
})
export class FitIndexComponent implements OnInit {
  isLoading = false;
  userData: any;
  allWorkouts: any;
  cardioWeekData: any;
  cardioLastWeekData: any;
  cardioMinsWeek = 0;
  cardioMinsWeekForTarget = 0;
  cardioMinsWeekModerate = 0;
  cardioMinsWeekVigorous = 0;
  progress = 0;
  allTaskStatuses:any;

  canProgress = false;
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public lastWeek: Date[] = this.dateHelperService.getLastWeek();
  public today = this.dateHelperService.getToday();

  constructor(
    private dateHelperService: DateHelperService,
    private userService: UserService,
    private userCardioService: UserCardioService,
    private router: Router,
    private userTaskStatusService:UserTaskStatusService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
    });

    forkJoin({
      allWorkouts: this.userCardioService.fetchAll(),
      allUserTaskStatuses: this.userTaskStatusService.fetchAll()
    }).subscribe(
      (responseData) => {
        this.allWorkouts = responseData.allWorkouts;

        this.cardioWeekData = this.userCardioService.generateWeekArray(
          this.currentWeek,
          this.allWorkouts
        );
        this.cardioLastWeekData = this.userCardioService.generateWeekArray(
          this.lastWeek,
          this.allWorkouts
        );
        this.cardioMinsWeek = 0;
        this.cardioMinsWeekForTarget = 0;
        console.log(this.cardioWeekData);
        this.cardioWeekData.forEach((day: any) => {
          //this.cardioMinsWeek += +day.minutes;
          this.cardioMinsWeekModerate += day.moderateMinutes;
          this.cardioMinsWeekVigorous += day.vigorousMinutes;

        });


          // do it
          switch (+this.userData.fit_level) {
            case 1:
              this.cardioMinsWeekForTarget +=
                +this.cardioMinsWeekModerate + +this.cardioMinsWeekVigorous;
              break;

            case 2:
              this.cardioMinsWeekForTarget += +this.cardioMinsWeekVigorous;
              break;

            case 3:
              this.cardioMinsWeekForTarget += +this.cardioMinsWeekVigorous;
              break;
          }
        switch (+this.userData.fit_level) {
          case 1:
            this.progress =
              ((this.cardioMinsWeekModerate + this.cardioMinsWeekVigorous) /
                150) *
              100;
            break;
          case 2:
            this.progress = (this.cardioMinsWeekVigorous / 75) * 100;
            break;
        }

        // get last week data and see if they can progress?

        let lastWeekModerate = 0;
        let lastWeekVigorous = 0;
        this.cardioLastWeekData.forEach((data: any) => {
          lastWeekModerate += data.moderateMinutes;
          lastWeekVigorous += data.vigorousMinutes;
        });

        switch (+this.userData.fit_level) {
          case 1:
            // can they progress to Level 2?
            if (lastWeekModerate + lastWeekVigorous >= 150) {
              this.canProgress = true;
            }

            break;
          case 2:
            // can they progress to Level 2?
            if (lastWeekVigorous >= 75) {
              this.canProgress = true;
            }
            break;
        }



        this.allTaskStatuses = responseData.allUserTaskStatuses;
        // have they read the move well activity?
        let readMoveWell = this.userTaskStatusService.checkTaskStatus(
          this.allTaskStatuses,
          'MoveWellIntro'
        );

        if (!readMoveWell) {
          // forward on
          this.router.navigate(['/activity', 'resources', 1]);
        }


        let readPrepFit = this.userTaskStatusService.checkTaskStatus(
          this.allTaskStatuses,
          'PrepFitIntro'
        );

        if (!readPrepFit) {
          // forward on
          this.router.navigate(['/activity', 'fit', 'resources']);
        }



        this.isLoading = false;
      },
      (error) => {}
    );
  }

  onProgress(level: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Would you ike to progress to the next level of Prep Fit',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.value) {
        this.userCardioService.updateLevel(level).subscribe((responseData) => {
          this.userData.fit_level = level;
          this.canProgress = false;
        });
      }
    });
  }
}
