<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <h2>Nutrition Tracker</h2>
</nav>

<div class="container container-inner" *ngIf="userData">
  <button
    class="btn btn-primary w-100 mt-4"
    [routerLink]="['/modules', 'eat-well', 'add-entry']"
  >
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'circle-plus']"></fa-icon
    ></span>
    Add A Daily Entry
  </button>

  <div class="row">
    <div class="col-12 col-md-6">
      <div class="weeks-nav mt-4 mb-1" *ngIf="allWeeksData && showWeek > 0">
        <div class="prevnext">
          <button
            (click)="onPrevWeek()"
            *ngIf="showWeek > 1"
            class="btn btn-link"
          >
            <span class="icon-wrap"
              ><fa-icon
                [icon]="['far', 'chevron-circle-left']"
                aria-label="Previous week"
              ></fa-icon
            ></span>
          </button>
        </div>
        <div class="weeks-nav-title">
          <h4 class="text-center" *ngIf="userWeek">WEEK {{ showWeek }}</h4>
          <div>
            {{
              allWeeksData[userWeek - showWeek][0].date
                | customDate : "ddd Do MMM"
            }}
            -
            {{
              allWeeksData[userWeek - showWeek][6].date
                | customDate : "ddd Do MMM"
            }}
          </div>
        </div>
        <div class="prevnext">
          <button
            (click)="onNextWeek()"
            *ngIf="showWeek < userWeek"
            class="btn btn-link"
          >
            <span class="icon-wrap"
              ><fa-icon
                [icon]="['far', 'chevron-circle-right']"
                aria-label="Next week"
              ></fa-icon
            ></span>
          </button>
        </div>
      </div>

      <div class="card bg-dark text-white">
        <div class="card-body">
          <table class="tracker-table">
            <thead>
              <tr>
                <th></th>
                <th *ngFor="let day of allWeeksData[userWeek - showWeek]">
                  {{ day.date | customDate : "ddd" }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['far', 'carrot']"></fa-icon
                  ></span>
                </td>
                <td *ngFor="let day of allWeeksData[userWeek - showWeek]">
                  <span class="icon-wrap" *ngIf="+day.fruit_veg >= 5"
                    ><fa-icon [icon]="['fas', 'check']"></fa-icon
                  ></span>
                  <span
                    class="dot"
                    *ngIf="
                      +day.fruit_veg < 5 && day.date <= today && day.hasData
                    "
                  ></span>
                  <span *ngIf="day.date > today || !day.hasData">-</span>
                </td>
              </tr>

              <tr>
                <td>
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['far', 'egg']"></fa-icon
                  ></span>
                </td>
                <td *ngFor="let day of allWeeksData[userWeek - showWeek]">
                  <span class="icon-wrap" *ngIf="day.protein >= 3"
                    ><fa-icon [icon]="['fas', 'check']"></fa-icon
                  ></span>
                  <span
                    class="dot"
                    *ngIf="+day.protein < 3 && day.date <= today && day.hasData"
                  ></span>
                  <span *ngIf="day.date > today || !day.hasData">-</span>
                </td>
              </tr>

              <tr>
                <td>
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['far', 'peanut']"></fa-icon
                  ></span>
                </td>
                <td *ngFor="let day of allWeeksData[userWeek - showWeek]">
                  <span class="icon-wrap" *ngIf="day.fat >= 3"
                    ><fa-icon [icon]="['fas', 'check']"></fa-icon
                  ></span>
                  <span
                    class="dot"
                    *ngIf="day.fat < 3 && day.date <= today && day.hasData"
                  ></span>
                  <span *ngIf="day.date > today || !day.hasData">-</span>
                </td>
              </tr>

              <tr>
                <td>
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['far', 'baguette']"></fa-icon
                  ></span>
                </td>
                <td *ngFor="let day of allWeeksData[userWeek - showWeek]">
                  <span class="icon-wrap" *ngIf="day.carbs >= 3"
                    ><fa-icon [icon]="['fas', 'check']"></fa-icon
                  ></span>
                  <span
                    class="dot"
                    *ngIf="day.carbs < 3 && day.date <= today && day.hasData"
                  ></span>
                  <span *ngIf="day.date > today || !day.hasData">-</span>
                </td>
              </tr>

              <tr>
                <td class="border-0">
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['far', 'toilet']"></fa-icon
                  ></span>
                </td>
                <td
                  class="border-0"
                  *ngFor="let day of allWeeksData[userWeek - showWeek]"
                >
                  <span
                    class="icon-wrap"
                    *ngIf="+day.hydration <= 3 && day.hasData"
                    ><fa-icon [icon]="['fas', 'check']"></fa-icon
                  ></span>
                  <span
                    class="dot"
                    *ngIf="
                      +day.hydration > 3 && day.date <= today && day.hasData
                    "
                  ></span>
                  <span *ngIf="day.date > today || !day.hasData">-</span>
                </td>
              </tr>

              <tr>
                <td class="border-0">
                  <span class="tracker-table-label">Score</span>
                </td>
                <td
                  class="border-0"
                  *ngFor="let day of allWeeksData[userWeek - showWeek]"
                >
                  <span
                    class="circle circle-sm circle-lighten"
                    *ngIf="day.hasData"
                    >{{ day.score }}</span
                  >
                  <span
                    class="circle circle-sm circle-lighten"
                    *ngIf="day.date > today || !day.hasData"
                    >-</span
                  >
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-center">
            <div
              class="card-total card-total-inline mt-3 mb-2 card-total-light card-total-lg card-total-nb bg-dark"
            >
              <div class="card-total-title">Total Score</div>
              <span>{{ weekScore }}</span> / <span class="opacity-75">35</span>
            </div>
          </div>

          <hr />
          <div class="text-center">
            <button
              class="btn btn-link btn-link-nd text-white"
              (click)="showBreakdown = !showBreakdown"
            >
              Full Breakdown
              <span class="icon-wrap"
                ><fa-icon
                  *ngIf="showBreakdown"
                  [icon]="['fas', 'chevron-circle-up']"
                ></fa-icon
                ><fa-icon
                  *ngIf="!showBreakdown"
                  [icon]="['fas', 'chevron-circle-down']"
                ></fa-icon
              ></span>
            </button>

            <div class="breakdown" *ngIf="showBreakdown">
              <table class="tracker-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['far', 'carrot']"></fa-icon
                      ></span>
                      <div class="table-tracker-target">
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
                        ></span>
                        5+
                      </div>
                    </th>
                    <th>
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['far', 'egg']"></fa-icon
                      ></span>
                      <div class="table-tracker-target">
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
                        ></span>
                        3+
                      </div>
                    </th>
                    <th>
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['far', 'peanut']"></fa-icon
                      ></span>
                      <div class="table-tracker-target">
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
                        ></span>
                        3+
                      </div>
                    </th>
                    <th>
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['far', 'baguette']"></fa-icon
                      ></span>
                      <div class="table-tracker-target">
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
                        ></span>
                        3+
                      </div>
                    </th>
                    <th>
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['far', 'toilet']"></fa-icon
                      ></span>
                      <div class="table-tracker-target">
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
                        ></span>
                        1-3
                      </div>
                    </th>
                    <th>
                      <span class="tracker-table-label">Score</span>
                      <div class="table-tracker-target">
                        <span class="icon-wrap"
                          ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
                        ></span>
                        5
                      </div>
                    </th>
                    <th></th>
                    <!--th *ngFor="let day of eatWellWeekData">
                      {{ day.date | customDate : "ddd" }}
                    </th-->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let day of allWeeksData[userWeek - showWeek]">
                    <td>
                      {{ day.date | customDate : "ddd" }}
                    </td>

                    <td>
                      <span
                        class="circle circle-sm circle-lighten"
                        [ngClass]="{
                          'circle-lighten': day.fruit_veg < 5,
                          'bg-white text-dark-green': day.fruit_veg >= 5
                        }"
                      >
                        <span *ngIf="day.hasData">{{ day.fruit_veg }}</span>
                        <span *ngIf="!day.hasData">-</span>
                      </span>
                    </td>
                    <td>
                      <span
                        class="circle circle-sm circle-lighten"
                        [ngClass]="{
                          'circle-lighten': day.protein < 3,
                          'bg-white text-dark-green': day.protein >= 3
                        }"
                      >
                        <span *ngIf="day.hasData">{{ day.protein }}</span>
                        <span *ngIf="!day.hasData">-</span></span
                      >
                    </td>
                    <td>
                      <span
                        class="circle circle-sm circle-lighten"
                        [ngClass]="{
                          'circle-lighten': day.fat < 3,
                          'bg-white text-dark-green': day.fat >= 3
                        }"
                      >
                        <span *ngIf="day.hasData">{{ day.fat }}</span>
                        <span *ngIf="!day.hasData">-</span></span
                      >
                    </td>

                    <td>
                      <span
                        class="circle circle-sm circle-lighten"
                        [ngClass]="{
                          'circle-lighten': day.carbs < 3,
                          'bg-white text-dark-green': day.carbs >= 3
                        }"
                      >
                        <span *ngIf="day.hasData">{{ day.carbs }}</span>
                        <span *ngIf="!day.hasData">-</span></span
                      >
                    </td>

                    <td>
                      <span
                        class="circle circle-sm circle-lighten"
                        [ngClass]="{
                          'circle-lighten': day.hydration > 3 || !day.hasData,
                          'bg-white text-dark-green':
                            day.hydration <= 3 && day.hasData
                        }"
                      >
                        <span *ngIf="day.hasData">{{ day.hydration }}</span>
                        <span *ngIf="!day.hasData">-</span></span
                      >
                    </td>
                    <td>
                      <span
                        class="circle circle-sm circle-lighten"
                        [ngClass]="{
                          'circle-lighten': day.score < 5,
                          'bg-white text-dark-green': day.score >= 5
                        }"
                      >
                        <span *ngIf="day.hasData">{{ day.score }}</span>
                        <span *ngIf="!day.hasData">-</span></span
                      >
                    </td>
                    <td width="18">
                      <span
                        class="icon-wrap"
                        dropdown
                        [container]="'body'"
                        *ngIf="day.hasData"
                      >
                        <button
                          dropdownToggle
                          type="button"
                          class="btn text-white"
                          aria-controls="dropdown-basic"
                        >
                          <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                        </button>
                        <ul
                          *dropdownMenu
                          class="dropdown-menu dropdown-menu-right"
                          role="menu"
                          aria-labelledby="button-basic"
                        >
                          <li role="menuitem">
                            <a
                              class="dropdown-item"
                              [routerLink]="[
                                '/modules',
                                'eat-well',
                                'edit-entry',
                                day.entryId
                              ]"
                              >Edit</a
                            >
                          </li>
                          <li role="menuitem">
                            <a
                              class="dropdown-item"
                              (click)="onDeleteEntry(day.entryId)"
                              >Delete</a
                            >
                          </li>
                        </ul>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="border-0">
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
                      ></span>
                      <div class="tracker-table-label">Hit</div>
                    </td>
                    <td class="border-0">
                      {{
                        getDaysHit(
                          allWeeksData[userWeek - showWeek],
                          "fruit_veg"
                        )
                      }}
                      <div class="tracker-table-label">Days</div>
                    </td>
                    <td class="border-0">
                      {{
                        getDaysHit(allWeeksData[userWeek - showWeek], "protein")
                      }}
                      <div class="tracker-table-label">Days</div>
                    </td>
                    <td class="border-0">
                      {{ getDaysHit(allWeeksData[userWeek - showWeek], "fat") }}
                      <div class="tracker-table-label">Days</div>
                    </td>
                    <td class="border-0">
                      {{
                        getDaysHit(allWeeksData[userWeek - showWeek], "carbs")
                      }}
                      <div class="tracker-table-label">Days</div>
                    </td>
                    <td class="border-0">
                      {{
                        getDaysHit(
                          allWeeksData[userWeek - showWeek],
                          "hydration"
                        )
                      }}
                      <div class="tracker-table-label">Days</div>
                    </td>
                    <td class="border-0">
                      <span class="circle circle-sm bg-dark text-white">{{
                        getTotalDaysHitScore(allWeeksData[userWeek - showWeek])
                      }}</span>
                    </td>
                    <td class="border-0"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <h3 class="title-xl text-center mt-3">All Time</h3>

      <div class="card bg-light">
        <div class="card-body">
          <h4 class="text-center">Weekly Scores</h4>
          <div class="stat">
            <div class="days-indicator days-indicator-md text-center">
              <div
                class="day"
                *ngFor="
                  let week of allWeeksData.slice().reverse();
                  let i = index
                "
              >
                <div class="day-label">W{{ i + 1 }}</div>
                <div
                  class="day-status-lg day-status-circle"
                  [ngClass]="{
                    'text-white':
                      getTotalDaysHitScore(week) > 23 ||
                      getTotalDaysHitScore(week) < 15,
                    'text-dark':
                      getTotalDaysHitScore(week) < 24 &&
                      getTotalDaysHitScore(week) > 14,
                    'bg-success': getTotalDaysHitScore(week) > 23,
                    'bg-warning':
                      getTotalDaysHitScore(week) > 14 &&
                      getTotalDaysHitScore(week) < 24,
                    'bg-danger': getTotalDaysHitScore(week) < 15
                  }"
                >
                  <div class="day-status-title">
                    {{ getTotalDaysHitScore(week) }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h4 class="mt-2 text-center">
            Number of days individual group targets have been hit
          </h4>
          <div class="stat-flex stat-flex-stretch w-100" *ngIf="allTimeStats">
            <div class="stat">
              <span class="icon-wrap me-1 text-dark-green"
                ><fa-icon
                  [icon]="['far', 'carrot']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span>
              <span class="stat-title-sm">{{
                allTimeStats.daysHit.fruit_veg
              }}</span>
            </div>
            <div class="stat">
              <span class="icon-wrap me-1 text-dark-green"
                ><fa-icon [icon]="['far', 'egg']" [fixedWidth]="true"></fa-icon
              ></span>
              <span class="stat-title-sm">{{
                allTimeStats.daysHit.protein
              }}</span>
            </div>
            <div class="stat">
              <span class="icon-wrap me-1 text-dark-green"
                ><fa-icon
                  [icon]="['far', 'peanut']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span>
              <span class="stat-title-sm">{{ allTimeStats.daysHit.fat }}</span>
            </div>
            <div class="stat">
              <span class="icon-wrap me-1 text-dark-green"
                ><fa-icon
                  [icon]="['far', 'baguette']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span>
              <span class="stat-title-sm">{{
                allTimeStats.daysHit.carbs
              }}</span>
            </div>

            <div class="stat">
              <span class="icon-wrap me-1 text-dark-green"
                ><fa-icon
                  [icon]="['far', 'toilet']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span>
              <span class="stat-title-sm">{{
                allTimeStats.daysHit.hydration
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
