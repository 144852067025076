import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserTaskStatusService {
  error = new Subject<string>();
  allTaskStatuses? : Observable<any[]> | null = null;
  cacheTime = new Date();
  cacheLifetime = 1 * 60 * 1000;
  slug = '/user_task_statuses';
  resourceName = 'user_task_statuses';

  constructor(private http: HttpClient) {}

  fetchAll(): Observable<any[]>  {
    
    if (!this.allTaskStatuses) {
      this.allTaskStatuses = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: any[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allTaskStatuses;
  }


  clearCache() {
    this.allTaskStatuses = null;
  }

  create(
    task_id:any,
    status:any
  ) {

    this.clearCache();
    const taskStatussData = {
      task_id,
      status
    };
    return this.http.post<any>(
      environment.apiUrl + '/user_task_statuses',
      taskStatussData,
      {
        observe: 'response',
      }
    );
  }

  checkTaskStatus(allStatuses:any[], taskId:string){
    let returnStatus = null;
    allStatuses.forEach((_userStatus)=>{
      if(_userStatus.task_id == taskId){
        returnStatus = _userStatus.status;
      }
    });
    return returnStatus;
  }
}
