import {
  transition,
  trigger,
  query,
  style,
  animate,
  group,
  animateChild,
} from '@angular/animations';

const slideLeft = [
  query(
    ':leave',
    style({
      position: 'fixed',
      width: '100%',
      left: 0,
      right: 0,
      transform: 'translate3d(0%,0,0)',
    }),
    { optional: true }
  ),
  query(
    ':enter',
    style({
      position: 'fixed',
      width: '100%',
      left: 0,
      right: 0,
      transform: 'translate3d(-100%,0,0)',
    }),
    { optional: true }
  ),
  group([
    query(
      ':leave',
      group([
        animate(
          '250ms cubic-bezier(.35,0,.25,1)',
          style({ transform: 'translate3d(100%,0,0)' })
        ), // y: '-100%'
      ]),
      { optional: true }
    ),
    query(
      ':enter',
      group([
        animate(
          '250ms cubic-bezier(.35,0,.25,1)',
          style({ transform: 'translate3d(0%,0,0)' })
        ),
      ]),
      { optional: true }
    ),
  ]),
];

const slideRight = [
  query(
    ':leave',
    style({
      position: 'fixed',
      width: '100%',
      left: 0,
      right: 0,
      transform: 'translate3d(0%,0,0)',
    }),
    { optional: true }
  ),
  query(
    ':enter',
    style({
      position: 'fixed',
      width: '100%',
      left: 0,
      right: 0,
      transform: 'translate3d(100%,0,0)',
    }),
    { optional: true }
  ),

  group([
    query(
      ':leave',
      group([
        animate(
          '250ms cubic-bezier(.35,0,.25,1)',
          style({ transform: 'translate3d(-100%,0,0)' })
        ), // y: '-100%'
      ]),
      { optional: true }
    ),
    query(
      ':enter',
      group([
        animate(
          '250ms cubic-bezier(.35,0,.25,1)',
          style({ transform: 'translate3d(0%,0,0)' })
        ),
      ]),
      { optional: true }
    ),
  ]),
];

const fadeInOut = [
    query(':enter', [style({ opacity: 0 })], { optional: true }),

    query(
      ':leave',
      [style({ opacity: 1 }), animate('0.2s', style({ opacity: 0 }))],
      { optional: true }
    ),

    query(
      ':enter',
      [style({ opacity: 0 }), animate('0.2s', style({ opacity: 1 }))],
      { optional: true }
    ),
  ];

export const slideInAnimation = trigger('routeAnimations', [
  transition('* <=> *', slideRight),
  // transition('* <=> *', slideRight),
]);

export const fadeInAnimation = trigger('routeAnimationsDesktop', [
  transition('* => *', fadeInOut),
]);
