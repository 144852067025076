<div class="fh bg-light pb-10 content-container">
  <div class="row" *ngIf="userData">
    <div class="col-md-12">
      <h3 class="mb-3 sm-title">Update your details</h3>
      <div class="alert alert-danger" *ngIf="error">
        <p>{{ error }}</p>
      </div>
      <div *ngIf="isLoading" class="text-center">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <form
        #authForm="ngForm"
        (ngSubmit)="onSubmit(authForm)"
        *ngIf="!updated"
        class="standard-form"
        name="update-details"
      >
        <div class="form-group">
          <label for="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            class="form-control"
            [ngModel]="userData.first_name"
            name="firstName"
            placeholder="First Name"
            required
          />
        </div>
        <div class="form-group">
          <label for="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            class="form-control"
            [ngModel]="userData.last_name"
            name="lastName"
            placeholder="Last Name"
            required
          />
        </div>

        <div class="form-group">
            <label for="screenName">Username</label>
              
              <input
                type="text"
                id="username"
                class="form-control"
                [ngModel]="userData.username"
                name="username"
                placeholder="Username"
                required
                pattern="[a-zA-Z0-9]*"
                #usernameInput="ngModel"

              [ngClass]="{ 'is-invalid': usernameInput.touched && !usernameInput.valid }"
              />
              <span *ngIf="!usernameInput.valid">Please use numbers letters only</span>
          </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            id="email"
            class="form-control"
            [ngModel]="userData.email"
            name="email"
            placeholder="Email"
            required
            email
          />
        </div>


        <div class="form-group">
          <label for="language">Language</label>
          <select
            id="language"
            class="form-select"
            [ngModel]="userData.language_id"
            name="language"
            placeholder="language"
            required
          >
          <option [value]="undefined">Select a Language</option>
          <option [value]="''+language.id" *ngFor="let language of environment.languages">{{language.languageNative}}</option>
          </select>
        </div>


        <div class="form-group">
          <label for="week_start">Week Start</label>
          <select
            id="week_start"
            class="form-select"
            [ngModel]="userData.week_start"
            name="week_start"
            placeholder="Day of week"
            required
          >
          <option [value]="undefined">Select a Day</option>
          <option [value]="'mon'">Monday</option>
          <option [value]="'tue'">Tuesday</option>
          <option [value]="'wed'">Wednesday</option>
          <option [value]="'thu'">Thursday</option>
          <option [value]="'fri'">Friday</option>
          <option [value]="'sat'">Saturday</option>
          <option [value]="'sun'">Sunday</option>
          </select>
        </div>

        <div class="form-group">
          <label for="surgery_date">Sugery Date</label>

          <input
            type="text"
            id="surgery_date"
            class="form-control"
            [ngModel]="bsDate"
            #surgery_date="ngModel"
            name="surgery_date"
            placeholder="DD/MM/YYYY"
            required
            date
            bsDatepicker
            autocomplete="off"
            [maxDate]="maxDate"
            [bsConfig]="{
              dateInputFormat: 'DD/MM/YYYY',
              containerClass: 'theme-prepwell',
              showWeekNumbers: false
            }"
          />
          <div class="alert alert-danger" *ngIf="!surgery_date.valid && submitted">
            Please enter a date above
          </div>

        </div>



        <div class="form-group">
        <button
          type="submit"
          [disabled]="!authForm.valid || isLoading"
          class="btn btn-primary btn-block"
        >
          Update
        </button>
        </div>
      </form>
      <p *ngIf="updated">
        If your email exists you will have been sent a link to reset your
        password.
      </p>
      <hr />
    </div>
  </div>
</div>
