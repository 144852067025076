<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <div class="prev">
    <a [routerLink]="prev" *ngIf="prev"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-left']"></fa-icon></span
    ></a>
  </div>
  <h2>Level {{ level }}</h2>
  <div class="next">
    <a [routerLink]="next" *ngIf="next">
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-right']"></fa-icon></span
    ></a>
  </div>
</nav>

<div class="container container-inner" *ngIf="userData">
  <div class="row">
    <div class="col-12 col-md-12">
      <div class="card mt-3 bg-dark text-white mb-3">
        <img
          src="/assets/img/placeholder-move-level.jpg"
          class="card-img-top card-img-top-fh"
          alt="..."
        />
        <div class="card-body text-center">
          <div class="card-icon text-center">
            <span class="icon-wrap"
              ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
            ></span>
          </div>
          <p class="card-text" *ngIf="level">
            Complete at least 2 workouts over 7 days and comfortably do 3 sets of 8 repetitions during those workouts.
          </p>
          <div *ngIf="level == userData.move_level">
            <hr />

            <div class="days-indicator days-indicator-lg days-indicator-white">
              <div class="day" *ngFor="let day of moveWeekData">
                <div class="day-label">{{ day.date | customDate: "dddd" | slice: 0:1 }}</div>
                <div class="day-status" [ngClass]="{'bg-white':day.total_reps >143}"><span class="dot" *ngIf="day.total_reps>0 && day.total_reps<144"></span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="btn btn-primary w-100"
        *ngIf="level == userData.move_level"
        [routerLink]="['/activity','move','quick-add-entry']"
      >
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'circle-plus']"></fa-icon
        ></span>
        Add A Workout Entry
      </button>
    </div>
    <ng-container *ngIf="level == userData.move_level">
      <div class="col-12 col-md-6">
        <h4 class="text-center mt-4 mb-2">LAST 7 DAYS SUMMARY</h4>
        <div class="card bg-light">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-2">
              <div class="stat stat-flex">
                <div
                  class="icon-wrap card-icon-sm text-dark-green stat-icon-lg"
                >
                  <fa-icon [icon]="$any(['fac', 'lunge'])"></fa-icon>
                </div>
                <div class="stat-data-lg">1</div>
                <div class="stat-title-lg">
                  Workout <br />
                  Required
                </div>
              </div>
            </div>

            <div class="days-indicator days-indicator-lg text-center">
              <div class="day" *ngFor="let day of moveWeekData">
                <div class="day-label">
                  {{ day.date | customDate: "dddd" | slice: 0:1 }}
                </div>

                <div
                  class="day-status-lg bg-primary text-dark"
                  *ngIf="day.date <= today && +day.total_reps > 143"
                >
                  <div class="day-status-title">
                    <div class="icon-wrap">
                      <fa-icon [icon]="['fas', 'check']"></fa-icon>
                    </div>
                  </div>
                  <div class="day-status-subtitle">
                    {{ day.total_reps }} Reps
                  </div>
                </div>

                <div
                  class="day-status-lg bg-dark text-white"
                  *ngIf="day.date <= today && +day.total_reps < 144 && +day.total_reps > 0"
                >
                  <div class="day-status-title">{{ day.total_reps }}</div>
                  <div class="day-status-subtitle">Reps</div>
                </div>
                <div
                  class="day-status-lg bg-dark text-white"
                  *ngIf="day.date <= today && day.total_reps ==0"
                >
                  <div class="day-status-title">-</div>
                </div>
                <div
                  class="day-status-lg bg-dark text-white"
                  *ngIf="day.date > today"
                ></div>
              </div>
            </div>
            <p class="card-text text-center mt-2 px-2">
              {{moveDaysHit}} of a minimum 2 workouts completed at the target specified over the past 7 days.
            </p>
          </div>
          <div class="card-footer text-center relative text-dark bg-primary">
            <a 
            [routerLink]="['/activity','move','add-entry']" class="stretched-link"
              >Add Entry &nbsp;
              <span class="icon-wrap more-link text-dark"
                ><fa-icon
                  [icon]="['fas', 'circle-chevron-right']"
                ></fa-icon></span
            ></a>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <h4 class="text-center mt-4 mb-2">Level 1 - Workouts</h4>
        <div class="card bg-light">
          <div class="card-body text-center">
            <tabset [justified]="true" [className]="'nav-tabs-alt'">
              <tab heading="Session A" id="tab1">
                <div class="py-3">
                  <p class="card-text">
                    Complete 3 sets of the 6 exercises listed below. Do at least
                    8 repetitions of each exercise in a set.
                  </p>
                  <h5>Lower Body</h5>
                  <div class="video-links mb-3">
                    <a
                      [routerLink]="['/activity', 'move', 'exercise', '1', '1']"
                      class="video-link"
                    >
                      <span class="icon-wrap duo-primary-dark"
                        ><fa-icon [icon]="['fad', 'circle-play']"></fa-icon
                      ></span>
                      Hip hinge to chair
                    </a>
                    <a
                      [routerLink]="['/activity', 'move', 'exercise', '1', '1']"
                      class="video-link"
                    >
                      <span class="icon-wrap duo-primary-dark"
                        ><fa-icon [icon]="['fad', 'circle-play']"></fa-icon
                      ></span>
                      Chair sit-to-stand
                    </a>
                  </div>
                  <h5>Upper Body</h5>
                  <div class="video-links mb-3">
                    <a
                      [routerLink]="['/activity', 'move', 'exercise', '1', '1']"
                      class="video-link"
                    >
                      <span class="icon-wrap duo-primary-dark"
                        ><fa-icon [icon]="['fad', 'circle-play']"></fa-icon
                      ></span>
                      Hip hinge to chair
                    </a>
                    <a
                      [routerLink]="['/activity', 'move', 'exercise', '1', '1']"
                      class="video-link"
                    >
                      <span class="icon-wrap duo-primary-dark"
                        ><fa-icon [icon]="['fad', 'circle-play']"></fa-icon
                      ></span>
                      Chair sit-to-stand
                    </a>
                  </div>

                  <h5>Core</h5>
                  <div class="video-links">
                    <a
                      [routerLink]="['/activity', 'move', 'exercise', '1', '1']"
                      class="video-link"
                    >
                      <span class="icon-wrap duo-primary-dark"
                        ><fa-icon [icon]="['fad', 'circle-play']"></fa-icon
                      ></span>
                      Hip hinge to chair
                    </a>
                    <a
                      [routerLink]="['/activity', 'move', 'exercise', '1', '1']"
                      class="video-link"
                    >
                      <span class="icon-wrap duo-primary-dark"
                        ><fa-icon [icon]="['fad', 'circle-play']"></fa-icon
                      ></span>
                      Chair sit-to-stand
                    </a>
                  </div>
                </div>
              </tab>
              <tab heading="Session B"> </tab>
            </tabset>
          </div>
          <div class="card-footer text-center relative text-dark bg-primary">
            <a 
            [routerLink]="['/activity','move','add-entry']" class="stretched-link"
              >Start a Guided Workout &nbsp;
              <span class="icon-wrap more-link text-dark"
                ><fa-icon
                  [icon]="['fas', 'circle-chevron-right']"
                ></fa-icon></span
            ></a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <h4 class="text-center mt-4 mb-2">Recent Entries</h4>
        <div class="card bg-light">
          <div class="card-body">
            <table class="entries-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Set 1</th>
                  <th>Set 2</th>
                  <th>Set 3</th>
                  <th>Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let entry of moveLast7Days">
                  <tr *ngIf="entry.total_reps > 0">
                  <td>
                    <span class="et-cell text-dark"
                        ><span class="et-total">{{
                          entry.date | customDate: "DD"
                        }}</span
                        ><span class="et-label">{{
                          entry.date | customDate: "MMM"
                        }}</span>
                      </span>
                  </td>
                  <td>
                    <span class="et-cell"
                      ><span class="et-total">{{entry.set1_reps}}</span
                      ><span class="et-label">reps</span></span
                    >
                  </td>
                  <td>
                    <span class="et-cell"
                      ><span class="et-total">{{entry.set2_reps}}</span
                      ><span class="et-label">reps</span></span
                    >
                  </td>
                  <td>
                    <span class="et-cell"
                      ><span class="et-total">{{entry.set3_reps}}</span
                      ><span class="et-label">reps</span></span
                    >
                  </td>
                  <td>
                    <span class="et-cell text-dark"
                      ><span class="et-total">{{entry.total_reps}}</span
                      ><span class="et-label">reps</span></span
                    >
                  </td>
                  <td>
                    <span class="et-cell"
                      ><a href="">
                        <span class="icon-wrap text-primary"
                          ><fa-icon
                            [icon]="['fas', 'circle-chevron-right']"
                          ></fa-icon></span></a
                    ></span>
                  </td>
                </tr>
                </ng-container>
                
                
              </tbody>
            </table>
          </div>
          <div class="card-footer text-center relative text-dark bg-primary">
            <a href="" class="stretched-link"
              >View all Entries &nbsp;
              <span class="icon-wrap more-link text-dark"
                ><fa-icon
                  [icon]="['fas', 'circle-chevron-right']"
                ></fa-icon></span
            ></a>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="+level > 1 && level!= userData.move_level">
      <div class="col-12 col-md-6">
        <h4 class="text-center mt-4 mb-2">Level Locked</h4>
        <div class="card bg-light">
          <div class="card-body">
            <div class="card-icon text-center text-dark card-icon-lg">
              <span class="icon-wrap"
                ><fa-icon [icon]="['far', 'lock']"></fa-icon
              ></span>
            </div>
            <p class="card-text">
              Level {{ level }} will become unlocked once you have completed
              Level {{ level - 1 }}.
            </p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
