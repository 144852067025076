import { Options } from '@angular-slider/ngx-slider';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { UserService } from 'src/app/auth/service/user.service';
import { UserCardioService } from 'src/app/services/user-cardio.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fit-add-workout',
  templateUrl: './fit-add-workout.component.html',
  styleUrls: ['./fit-add-workout.component.scss'],
})
export class FitAddWorkoutComponent implements OnInit {
  isLoading = false;
  bsDate = new Date();
  maxDate = new Date();
  userData: any;
  submitted = false;
  exercises: any;
  durations = [
    5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95,
    100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150,
  ];
  intensityLabels: any = {
    0: 'At Rest ',
    1: 'very light activity',
    2: 'light activity',
    3: 'light activity',
    4: 'Moderate intensity exercise(can talk, but not sing)',
    5: 'Moderate intensity exercise(can talk, but not sing)',
    6: 'Moderate intensity exercise(can talk, but not sing)',
    7: 'high intensity exercise (unable to talk much)',
    8: 'high intensity exercise (unable to talk much)',
    9: 'Very high intensity exercise',
    10: 'Maximum effort',
  };
  maxDuration = 60;
  topDuration = this.durations[this.durations.length - 1];
  intensity: number = 5;
  options: Options = {
    floor: 0,
    ceil: 10,
    hideLimitLabels: true,
    hidePointerLabels: true,
    showTicks: true,
    getTickColor: (value: number): string => {
      if (value == 1) {
        return '#1D8670';
      }
      if (value == 2) {
        return '#608F62';
      }
      if (value == 3) {
        return '#879359';
      }
      if (value == 4) {
        return '#B39950';
      }
      if (value == 5) {
        return '#DB9E47';
      }
      if (value == 6) {
        return '#EF9742';
      }
      if (value == 7) {
        return '#ED853F';
      }
      if (value == 8) {
        return '#EA723D';
      }
      if (value == 9) {
        return '#E8603B';
      }
      if (value == 10) {
        return '#E54B38';
      }
      return '#cccccc';
    },
  };

  @ViewChild('entryForm', { static: false }) entryForm?: NgForm;
  constructor(
    private userCardioService: UserCardioService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
    });

    this.userCardioService.fetchExercises().subscribe((responseData) => {
      this.exercises = responseData;
    });
  }

  onSubmitForm() {
    this.userCardioService
      .create(
        this.entryForm?.value.minutes.minutes,
        moment(this.entryForm?.value.date).format('YYYY-MM-DD'),
        +this.entryForm?.value.exercise.exercise,
        this.intensity
      )
      .subscribe((responseData) => {
        window.scroll(0, 0);
        this.submitted = true;
        /*
        // refresh it
        Swal.fire({
          icon: 'success',
          title: 'Workout Added',
          text: 'Your workout was added',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/activity', 'fit', 'level', this.userData.fit_level]));*/
      });
  }

  onSetDuration(duration: number) {
    if (duration == this.maxDuration) {
      this.maxDuration += 30;
    }
  }
}
