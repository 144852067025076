import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserStep } from '../models/user-step.model';
import { DateHelperService } from './date-helper.service';

@Injectable({
  providedIn: 'root',
})
export class UserStepService {
  error = new Subject<string>();
  slug = '/user_steps';
  resourceName = 'user_steps';
  allSteps?: Observable<UserStep[]> | null = null;

  constructor(private http: HttpClient, private dateHelperService:DateHelperService) {}

  fetchAll(): Observable<UserStep[]> {
    if (!this.allSteps) {
      this.allSteps = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserStep[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allSteps;
  }

  clearCache() {
    this.allSteps = null;
  }

  create(steps: number, date_recorded: string) {
    this.clearCache();
    const payload = {
      steps,
      date_recorded,
    };
    return this.http.post<UserStep>(environment.apiUrl + this.slug, payload, {
      observe: 'response',
    });
  }

  getDaySteps(date: string, data: any):number {
    let daySteps = 0;
    data.forEach((item: UserStep) => {
      if (item.date_recorded === date) {
       if(item.steps){ daySteps = +item.steps;}
      }
    });
    return daySteps
  }

  getTotalSteps(data:any){
    let totalSteps = 0;
    data.forEach((day:any)=>{
      totalSteps+= +day.steps;
    });
    return totalSteps;
  }
  getBestDay(data:any){
    let topSteps = -1;
    let bestDay;
    data.forEach((day:any)=>{
     if(+day.steps > topSteps){
      bestDay = day;
      topSteps = +day.steps;
     }
    });
    return bestDay;
  }
  generateWeekArray(currentWeek: any, data: any) {
    const weekArray: any = [];
    currentWeek.forEach((day: any) => {
      let currentDay = {
        date: this.dateHelperService.formatDate(day, "YYYY-MM-DD"),
        steps: 0
      };
      data.forEach((item: UserStep) => {
        if (item.date_recorded === currentDay.date) {
          if(item.steps){currentDay.steps += +item.steps;}
        }
      });
      weekArray.push(currentDay);
    });

    return weekArray;
  }
}
