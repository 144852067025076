import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserBreathWorkout } from '../models/user-breath-workout.model';
import { DateHelperService } from './date-helper.service';

@Injectable({
  providedIn: 'root',
})
export class UserBreathWorkoutService {
  error = new Subject<string>();
  slug = '/user_breath_workouts';
  resourceName = 'user_breath_workouts';
  weeklyTarget = {
    threshold: 1,
    days: 3,
  };
  allWorkouts?: Observable<UserBreathWorkout[]> | null = null;
  workoutsBetween?: any;
  allExercises?: any;

  constructor(
    private http: HttpClient,
    private dateHelperService: DateHelperService
  ) {}

  fetchBetween(
    startDate: Date,
    endDate: Date
  ): Observable<UserBreathWorkout[]> {
    if (!this.workoutsBetween) {
      this.workoutsBetween = {};
    }

    if (
      !this.workoutsBetween[
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
          '_' +
          this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      ]
    ) {
      let searchParams = new HttpParams();

      searchParams = searchParams.append(
        'startDate',
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD')
      );
      searchParams = searchParams.append(
        'endDate',
        this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      );
      this.workoutsBetween[
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
          '_' +
          this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      ] = this.http
        .get<any>(environment.apiUrl + this.slug, {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserBreathWorkout[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.workoutsBetween[
      this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
        '_' +
        this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
    ];
  }
  fetchAll(): Observable<UserBreathWorkout[]> {
    if (!this.allWorkouts) {
      this.allWorkouts = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserBreathWorkout[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                // parse the exercise data
                if (item.exercises) {
                  item.exercises = JSON.parse(item.exercises);
                }
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allWorkouts;
  }

  clearCache() {
    this.allWorkouts = null;
    this.workoutsBetween = null;
    this.allExercises = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const item = new UserBreathWorkout(
            +responseData.user_breath_workout_id,
            +responseData.user_id,
            responseData.workout_date,
            +responseData.breaths,
            +responseData.resistance,
            +responseData.intensity,
            responseData.created,
            responseData.modified
          );
          return item;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    workout_date: string,
    breaths: number,
    resistance: number,
    intensity: number
  ) {
    this.clearCache();
    const payload = {
      workout_date: moment(workout_date).format('YYYY-MM-DD HH:mm:ss'),
      breaths,
      resistance,
      intensity,
    };
    return this.http.post<UserBreathWorkout>(
      environment.apiUrl + this.slug,
      payload,
      {
        observe: 'response',
      }
    );
  }

  update(
    id: number,
    workout_date: string,
    breaths: number,
    resistance: number,
    intensity: number
  ) {
    this.clearCache();
    const payload = {
      workout_date: moment(workout_date).format('YYYY-MM-DD HH:mm:ss'),
      breaths,
      resistance,
      intensity,
    };
    return this.http.patch<UserBreathWorkout>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }

  generateWeekArray(currentWeek: any, data: any) {
    const weekArray: any = [];
    currentWeek.forEach((day: any) => {
      let currentDay = {
        date: moment(day).format('YYYY-MM-DD'),
        am: { breaths: 0, resistance: 0, intensity: 0 },
        pm: { breaths: 0, resistance: 0, intensity: 0 },
      };
      data.forEach((item: UserBreathWorkout) => {
        if (
          moment(item.workout_date).format('YYYY-MM-DD') === currentDay.date
        ) {
          // am or pm
          if (+moment(item.workout_date).format('H') > 11) {
            // pm
            currentDay.pm.breaths = item.breaths;
            currentDay.pm.resistance = item.resistance;
            currentDay.pm.intensity = item.intensity;
          } else {
            currentDay.am.breaths = item.breaths;
            currentDay.am.resistance = item.resistance;
            currentDay.am.intensity = item.intensity;
          }
        }
      });
      weekArray.push(currentDay);
    });
    return weekArray;
  }

  getDaysHit(weekArray: any) {
    let daysHit = 0;
    weekArray.forEach((day: any) => {
      if (day.breaths > 0) {
        daysHit++;
      }
    });
    return daysHit;
  }

  onlyUnique(value: any, index: number, self: any) {
    return self.indexOf(value) === index;
  }

  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  fetchExercises(): Observable<any[]> {
    if (!this.allExercises) {
      this.allExercises = this.http
        .get<any>(environment.apiUrl + '/breath_exercises', {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData['_embedded']['breath_exercises'];
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allExercises;
  }

  getExercisesForSession(level: number, session: string, exercises: any) {
    const sessionATypes = [
      'Squat',
      'Deadlift',
      'Upper body pressing',
      'Upper body pulling',
      'Chest opening',
      'Core extension',
      'Core flexion',
    ];

    const sessionBTypes = [
      'Squat',
      'Lunge',
      'Upper body pressing',
      'Upper body pulling',
      'Overhead pressing',
      ' Core lateral flexion',
      'Isometric core',
    ];

    let selectedExercises: any = [];

    exercises.forEach((exercise: any) => {
      if (
        exercise.level == level &&
        ((session == 'A' && sessionATypes.indexOf(exercise.category) !== -1) ||
          (session == 'B' && sessionBTypes.indexOf(exercise.category) !== -1))
      ) {
        selectedExercises.push(exercise);
      }
    });
    return selectedExercises;
  }

  generatePastSevenDaysArray(data: any) {
    const weekArray: any = [];
    for (let i = 0; i < 7; i++) {
      let thisDay = moment().subtract(i, 'days').format('YYYY-MM-DD');

      let currentDay = {
        date: thisDay,
        am: { breaths: 0, resistance: 0, intensity: 0 },
        pm: { breaths: 0, resistance: 0, intensity: 0 },
      };
      data.forEach((item: UserBreathWorkout) => {
        if (
          moment(item.workout_date).format('YYYY-MM-DD') === currentDay.date
        ) {
          // am or pm
          if (+moment(item.workout_date).format('H') > 11) {
            // pm
            currentDay.pm.breaths = item.breaths;
            currentDay.pm.resistance = item.resistance;
            currentDay.pm.intensity = item.intensity;
          } else {
            currentDay.am.breaths = item.breaths;
            currentDay.am.resistance = item.resistance;
            currentDay.am.intensity = item.intensity;
          }
        }
      });
      weekArray.push(currentDay);
    }

    return weekArray;
  }

  getCurrentLevel(workouts: UserBreathWorkout[]) {
    let currentLevel = 0;
    let latestWorkout: any;
    workouts.forEach((workout) => {
      if (currentLevel == 0) {
        currentLevel = workout.resistance;
        latestWorkout = workout.workout_date;
      } else {
        if (workout.workout_date > latestWorkout) {
          currentLevel = workout.resistance;
          latestWorkout = workout.workout_date;
        }
      }
    });
    return currentLevel;
  }

  getLevelChange(workouts: UserBreathWorkout[]) {
    let levelChange: any = {};
    workouts.sort((a: any, b: any) => a.workout_date - b.workout_date);
    if (workouts.length > 1) {
      levelChange.prev = workouts[1].resistance;
      levelChange.current = workouts[0].resistance;
      levelChange.change = levelChange.current - levelChange.prev;
      if (levelChange.change > 0) {
        levelChange.label = 'Increased';
      }
      if (levelChange.change < 0) {
        levelChange.label = 'Decreased';
      }
      if (levelChange.change == 0) {
        levelChange.label = 'Maintained';
      }
    }

    return levelChange;
  }
}
