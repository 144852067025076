import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { UserBreathWorkoutService } from 'src/app/services/user-breath-workout.service';
import { UserTaskStatusService } from 'src/app/services/user-task-status.service';

@Component({
  selector: 'app-breathe-index',
  templateUrl: './breathe-index.component.html',
  styleUrls: ['./breathe-index.component.scss'],
})
export class BreatheIndexComponent implements OnInit {
  isLoading = false;
  userData: any;
  allWorkouts: any;
  breatheWeekData: any;
  currentLevel?: number;
  levelChange?: any;
  breatheLast7Days?: any;
  allTaskStatuses: any;

  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public today = this.dateHelperService.getToday();
  constructor(
    private dateHelperService: DateHelperService,
    private userService: UserService,
    private userBreathWorkoutService: UserBreathWorkoutService,
    private userTaskStatusService: UserTaskStatusService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
    });

    forkJoin({
      allWorkouts: this.userBreathWorkoutService.fetchAll(),
      allUserTaskStatuses: this.userTaskStatusService.fetchAll(),
    }).subscribe(
      (responseData) => {
        this.allWorkouts = responseData.allWorkouts;

        this.breatheWeekData = this.userBreathWorkoutService.generateWeekArray(
          this.currentWeek,
          this.allWorkouts
        );

        this.breatheLast7Days =
          this.userBreathWorkoutService.generatePastSevenDaysArray(
            this.allWorkouts
          );
        this.currentLevel = this.userBreathWorkoutService.getCurrentLevel(
          this.allWorkouts
        );
        this.levelChange = this.userBreathWorkoutService.getLevelChange(
          this.allWorkouts
        );

        this.allTaskStatuses = responseData.allUserTaskStatuses;
        // have they read the move well activity?
        let readMoveWell = this.userTaskStatusService.checkTaskStatus(
          this.allTaskStatuses,
          'MoveWellIntro'
        );

        if (!readMoveWell) {
          // forward on
          this.router.navigate(['/activity', 'resources', 1]);
        }


        // have they read the move well activity?
        let readBreatheWell = this.userTaskStatusService.checkTaskStatus(
          this.allTaskStatuses,
          'BreatheWellIntro'
        );

        if (!readBreatheWell) {
          // forward on
          this.router.navigate(['/activity', 'breathe', 'resources']);
        }

        this.isLoading = false;
      },
      (error) => {}
    );
  }
}
