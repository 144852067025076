import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-move-exercise',
  templateUrl: './move-exercise.component.html',
  styleUrls: ['./move-exercise.component.scss']
})
export class MoveExerciseComponent implements OnInit {

  level = 1;
  isLoading = false;
  next:any;
  prev:any;
  constructor() { }

  ngOnInit(): void {
  }

}
