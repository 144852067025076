import { Options } from '@angular-slider/ngx-slider';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin, Observable, Subject } from 'rxjs';
import { UserStrengthWorkoutService } from 'src/app/services/user-strength-workout.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-move-add-workout',
  templateUrl: './move-add-workout.component.html',
  styleUrls: ['./move-add-workout.component.scss'],
})
export class MoveAddWorkoutComponent implements OnInit {
  @ViewChild('form', { static: false }) entryForm?: NgForm;
  @ViewChild('repDataEl') repDataEl?: ElementRef;
  submitted = false;
  error? = '';
  maxDate = new Date();
  bsDate = new Date();
  step = 0;
  selectedExercises: any = [];
  exerciseData: any;
  exercises: any;
  repData: any;
  level = 1;
  session = 'A';
  set = 1;

  options: Options = {
    floor: 0,
    ceil: 10,
    hideLimitLabels:true,
    hidePointerLabels:true,
    showTicks:true,
    getTickColor: (value: number): string => {
      if (value ==1) {
        return '#1D8670';
      }
      if (value == 2) {
        return '#608F62';
      }
      if (value == 3) {
        return '#879359';
      }
      if (value == 4) {
        return '#B39950';
      }
      if (value == 5) {
        return '#DB9E47';
      }
      if (value == 6) {
        return '#EF9742';
      }
      if (value == 7) {
        return '#ED853F';
      }
      if (value == 8) {
        return '#EA723D';
      }
      if (value == 9) {
        return '#E8603B';
      }
      if (value == 10) {
        return '#E54B38';
      }
      return '#cccccc';
    }
  };

  constructor(
    private strengthWorkoutService: UserStrengthWorkoutService,
    private router: Router,
    private route: ActivatedRoute,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    // get the date if set
    this.route.queryParams.subscribe((params: any) => {
      if (params.d) {
        this.bsDate = moment(params.d).toDate();
      }
    });
    forkJoin({
      strengthExercises: this.strengthWorkoutService.fetchExercises(),
    }).subscribe((responseData) => {
      this.exercises = responseData.strengthExercises;
      this.selectedExercises =
        this.strengthWorkoutService.getExercisesForSession(
          this.level,
          this.session,
          this.exercises
        );
        
      this.repData = {
        set1: {},
        set2: {},
        set3: {},
      };
      this.selectedExercises.forEach((ex: any) => {
        this.repData.set1[ex.strength_exercise_id] = {};
        this.repData.set2[ex.strength_exercise_id] = {};
        this.repData.set3[ex.strength_exercise_id] = {};
        this.repData.set1[ex.strength_exercise_id].reps = '';
        this.repData.set2[ex.strength_exercise_id].reps = '';
        this.repData.set3[ex.strength_exercise_id].reps = '';
        this.repData.set1[ex.strength_exercise_id].intensity = 0;
        this.repData.set2[ex.strength_exercise_id].intensity = 0;
        this.repData.set3[ex.strength_exercise_id].intensity = 0;
      });
    });
  }
  onNextStep() {
    if (this.step >= this.selectedExercises.length) {
      // do the next set
      if (this.set < 3) {
        this.set++;
        this.step = 1;
        this.repDataEl?.nativeElement.focus();
      } else {
        // submit it!
        this.submitted = true;
        this.step++;
        this.strengthWorkoutService
          .create(
            moment().format('YYYY-MM-DD'),
            this.level,
            this.session,
            this.repData,
            this.countReps(1),
            this.countReps(2),
            this.countReps(3)
          )
          .subscribe(
            (responseData) => {
              console.log('saved');
            },
            (error) => {
              this.error = error.message;
            }
          );
      }
    } else {
      this.step++;
      this.repDataEl?.nativeElement.focus();
    }
  }
  onPrevStep() {
    this.step--;
  }

  // validattion stuff
  public scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector('form .ng-invalid');

    if (firstInvalidControl) {
      firstInvalidControl.focus();
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  public getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<any> | any {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if (this.submitted) {
      return true;
    } else if (this.selectedExercises.length == 0) {
      return true;
    }
  }

  public countReps(set: number) {
    let repCount = 0;
    if (this.exercises) {
      this.exercises.forEach((exercise: any) => {
        if (set == 0) {
          if (this.repData['set1'][exercise.strength_exercise_id]) {
            repCount += +this.repData['set1'][exercise.strength_exercise_id].reps;
          }
          if (this.repData['set2'][exercise.strength_exercise_id]) {
            repCount += +this.repData['set2'][exercise.strength_exercise_id].reps;
          }
          if (this.repData['set3'][exercise.strength_exercise_id]) {
            repCount += +this.repData['set3'][exercise.strength_exercise_id].reps;
          }
        } else {
          if (this.repData['set' + set][exercise.strength_exercise_id]) {
            repCount +=
              +this.repData['set' + set][exercise.strength_exercise_id].reps;
          }
        }
      });
    }
    return repCount;
  }
}
