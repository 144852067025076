import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserStepService } from './user-step.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityTrackerService {
  constructor(private http: HttpClient, private userStepService:UserStepService) {}

  setupTracker(
    trackerType: string,
    oauth_token?: string,
    oauth_verifier?: string
  ) {
    this.userStepService.clearCache();
    let queryParams = new HttpParams();

    if (oauth_token) {
      queryParams = queryParams.append('oauth_token', oauth_token);
    }
    if (oauth_verifier) {
      queryParams = queryParams.append('oauth_verifier', oauth_verifier);
    }

    return this.http
      .get<any>(environment.apiUrl + '/setup_tracker/' + trackerType, {
        responseType: 'json',
        params: queryParams,
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  syncTracker(trackerType:string) {
    let queryParams = new HttpParams();

   /* if (oauth_token) {
      queryParams = queryParams.append('oauth_token', oauth_token);
    }
    if (oauth_verifier) {
      queryParams = queryParams.append('oauth_verifier', oauth_verifier);
    }
*/
    this.userStepService.clearCache();
    return this.http
      .get<any>(environment.apiUrl + '/sync_tracker/' + trackerType, {
        responseType: 'json',
        params: queryParams,
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
}
