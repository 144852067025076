<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <h2>Add Entry</h2>
</nav>

<div class="container container-inner">
  <div class="row">
    <div class="col-12">
      <form
        name="entryForm"
        #entryForm="ngForm"
        class="standard-form mt-4"
        [hidden]="submitted"
      >
        <div class="row">
          <div class="col-12">
            <div class="form-group text-center">
              <label for="date">Date</label>
              <div class="form-group-inner">
                <input
                  type="text"
                  id="date"
                  class="form-control form-control-stub"
                  [ngModel]="entryDate"
                  #date="ngModel"
                  name="date"
                  placeholder="DD/MM/YYYY"
                  required
                  date
                  bsDatepicker
                  autocomplete="off"
                  [maxDate]="maxDate"
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY',
                    containerClass: 'theme-prepwell',
                    showWeekNumbers: false
                  }"
                />
                <div
                  class="alert alert-danger"
                  *ngIf="!date.valid && date.touched"
                >
                  Please enter a date above
                </div>
              </div>
            </div>

            <div class="form-group text-center mb-0">
              <label class="text-center">Food Groups</label>
            </div>

            <div class="form-group-container">
              <p class="instructions">
                Roughly, how many portions of these food <br />
                groups do you think you had today?
              </p>
              <hr />
              <div class="form-group text-center">
                <label for="fruit_veg">
                  <span class="icon-wrap text-dark-green"
                    ><fa-icon [icon]="['far', 'carrot']"></fa-icon
                  ></span>
                  Fruit &amp; Vegetables</label
                >
                <div
                  class="form-group-inner"
                  ngModelGroup="fruit_veg"
                  #fruit_veg="ngModelGroup"
                >
                  <div
                    class="radio-group radio-group-sm radio-group-text"
                    [ngClass]="{ chosen: fruit_veg.dirty || fruit_veg.touched }"
                  >
                    <div
                      class="form-check form-check-inline"
                      *ngFor="let i of [].constructor(6); let index = index"
                    >
                      <input
                        [ngModel]="userEatWell?.fruit_veg"
                        #fruitVegInput="ngModel"
                        class="form-check-input"
                        type="radio"
                        name="fruit_veg"
                        id="fruit_veg_{{ index }}"
                        [value]=" index "
                      />
                      <label
                        class="form-check-label"
                        for="fruit_veg_{{ index }}"
                        >{{ index }}
                        <ng-container *ngIf="index == 5">+</ng-container></label
                      >
                    </div>
                  </div>
                  <div
                    class="alert alert-danger"
                    *ngIf="!fruit_veg.valid && submitted"
                  >
                    {{ "Please select a value above" }}
                  </div>
                </div>
              </div>

              <hr />
              <div class="form-group text-center">
                <label for="protein"
                  ><span class="icon-wrap text-dark-green"
                    ><fa-icon [icon]="['far', 'egg']"></fa-icon
                  ></span>
                  'Healthy' Protein</label
                >
                <div
                  class="form-group-inner"
                  ngModelGroup="protein"
                  #protein="ngModelGroup"
                >
                  <div
                    class="radio-group radio-group-sm radio-group-text"
                    [ngClass]="{ chosen: protein.dirty || protein.touched }"
                  >
                    <div
                      class="form-check form-check-inline"
                      *ngFor="let i of [].constructor(4); let index = index"
                    >
                      <input
                      [ngModel]="userEatWell?.protein"
                        #proteinInput="ngModel"
                        class="form-check-input"
                        type="radio"
                        name="protein"
                        id="protein_{{ index }}"
                        [value]=" index "
                      />
                      <label class="form-check-label" for="protein_{{ index }}"
                        >{{ index }}
                        <ng-container *ngIf="index == 3">+</ng-container></label
                      >
                    </div>
                  </div>
                  <div
                    class="alert alert-danger"
                    *ngIf="!protein.valid && submitted"
                  >
                    {{ "Please select a value above" }}
                  </div>
                </div>
              </div>

              <hr />
              <div class="form-group text-center">
                <label for="fat"
                  ><span class="icon-wrap text-dark-green"
                    ><fa-icon [icon]="['far', 'peanut']"></fa-icon
                  ></span>
                  'Healthy' Fat Sources</label
                >
                <div
                  class="form-group-inner"
                  ngModelGroup="fat"
                  #fat="ngModelGroup"
                >
                  <div
                    class="radio-group radio-group-sm radio-group-text"
                    [ngClass]="{ chosen: fat.dirty || fat.touched }"
                  >
                    <div
                      class="form-check form-check-inline"
                      *ngFor="let i of [].constructor(4); let index = index"
                    >
                      <input
                      [ngModel]="userEatWell?.fat"
                        #fatInput="ngModel"
                        class="form-check-input"
                        type="radio"
                        name="fat"
                        id="fat_{{ index }}"
                        [value]="index"
                      />
                      <label class="form-check-label" for="fat_{{ index }}"
                        >{{ index }}
                        <ng-container *ngIf="index == 3">+</ng-container></label
                      >
                    </div>
                  </div>
                  <div
                    class="alert alert-danger"
                    *ngIf="!fat.valid && submitted"
                  >
                    {{ "Please select a value above" }}
                  </div>
                </div>
              </div>

              <hr />
              <div class="form-group text-center">
                <label for="carbs"
                  ><span class="icon-wrap text-dark-green"
                    ><fa-icon [icon]="['far', 'baguette']"></fa-icon
                  ></span>
                  'Healthy' Carbohyrates or starchy food</label
                >
                <div
                  class="form-group-inner"
                  ngModelGroup="carbs"
                  #carbs="ngModelGroup"
                >
                  <div
                    class="radio-group radio-group-sm radio-group-text"
                    [ngClass]="{ chosen: carbs.dirty || carbs.touched }"
                  >
                    <div
                      class="form-check form-check-inline"
                      *ngFor="let i of [].constructor(4); let index = index"
                    >
                      <input
                      [ngModel]="userEatWell?.carbs"
                        #carbsInput="ngModel"
                        class="form-check-input"
                        type="radio"
                        name="carbs"
                        id="carbs_{{ index }}"
                        [value]="index"
                      />
                      <label class="form-check-label" for="carbs_{{ index }}"
                        >{{ index }}
                        <ng-container *ngIf="index == 3">+</ng-container></label
                      >
                    </div>
                  </div>
                  <div
                    class="alert alert-danger"
                    *ngIf="!carbs.valid && submitted"
                  >
                    {{ "Please select a value above" }}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group text-center mb-0">
              <label class="text-center">Hydration</label>
            </div>
            <div class="form-group-container mt-4">
              <p class="instructions">
                By the middle of the day my urine colour was
              </p>
              <div class="form-group text-center">
                <div
                  class="form-group-inner"
                  ngModelGroup="hydration"
                  #hydration="ngModelGroup"
                >
                  <div
                    class="radio-group radio-group-sm radio-group-hydration radio-group-text"
                    [ngClass]="{ chosen: hydration.dirty || hydration.touched }"
                  >
                    <div
                      class="form-check form-check-inline"
                      *ngFor="let i of [].constructor(8); let index = index"
                    >
                      <input
                      [ngModel]="userEatWell?.hydration"
                        #hydrationInput="ngModel"
                        class="form-check-input"
                        type="radio"
                        name="hydration"
                        id="hydration_{{ index }}"
                        [value]="index"
                      />
                      <label
                        class="form-check-label"
                        for="hydration_{{ index }}"
                        ><span class="sr-only">{{ index }}</span>
                        <span class="icon-wrap text-dark"
                          ><fa-icon [icon]="['far', 'toilet']"></fa-icon
                        ></span>
                      </label>
                    </div>
                  </div>
                  <div
                    class="alert alert-danger"
                    *ngIf="!hydration.valid && submitted"
                  >
                    {{ "Please select a value above" }}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group text-center">
              <label>&nbsp;</label>
              <div>
                <button
                  (click)="onSubmitForm()"
                  class="btn btn-primary w-100"
                  [disabled]="date.invalid"
                >
                  Submit Entry
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div [hidden]="!submitted">
        <div class="submit-confirm">
          <h3>Excellent!</h3>
          <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'thumbs-up']"></fa-icon
          ></span>
          <h4>Entry Details</h4>
          <div class="entry-details">
            <div>
              <div class="label">Date</div>
              <div class="data">
                {{ date.value | customDate : "MMMM Do YYYY, h:mm:ss a" }}
              </div>
            </div>
            
          </div>
          <button
            class="btn btn-primary w-100 mt-4"
            [routerLink]="['/modules', 'eat-well', 'tracker']"
          >
            Back to Eat Well
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
