  <nav class="subnav">
    <div class="prev">
     
    </div>
    <h2>Share Your Story</h2>
    <div class="next">
      <a [routerLink]="['/social','healthcare-professional']">
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'circle-arrow-right']"></fa-icon></span
      ></a>
    </div>
  </nav>
  <div class="container relative bg-light fh pt-2">
  <div *ngIf="isLoading">
    <app-loading-spinner [inline]="1"></app-loading-spinner>
  </div>



  <div class="content-wrap">
    <div class="row">
      <div class="col">
        <ul class="tracker-list tracker-list-no-bt tracker-list-padded">
          <li *ngFor="let category of categories">
            <a
              [routerLink]="[
                '/social',
                'forum',
                'category',
                category.forum_category_id
              ]"
            >
              <span class="tracker-info tracker-info-lg">
                {{ category.title }}
                <span class="tracker-link">
                  <span class="icon-wrap float-end icon-more"
                    ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon></span
                ></span>

                <span class="details-sm"
                  >Topics: {{ category.topic_count | number }} | Messages:
                  {{ category.post_count | number }}
                </span>
                <span
                  class="details-sm mt-3"
                  *ngIf="category.latest_topic?.title"
                >
                  <span class="text-primary">{{
                    category.latest_topic?.title
                  }}</span
                  ><br />
                  {{ category.latest_topic?.modified | timeago: true }} at
                  {{
                    category.latest_topic?.modified
                      | customDate: "h:mma"
                      | lowercase
                  }}
                  <span *ngIf="category.latest_topic?.username"
                    >| {{ category.latest_topic?.username }}</span
                  >
                </span>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
