import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { UserStepService } from 'src/app/services/user-step.service';
import { UserTargetService } from 'src/app/services/user-target.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-steps-target',
  templateUrl: './steps-target.component.html',
  styleUrls: ['./steps-target.component.scss'],
})
export class StepsTargetComponent implements OnInit {
  @ViewChild('stepTargetForm', { static: false }) stepTargetForm?: NgForm;
  isLoading = false;
  userData: any;
  allSteps:any;
  stepTarget:any;
  constructor(
    private userTargetService: UserTargetService,
    private router: Router,
    private userService: UserService,
    private userStepService:UserStepService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
    });

    forkJoin({
      userTargets: this.userTargetService.fetchAll(),
      allSteps: this.userStepService.fetchAll(),
    }).subscribe(
      (responseData) => {
        this.allSteps = responseData.allSteps;
        this.stepTarget = this.userTargetService.getLatestTarget(
          responseData.userTargets,
          'step'
        );
        this.isLoading = false;
      },
      (error) => {}
    );
  }

  submitStepTarget() {
    this.userTargetService
      .create('step', this.stepTargetForm?.value.targetSteps, 'step')
      .subscribe((responseData) => {
        // refresh it
        Swal.fire({
          icon: 'success',
          title: 'Target Updated',
          text: 'Your target was successfully updated',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/activity', 'steps']));
      });
  }
}
