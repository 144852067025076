import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserCardioWorkout } from '../models/user-cardio-workout.model';
import { DateHelperService } from './date-helper.service';

@Injectable({
  providedIn: 'root',
})
export class UserCardioService {
  error = new Subject<string>();
  slug = '/user_cardio_workouts';
  resourceName = 'user_cardio_workouts';
  level = 1;
  allWorkouts?: Observable<UserCardioWorkout[]> | null = null;
  allExercises?: any;

  constructor(
    private http: HttpClient,
    private dateHelperService: DateHelperService
  ) {}

  setLevel(level: number) {
    this.level = level;
  }
  fetchAll(): Observable<UserCardioWorkout[]> {
    if (!this.allWorkouts) {
      this.allWorkouts = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserCardioWorkout[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allWorkouts;
  }

  clearCache() {
    this.allWorkouts = null;
    this.allExercises = null;
  }

  create(
    minutes: number,
    workout_date: string,
    exercise_id: number,
    intensity: number
  ) {
    this.clearCache();
    const payload = {
      minutes,
      workout_date,
      exercise_id,
      intensity,
    };
    return this.http.post<UserCardioWorkout>(
      environment.apiUrl + this.slug,
      payload,
      {
        observe: 'response',
      }
    );
  }

  getDaySteps(date: string, data: any): number {
    let daySteps = 0;
    data.forEach((item: UserCardioWorkout) => {
      if (item.workout_date === date) {
        if (item.minutes) {
          daySteps = +item.minutes;
        }
      }
    });
    return daySteps;
  }

  getTotalSteps(data: any) {
    let totalSteps = 0;
    data.forEach((day: any) => {
      totalSteps += +day.minutes;
    });
    return totalSteps;
  }
  getBestDay(data: any) {
    let topSteps = -1;
    let bestDay;
    data.forEach((day: any) => {
      if (+day.minutes > topSteps) {
        bestDay = day;
        topSteps = +day.minutes;
      }
    });
    return bestDay;
  }
  generateWeekArray(currentWeek: any, data: any) {
    const weekArray: any = [];
    currentWeek.forEach((day: any) => {
      let currentDay = {
        date: this.dateHelperService.formatDate(day, 'YYYY-MM-DD'),
        minutes: 0,
        moderateMinutes: 0,
        vigorousMinutes: 0,
      };
      data.forEach((item: UserCardioWorkout) => {
        if (item.workout_date === currentDay.date) {
          if (item.minutes) {
            currentDay.minutes += +item.minutes;
            
            if(item.intensity && +item.intensity>=4 && +item.intensity<7){
              currentDay.moderateMinutes+= +item.minutes;
            }
            if(item.intensity && +item.intensity>=7){
              currentDay.vigorousMinutes+= +item.minutes;
            }

          }
        }
      });
      weekArray.push(currentDay);
    });

    return weekArray;
  }

  generatePastSevenDaysArray(data: any) {
    const weekArray: any = [];
    for (let i = 0; i < 7; i++) {
      let thisDay = moment().subtract(i, 'days').format('YYYY-MM-DD');

      let currentDay = {
        date: thisDay,
        minutes: 0,
        low: 0,
        mod: 0,
        high: 0,
      };
      data.forEach((item: UserCardioWorkout) => {
        if (item.workout_date === currentDay.date) {
          if (item.minutes) {
            currentDay.minutes += +item.minutes;
            // do the intensities
            if (item.intensity) {
              if (+item.intensity < 4) {
                currentDay.low += +item.minutes;
              }
              if (+item.intensity > 3 && +item.intensity < 7) {
                currentDay.mod += +item.minutes;
              }
              if (+item.intensity > 6) {
                currentDay.high += +item.minutes;
              }
            }
          }
        }
      });
      weekArray.push(currentDay);
    }

    return weekArray;
  }

  fetchExercises(): Observable<any[]> {
    if (!this.allExercises) {
      this.allExercises = this.http
        .get<any>(environment.apiUrl + '/exercises', {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData['_embedded']['exercises'];
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allExercises;
  }

  updateLevel(level:number){
    const payload = {
      level: level,
      area: 'fit'
    };
    return this.http.post<any>(
      environment.apiUrl + '/user_move_well_levels',
      payload,
      {
        observe: 'response',
      }
    );
  }
}
