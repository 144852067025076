import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit {
  @Input() title:any;
  @Input() subTitle:any;
  @Input() backLink:any;
  constructor() { }

  ngOnInit(): void {
  }

}
