<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <div class="prev">
    <a href=""
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-left']"></fa-icon></span
    ></a>
  </div>
  <h2>Overview</h2>
  <div class="next">
    <a [routerLink]="['/activity', 'fit', 'level', '1']">
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-right']"></fa-icon></span
    ></a>
  </div>
</nav>

<div class="container container-inner">
  <div class="row">
    <div class="col-12">
      <form
        name="entryForm"
        #entryForm="ngForm"
        class="standard-form mt-4"
        [hidden]="submitted"
      >
        <div class="row">
          <div class="col-12">
            <div class="form-group text-center">
              <label for="date">Date</label>
              <div class="form-group-inner">
                <input
                  type="text"
                  id="date"
                  class="form-control form-control-stub"
                  [ngModel]="bsDate"
                  #date="ngModel"
                  name="date"
                  placeholder="DD/MM/YYYY"
                  required
                  date
                  bsDatepicker
                  autocomplete="off"
                  [maxDate]="maxDate"
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY',
                    containerClass: 'theme-prepwell',
                    showWeekNumbers: false
                  }"
                />
                <div
                  class="alert alert-danger"
                  *ngIf="!date.valid && date.touched"
                >
                  Please enter a date above
                </div>
              </div>
            </div>
            <div class="form-group text-center">
              <label for="exercise">Exercise</label>
              <div
                class="form-group-inner"
                ngModelGroup="exercise"
                #exercise="ngModelGroup"
              >
                <div
                  class="radio-group radio-group-text"
                  [ngClass]="{ chosen: exercise.dirty || exercise.touched }"
                >
                  <div
                    class="form-check form-check-inline"
                    *ngFor="let exercise of exercises"
                  >
                    <input
                      [ngModel]
                      #exerciseInput="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="exercise"
                      id="exercise_{{ exercise.exercise_id }}"
                      value="{{ exercise.exercise_id }}"
                    />
                    <label
                      class="form-check-label"
                      for="exercise_{{ exercise.exercise_id }}"
                      >{{ exercise.name }}</label
                    >
                  </div>
                </div>
                <div
                  class="alert alert-danger"
                  *ngIf="!exercise.valid && submitted"
                >
                  {{ "Please select a value above" }}
                </div>

                <div
                  class="alert alert-danger"
                  *ngIf="!date.valid && date.touched"
                >
                  Please enter a date above
                </div>

                <p class="card-text mt-2">
                  Please select from the options above
                </p>
              </div>
            </div>
            <div class="form-group text-center">
              <label for="duration">Duration</label>
              <div 
              class="form-group-inner"
              ngModelGroup="minutes"
              #minutes="ngModelGroup">
                <div
                  class="radio-group radio-group-text"
                  
                  [ngClass]="{ chosen: minutes.dirty || minutes.touched }"
                >
                  <div class="form-check form-check-inline" *ngFor="let duration of durations; let index = index" [hidden]="duration>maxDuration">
                   
                    <input
                      [ngModel]
                      #minutesInput="ngModel"
                      class="form-check-input"
                      type="radio"
                      
                      name="minutes"
                      id="minutes_{{index}}"
                      value="{{duration}}"
                    />
                    <label class="form-check-label" for="minutes_{{index}}"
                    (click)="onSetDuration(duration)"
                      >{{duration}} <ng-container  *ngIf="duration==maxDuration && duration< topDuration">+</ng-container><br>Mins</label
                    >
                    </div>
                  
                </div>
                <div
                  class="alert alert-danger"
                  *ngIf="!minutes.valid && submitted"
                >
                  {{ "Please select a value above" }}
              </div>

               
              </div>
            </div>
            <div class="form-group text-center">
              <label for="intensity">Intensity</label>
              <div class="form-group-inner">
                <div class="stat stat-flex stat-intensity">
                  <div class="stat-data-lg">{{ intensity }}</div>
                  <div class="stat-title-lg">{{intensityLabels[intensity]}}</div>
                </div>
                <div class="temp-slider">
                  <ngx-slider
                    [(value)]="intensity"
                    [options]="options"
                  ></ngx-slider>
                </div>

                <p class="card-text mt-2">Drag the cursor above to select</p>
              </div>
            </div>
            <div class="form-group text-center">
              <label>&nbsp;</label>
              <div>
                <button
                  (click)="onSubmitForm()"
                  class="btn btn-primary w-100"
                  [disabled]="date.invalid"
                >
                  Submit Entry
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div [hidden]="!submitted">
        <div class="submit-confirm">
          <h3>Excellent!</h3>
          <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'thumbs-up']"></fa-icon
          ></span>
          <h4>Entry Details</h4>
          <div class="entry-details">
            <div>
              <div class="label">Date</div>
              <div class="data">{{date.value | customDate : 'MMMM Do YYYY, h:mm:ss a'}}</div>
            </div>
            <div>
              <div class="label">Exercise</div>
              <div class="data">
                <ng-container *ngFor="let exercise of exercises">
                  <span *ngIf="exercise.exercise_id == entryForm?.value.exercise.exercise">{{exercise.name}}</span>
                </ng-container>
              </div>
            </div>
            <div>
              <div class="label">Duration</div>
              <div class="data" *ngIf="entryForm?.value.minutes">{{entryForm?.value.minutes.minutes}} Mins</div>
            </div>
            <div>
              <div class="label">Intensity</div>
              <div class="data">{{intensity}} - {{intensityLabels[intensity]}}</div>
            </div>
          </div>
          <button class="btn btn-primary w-100 mt-4" [routerLink]="['/activity', 'fit', 'level', userData?.fit_level]">Back to Prep Fit</button>
        </div>
      </div>
    </div>
  </div>
</div>
