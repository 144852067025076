import { Injectable } from '@angular/core';
import { ResourcesService } from './resources.service';
import { SiteContentService } from './site-content.service';

@Injectable({
  providedIn: 'root'
})
export class CacheManagerService {

  constructor(
    private resoucesService:ResourcesService,
    private siteContentService:SiteContentService) { }

    clearAllCache(){
      this.resoucesService.clearCache();
      this.siteContentService.clearCache();

    }
}
