import { Options } from '@angular-slider/ngx-slider';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { UserSleepsService } from 'src/app/services/user-sleeps.service';

@Component({
  selector: 'app-sleep-well-add-entry',
  templateUrl: './sleep-well-add-entry.component.html',
  styleUrls: ['./sleep-well-add-entry.component.scss'],
})
export class SleepWellAddEntryComponent implements OnInit {
  isLoading = false;
  bsDate = new Date();
  maxDate = new Date();
  userData: any;
  submitted = false;
  allEntries: any;
  colors = [
    '#E54B38',
    '#D06461',
    '#BF7780',
    '#B28597',
    '#A495B1',
    '#90ACD8',
    '#6D8FC4',
    '#5277B2',
    '#385F9D',
    '#1D4586',
    '#0C2F69'
  ];

  options: Options = {
    floor: 0,
    ceil: 10,
    hideLimitLabels: true,
    hidePointerLabels: true,
    showTicks: true,
    getTickColor: (value: number): string => {
      return this.colors[value];
    },
  };
  quality:number = 5;
  gettingToSleep:number = 5;
  awakening:number = 5;


  @ViewChild('entryForm', { static: false }) entryForm?: NgForm;
  constructor(
    private userSleepsService: UserSleepsService,
    private router: Router,
    private userService: UserService,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
    });
  }

  onSubmitForm() {
    this.userSleepsService
      .create(
        moment(this.entryForm?.value.date).format('YYYY-MM-DD'),
        this.quality,
        this.gettingToSleep,
        this.awakening
      )
      .subscribe((responseData) => {
        window.scroll(0, 0);
        this.submitted = true;
      });
  }

  // validattion stuff
  public scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector('form .ng-invalid');

    if (firstInvalidControl) {
      firstInvalidControl.focus();
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  public getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<any> | any {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if (this.submitted) {
      return true;
    } else if (
      !this.entryForm?.value.fruit_veg.fruit_veg &&
      !this.entryForm?.value.protein.protein &&
      !this.entryForm?.value.fat.fat &&
      !this.entryForm?.value.carbs.carbs &&
      !this.entryForm?.value.hydration.hydration
    ) {
      return true;
    }
  }
}
