import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { UserStrengthWorkoutService } from 'src/app/services/user-strength-workout.service';
import { UserTaskStatusService } from 'src/app/services/user-task-status.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-move-index',
  templateUrl: './move-index.component.html',
  styleUrls: ['./move-index.component.scss'],
})
export class MoveIndexComponent implements OnInit {
  isLoading = false;
  userData: any;
  allWorkouts: any;
  moveWeekData: any;
  moveMinsWeek = 0;
  progress = 0;
  canProgress = false;
  allTaskStatuses: any;

  moveLastWeekData: any;
  moveMinsLastWeek = 0;
  lastWeekLevel = 0;
  lastWeekWorkouts = 0;

  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public lastWeek: Date[] = this.dateHelperService.getLastWeek();
  public today = this.dateHelperService.getToday();

  constructor(
    private dateHelperService: DateHelperService,
    private userService: UserService,
    private userStrengthWorkoutService: UserStrengthWorkoutService,
    private userTaskStatusService:UserTaskStatusService,
    private router:Router
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
    });

    forkJoin({
      allWorkouts: this.userStrengthWorkoutService.fetchAll(),
      allUserTaskStatuses: this.userTaskStatusService.fetchAll(),
    }).subscribe(
      (responseData) => {
        this.allWorkouts = responseData.allWorkouts;

        this.moveWeekData = this.userStrengthWorkoutService.generateWeekArray(
          this.currentWeek,
          this.allWorkouts
        );

        this.moveLastWeekData =
          this.userStrengthWorkoutService.generateWeekArray(
            this.lastWeek,
            this.allWorkouts
          );

        this.moveLastWeekData.forEach((day: any) => {
          if (day.level > 0) {
            this.lastWeekLevel = day.level;
          }
          if (+day.total_reps > 0) {
            this.lastWeekWorkouts++;
          }
        });

        switch (+this.userData.move_level) {
          case 1:
            // can they progress to Level 2?
            if (this.lastWeekLevel == 1 && this.lastWeekWorkouts >= 2) {
              this.canProgress = true;
            }

            break;
          case 2:
            // can they progress to Level 2?
            if (this.lastWeekLevel == 2 && this.lastWeekWorkouts >= 2) {
              this.canProgress = true;
            }
            break;
        }

        this.allTaskStatuses = responseData.allUserTaskStatuses;
        // have they read the move well activity?
        let readMoveWell = this.userTaskStatusService.checkTaskStatus(
          this.allTaskStatuses,
          'MoveWellIntro'
        );

        if (!readMoveWell) {
          // forward on
          this.router.navigate(['/activity', 'resources', 1]);
        }



        let readPrepStrong = this.userTaskStatusService.checkTaskStatus(
          this.allTaskStatuses,
          'PrepStrongIntro'
        );

        if (!readPrepStrong) {
          // forward on
          this.router.navigate(['/activity', 'move', 'resources']);
        }

        this.isLoading = false;
      },
      (error) => {}
    );
  }

  onProgress(level: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Would you ike to progress to the next level of Prep Strong',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.value) {
        this.userStrengthWorkoutService
          .updateLevel(level)
          .subscribe((responseData) => {
            this.userData.move_level = level;
            this.canProgress = false;
          });
      }
    });
  }
}
