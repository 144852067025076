import { SettingsService } from './services/settings.service';
import { AuthService } from './auth/service/auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { AnalyticsService } from './services/analytics.service';
import { UpdateService } from './services/update.service';
import { environment } from './../environments/environment';
import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent,
} from 'ngx-cookieconsent';
import { ViewportScroller } from '@angular/common';
import { slideInAnimation, fadeInAnimation } from './route-animations';
import { UserService } from './auth/service/user.service';
import { ActivityTrackerService } from './services/activity-tracker.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation, fadeInAnimation],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'base-pwa'; // keep refs to subscriptions to be able to unsubscribe later
  public innerWidth!: number;
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializeSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;
  private checkSiteStatusInterval: any;
  private activityInterval:any;
  private siteStatus = 1;
  private userData:any;

  constructor(
    private sw: UpdateService,
    private router: Router,
    private titleService: Title,
    private ccService: NgcCookieConsentService,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private viewportScroller: ViewportScroller,
    private settingsService: SettingsService,
    private userService:UserService,
    private activityTrackerService:ActivityTrackerService
  ) {
    // check the service worker for updates
    this.sw.checkForUpdates();
  }

  ngOnInit() {
    this.checkSiteStatus();
    this.checkSiteStatusInterval = interval(60000).subscribe((count) => {
      this.checkSiteStatus();
    });
    // try and restpore session
    this.authService.restore().subscribe(
      (restoreResponsedata) => {
        if (this.router.url.split('?')[0] === '/login') {
          if (this.authService.redirect) {
            this.router.navigateByUrl(this.authService.redirect);
          } else {
            this.router.navigate(['/']);
          }
        }
      },
      (errorMessage) => {
        // try autologin here?
        this.authService.autoLogin()?.subscribe(
          (resData) => {
            if (this.router.url.split('?')[0] === '/login') {
              if (this.authService.redirect) {
                this.router.navigateByUrl(this.authService.redirect);
              } else {
                this.router.navigate(['/dashboard']);
              }
            }
          },
          (errorMessage) => {
            // console.log(errorMessage);
          }
        );
      }
    );

    // scroll to top on route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (this.siteStatus === 0 && this.router.url != '/offline') {
          this.router.navigate(['/offline']);
      }
      window.scrollTo(0, 0);

      // set page title
      const title = this.getTitle(
        this.router.routerState,
        this.router.routerState.root
      ).join(' | ');
      this.titleService.setTitle(environment.title + ' | ' + title);
      this.analyticsService.trackVirtualPageview(
        environment.title + ' | ' + title
      );
    });
    // cookie consent

    if (this.ccService.hasConsented()) {
      // start tracking
      this.analyticsService.startTracking();
    }
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        if (event.status === 'deny') {
          this.analyticsService.stopTracking();
        } else {
          this.analyticsService.startTracking();
        }
      }
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );
    // end cookie consent
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
      });



  this.activityInterval = 
    interval(60000).subscribe(() =>{
      if(this.userData){
        if(this.userData.device_type == 'garmin'){
        this.activityTrackerService.syncTracker('garmin').subscribe((responseData)=>{
         // console.log('activity checked')
        });
        }
      }
    }
    );
  }

  getTitle(state: any, parent: any): any {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.pageTitle) {
      data.push(parent.snapshot.data.pageTitle);
    }

    if (parent && parent.snapshot.data && parent.snapshot.data.subTitle) {
      data.push(parent.snapshot.data.subTitle);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }
  showMobile(routerOutlet: any) {
    if (this.innerWidth < 400) {
      return (
        routerOutlet &&
        routerOutlet.activatedRouteData &&
        routerOutlet.activatedRouteData['animation']
      );
    }
  }
  showDesktop(routerOutlet: any) {
    if (this.innerWidth > 399) {
      return (
        routerOutlet &&
        routerOutlet.activatedRouteData &&
        routerOutlet.activatedRouteData['animation']
      );
    }
  }

  public onClick(event: any, elementId: string): void {
    event.preventDefault();
    this.viewportScroller.scrollToAnchor(elementId);
  }
  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription?.unsubscribe();
    this.popupCloseSubscription?.unsubscribe();
    this.initializeSubscription?.unsubscribe();
    this.statusChangeSubscription?.unsubscribe();
    this.revokeChoiceSubscription?.unsubscribe();
    this.noCookieLawSubscription?.unsubscribe();

    this.checkSiteStatusInterval.unsubscribe();
    this.activityInterval.unsubscribe();
  }

  checkSiteStatus() {
    this.settingsService.fetchAll().subscribe(
      (responseData) => {
        this.siteStatus = +responseData.online;
        if (this.siteStatus !== 1) {
          this.router.navigate(['offline']);
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }
}
