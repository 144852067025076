<div>
  <div class="container-fluid content-container fh login">
    <img src="/assets/img/logo-prepwell-light.svg" alt="Prepwell logo" class="logo" width="200">
    <h2>Forgotten Password</h2>
    <div class="row">
      <div class="col-md-4 offset-md-4">
        <div class="alert alert-danger" *ngIf="error">
          <p>{{ error }}</p>
        </div>
        <div *ngIf="isLoading" class="text-center">
          <app-loading-spinner></app-loading-spinner>
        </div>
        <form
          #authForm="ngForm"
          (ngSubmit)="onSubmit(authForm)"
          *ngIf="!isLoading && !sent"
        >
          <div class="form-group form-floating">
            
              <input
                type="email"
                id="email"
                class="form-control"
                ngModel
                name="email"
                placeholder="Email"
                required
                email
              />

            <label for="email text-center">Please enter your email below</label>
         
            <!--label for="email">Email address</label-->
          </div>

          <button
            type="submit"
            [disabled]="!authForm.valid"
            class="btn btn-primary btn-block btn-lg w-100"
          >
            Submit
          </button>
        </form>
        <p *ngIf="sent">
          If your email exists you will have been sent a link to reset your
          password.
        </p>
        <hr />
        <a [routerLink]="['/login']"><span class="icon-wrap"
          ><fa-icon
            [icon]="['fal', 'arrow-left']"
            [fixedWidth]="true"
          ></fa-icon
        ></span> Go back to Login</a>
        <!--span
          >Don’t have an account?
          <a [routerLink]="['/register']">Sign up now</a> or go back to
          <a [routerLink]="['/login']">Login</a></span
        -->
      </div>
    </div>
  </div>
</div>
