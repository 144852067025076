import { MfaComponent } from './auth/mfa/mfa.component';
import { UploadAvatarComponent } from './auth/upload-avatar/upload-avatar.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { UpdateDetailsComponent } from './auth/update-details/update-details.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MoreComponent } from './more/more.component';
import { RegisterComponent } from './auth/register/register.component';
import { LoginComponent } from './auth/login/login.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard } from './auth/guard/auth.guard';
import { ForgottenPasswordComponent } from './auth/forgotten-password/forgotten-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { OfflineComponent } from './offline/offline.component';
import { StepsIndexComponent } from './activity/steps/steps-index/steps-index.component';
import { FitIndexComponent } from './activity/fit/fit-index/fit-index.component';
import { FitLevelComponent } from './activity/fit/fit-level/fit-level.component';
import { MoveIndexComponent } from './activity/move/move-index/move-index.component';
import { MoveLevelComponent } from './activity/move/move-level/move-level.component';
import { MoveExerciseComponent } from './activity/move/move-exercise/move-exercise.component';
import { BreatheIndexComponent } from './activity/breathe/breathe-index/breathe-index.component';
import { ActivityIndexComponent } from './activity/activity-index/activity-index.component';
import { ProgrammeIndexComponent } from './programme/programme-index/programme-index.component';
import { TaskListComponent } from './programme/task-list/task-list.component';
import { ProgrammeProgressComponent } from './programme/programme-progress/programme-progress.component';
import { SocialIndexComponent } from './social/social-index/social-index.component';
import { ExpertComponent } from './chat/expert/expert.component';
import { SupportComponent } from './chat/support/support.component';
import { ForumComponent } from './chat/forum/forum.component';
import { ForumCategoryComponent } from './chat/forum/forum-category/forum-category.component';
import { ForumTopicComponent } from './chat/forum/forum-topic/forum-topic.component';
import { ForumTopicAddComponent } from './chat/forum/forum-topic-add/forum-topic-add.component';
import { ModulesIndexComponent } from './modules/modules-index/modules-index.component';
import { StepsTargetComponent } from './activity/steps/steps-target/steps-target.component';
import { FitAddWorkoutComponent } from './activity/fit/fit-add-workout/fit-add-workout.component';
import { MoveAddWorkoutComponent } from './activity/move/move-add-workout/move-add-workout.component';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { MoveQuickAddWorkoutComponent } from './activity/move/move-quick-add-workout/move-quick-add-workout.component';
import { BreatheAddEntryComponent } from './activity/breathe/breathe-add-entry/breathe-add-entry.component';
import { EatWellIndexComponent } from './modules/eat-well/eat-well-index/eat-well-index.component';
import { EatWellAddEntryComponent } from './modules/eat-well/eat-well-add-entry/eat-well-add-entry.component';
import { IQuitIndexComponent } from './modules/i-quit/i-quit-index/i-quit-index.component';
import { IQuitAddEntryComponent } from './modules/i-quit/i-quit-add-entry/i-quit-add-entry.component';
import { DrinkingGuideIndexComponent } from './modules/drinking-guide/drinking-guide-index/drinking-guide-index.component';
import { DrinkingGuideAddEntryComponent } from './modules/drinking-guide/drinking-guide-add-entry/drinking-guide-add-entry.component';
import { FeelWellIndexComponent } from './modules/feel-well/feel-well-index/feel-well-index.component';
import { FeelWellAddEntryComponent } from './modules/feel-well/feel-well-add-entry/feel-well-add-entry.component';
import { SleepWellIndexComponent } from './modules/sleep-well/sleep-well-index/sleep-well-index.component';
import { SleepWellAddEntryComponent } from './modules/sleep-well/sleep-well-add-entry/sleep-well-add-entry.component';
import { EatWellTrackerComponent } from './modules/eat-well/eat-well-tracker/eat-well-tracker.component';
import { EatWellEditEntryComponent } from './modules/eat-well/eat-well-edit-entry/eat-well-edit-entry.component';
import { ResourceIndexComponent } from './resources/resource-index/resource-index.component';
import { ResourcePageComponent } from './resources/resource-page/resource-page.component';
import { TrackerComponent } from './activity/tracker/tracker.component';
import { DrinkingGuideTrackerComponent } from './modules/drinking-guide/drinking-guide-tracker/drinking-guide-tracker.component';
import { DrinkingGuideEditEntryComponent } from './modules/drinking-guide/drinking-guide-edit-entry/drinking-guide-edit-entry.component';
import { IQuitTrackerComponent } from './modules/i-quit/i-quit-tracker/i-quit-tracker.component';
import { IQuitEditEntryComponent } from './modules/i-quit/i-quit-edit-entry/i-quit-edit-entry.component';
import { FeelWellTrackerComponent } from './modules/feel-well/feel-well-tracker/feel-well-tracker.component';
import { FeelWellEditEntryComponent } from './modules/feel-well/feel-well-edit-entry/feel-well-edit-entry.component';
import { SleepWellTrackerComponent } from './modules/sleep-well/sleep-well-tracker/sleep-well-tracker.component';
import { SleepWellEditEntryComponent } from './modules/sleep-well/sleep-well-edit-entry/sleep-well-edit-entry.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    data: { hideSiebar: false },
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Dashboard',
      hideSiebar: false,
      pageTitle: 'Dashboard',
      home: true,
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      pageTitle: 'Login',
      hideNavbar: true,
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      fluidContainer: true,
    },
  },
  {
    path: 'mfa',
    component: MfaComponent,
    data: {
      pageTitle: 'Authenticator',
      hideNavbar: true,
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      fluidContainer: true,
    },
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      subTitle: 'Register',
    },
  },
  {
    path: 'forgotten-password',
    component: ForgottenPasswordComponent,
    data: {
      pageTitle: 'Forgotten Password',
      hideNavbar: true,
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      fluidContainer: true,
    },
  },
  {
    path: 'reset-password/:id/:hash',
    component: ResetPasswordComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      fluidContainer: true,
      pageTitle: 'Reset Password',
    },
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      noContainer: true,
      bgDark: false,
      pageTitle: 'Change Password',
    },
  },
  {
    path: 'update-details',
    component: UpdateDetailsComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, pageTitle: 'Account' },
  },
  {
    path: 'upload-avatar',
    component: UploadAvatarComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, pageTitle: 'Account' },
  },
  {
    path: 'welcome',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 1,
      sectionId: 6,
      pageId: 7,
      hideSiebar: true,
      animation: 'Welcome',
      pageTitle: 'Prep Steps',
    },
  },
  {
    path: 'welcome/:pageId',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 1,
      sectionId: 6,
      hideSiebar: true,
      animation: 'Welcome',
      pageTitle: 'Prep Steps',
    },
  },

  {
    path: 'activity',
    component: ActivityIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ActivityIndex',
      pageTitle: 'Move Well',
    },
  },

  {
    path: 'activity/tracker',
    component: TrackerComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ActivityTracker',
      pageTitle: 'Move Well',
    },
  },
  {
    path: 'activity/resources/:pageId',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 1,
      sectionId: 1,
      hideSiebar: false,
      animation: 'ActivityIndex',
      pageTitle: 'Move Well',
    },
  },

  {
    path: 'activity/steps',
    component: StepsIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'StepsIndex',
      pageTitle: 'Prep Steps',
    },
  },

  {
    path: 'activity/steps/target',
    component: StepsTargetComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'StepsTarget',
      pageTitle: 'Prep Steps',
    },
  },
  {
    path: 'activity/steps/resources',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 1,
      sectionId: 2,
      pageId: 3,
      hideSiebar: false,
      animation: 'StepsTarget',
      pageTitle: 'Prep Steps',
    },
  },
  {
    path: 'activity/steps/resources/:pageId',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 1,
      sectionId: 2,
      hideSiebar: false,
      animation: 'StepsTarget',
      pageTitle: 'Prep Steps',
    },
  },
  {
    path: 'activity/fit',
    component: FitIndexComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, animation: 'FitIndex', pageTitle: 'Prep Fit' },
  },

  {
    path: 'activity/fit/resources',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 1,
      sectionId: 3,
      pageId: 4,
      hideSiebar: false,
      animation: 'Fit',
      pageTitle: 'Prep Fit',
    },
  },
  {
    path: 'activity/fit/resources/:pageId',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 1,
      sectionId: 3,
      hideSiebar: false,
      animation: 'Fit',
      pageTitle: 'Prep Fit',
    },
  },
  {
    path: 'activity/fit/level/:level',
    component: FitLevelComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, animation: 'FitLevel', pageTitle: 'Prep Fit' },
  },
  {
    path: 'activity/fit/add-entry',
    component: FitAddWorkoutComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'FitAddWorkout',
      pageTitle: 'Prep Fit',
    },
  },

  {
    path: 'activity/move',
    component: MoveIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'MoveIndex',
      pageTitle: 'Prep Strong',
    },
  },

  {
    path: 'activity/move/resources',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 1,
      sectionId: 4,
      pageId: 5,
      hideSiebar: false,
      animation: 'Fit',
      pageTitle: 'Prep Strong',
    },
  },

  {
    path: 'activity/move/resources/:pageId',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 1,
      sectionId: 4,
      hideSiebar: false,
      animation: 'Fit',
      pageTitle: 'Prep Strong',
    },
  },
  {
    path: 'activity/move/level/:level',
    component: MoveLevelComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'MoveLevel',
      pageTitle: 'Prep Strong',
    },
  },

  {
    path: 'activity/move/exercise/:level/:exerciseId',
    component: MoveExerciseComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'MoveExercise',
      pageTitle: 'Prep Strong',
    },
  },

  {
    path: 'activity/move/add-entry',
    component: MoveAddWorkoutComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, animation: 'MoveAdd', pageTitle: 'Prep Strong' },
    canDeactivate: [PendingChangesGuard],
  },

  {
    path: 'activity/move/quick-add-entry',
    component: MoveQuickAddWorkoutComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'MoveAddQuick',
      pageTitle: 'Prep Strong',
    },
    canDeactivate: [PendingChangesGuard],
  },

  {
    path: 'activity/breathe',
    component: BreatheIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'BreatheIndex',
      pageTitle: 'Breathe Well',
    },
  },

  {
    path: 'activity/breathe/resources',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 1,
      sectionId: 5,
      pageId: 6,
      hideSiebar: false,
      animation: 'Fit',
      pageTitle: 'Breathe Well',
    },
  },

  {
    path: 'activity/breathe/resources/:pageId',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 1,
      sectionId: 5,
      hideSiebar: false,
      animation: 'breathe',
      pageTitle: 'Breathe Well',
    },
  },
  {
    path: 'activity/breathe/add-entry',
    component: BreatheAddEntryComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'BreatheAdd',
      pageTitle: 'Breathe Well',
    },
  },

  {
    path: 'programme',
    component: ProgrammeIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ProgrammeIndex',
      pageTitle: 'Programme',
    },
  },

  {
    path: 'programme/task-list',
    component: TaskListComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, animation: 'TaskList', pageTitle: 'Programme' },
  },

  {
    path: 'programme/progress',
    component: ProgrammeProgressComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ProgrammeProgress',
      pageTitle: 'Programme',
    },
  },

  {
    path: 'social',
    component: SocialIndexComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, animation: 'SocialIndex', pageTitle: 'Social' },
  },

  {
    path: 'social/healthcare-professional',
    component: ExpertComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'socialHCP',
      pageTitle: 'Social',
      subTitle: 'Ask a Healthcare Professional',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'social/support',
    component: SupportComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'socialSupport',
      pageTitle: 'Social',
      subTitle: 'Technical Support ',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'social/forum',
    component: ForumComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'socialForum',
      pageTitle: 'Social',
      subTitle: 'Forum',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'social/forum/category/:id',
    component: ForumCategoryComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'socialForumCategory',
      pageTitle: 'Social',
      subTitle: 'Forum',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'social/forum/topic/:id',
    component: ForumTopicComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'socialForumTopic',
      pageTitle: 'Social',
      subTitle: 'Forum',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'social/forum/topic-add/:id',
    component: ForumTopicAddComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'socialForumTopicAdd',
      pageTitle: 'Social',
      subTitle: 'Forum',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'modules',
    component: ModulesIndexComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, animation: 'Modules', pageTitle: 'Modules' },
  },

  {
    path: 'modules/eat-well',
    component: EatWellIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesEatWell',
      pageTitle: 'Eat Well',
    },
  },

  {
    path: 'modules/eat-well/tracker',
    component: EatWellTrackerComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesEatWellTracker',
      pageTitle: 'Eat Well',
    },
  },
  {
    path: 'modules/eat-well/add-entry',
    component: EatWellAddEntryComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesEatWellAdd',
      pageTitle: 'Eat Well',
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'modules/eat-well/edit-entry/:entryId',
    component: EatWellEditEntryComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesEatWellEdit',
      pageTitle: 'Eat Well',
    },
    canDeactivate: [PendingChangesGuard],
  },


  {
    path: 'modules/eat-well/resources',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 2,
      sectionId: 8,
      pageId: 18,
      hideSiebar: false,
      animation: 'ModulesEatWellAdd',
      pageTitle: 'Eat Well',
    },
  },

  {
    path: 'modules/eat-well/resources/:pageId',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 2,
      sectionId: 8,
      hideSiebar: false,
      animation: 'ModulesEatWellAdd',
      pageTitle: 'Eat Well',
    },
  },


  {
    path: 'modules/i-quit',
    component: IQuitIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesIQuitIndex',
      pageTitle: 'I-Quit',
    },
  },

  {
    path: 'modules/i-quit/tracker',
    component: IQuitTrackerComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesIQuitTracker',
      pageTitle: 'I-Quit',
    },
  },
  {
    path: 'modules/i-quit/add-entry',
    component: IQuitAddEntryComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesIQuitAdd',
      pageTitle: 'I-Quit',
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'modules/i-quit/edit-entry/:entryId',
    component: IQuitEditEntryComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesIQuitEdit',
      pageTitle: 'I-Quit',
    },
    canDeactivate: [PendingChangesGuard],
  },

  {
    path: 'modules/i-quit/resources',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 2,
      sectionId: 7,
      pageId: 14,
      hideSiebar: false,
      animation: 'I-Quit',
      pageTitle: 'I-Quit',
    },
  },

  {
    path: 'modules/i-quit/resources/:pageId',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 2,
      sectionId: 7,
      hideSiebar: false,
      animation: 'I-Quit',
      pageTitle: 'I-Quit',
    },
  },

  {
    path: 'modules/drinking-guide',
    component: DrinkingGuideIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesDrinkingGuideIndex',
      pageTitle: 'Drink Guide',
    },
  },

  {
    path: 'modules/drinking-guide/tracker',
    component: DrinkingGuideTrackerComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesDrinkingGuideTracker',
      pageTitle: 'Drink Guide',
    },
  },

  {
    path: 'modules/drinking-guide/add-entry',
    component: DrinkingGuideAddEntryComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesDrinkingGuideAdd',
      pageTitle: 'Drink Guide',
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'modules/drinking-guide/edit-entry/:entryId',
    component: DrinkingGuideEditEntryComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesDrinkingGuideEdit',
      pageTitle: 'Drink Guide',
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'modules/drinking-guide/resources',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 2,
      sectionId: 11,
      pageId: 31,
      hideSiebar: false,
      animation: 'ModulesDrinkingGuideAdd',
      pageTitle: 'Drink Guide',
    },
  },

  {
    path: 'modules/drinking-guide/resources/:pageId',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 2,
      sectionId: 11,
      hideSiebar: false,
      animation: 'ModulesDrinkingGuideAdd',
      pageTitle: 'Drink Guide',
    },
  },

  {
    path: 'modules/feel-well',
    component: FeelWellIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesFeelWellIndex',
      pageTitle: 'Feel Well',
    },
  },
  {
    path: 'modules/feel-well/tracker',
    component: FeelWellTrackerComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesFeelWellTracker',
      pageTitle: 'Feel Well',
    },
  },

  {
    path: 'modules/feel-well/add-entry',
    component: FeelWellAddEntryComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesFeelWellAdd',
      pageTitle: 'Feel Well',
    },
    canDeactivate: [PendingChangesGuard],
  },

  {
    path: 'modules/feel-well/edit-entry/:entryId',
    component: FeelWellEditEntryComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesFeelWellEdit',
      pageTitle: 'Feel Well',
    },
    canDeactivate: [PendingChangesGuard],
  },

  {
    path: 'modules/feel-well/resources',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 2,
      sectionId: 10,
      pageId: 26,
      hideSiebar: false,
      animation: 'ModulesFeelWellAdd',
      pageTitle: 'Feel Well',
    },
  },

  {
    path: 'modules/feel-well/resources/:pageId',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 2,
      sectionId: 10,
      hideSiebar: false,
      animation: 'ModulesFeelWellAdd',
      pageTitle: 'Feel Well',
    },
  },

  {
    path: 'modules/sleep-well',
    component: SleepWellIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesSleepWellIndex',
      pageTitle: 'Sleep Well',
    },
  },

  {
    path: 'modules/sleep-well/tracker',
    component: SleepWellTrackerComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesSleepWellTracker',
      pageTitle: 'Sleep Well',
    },
  },

  {
    path: 'modules/sleep-well/add-entry',
    component: SleepWellAddEntryComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesSleepWellAdd',
      pageTitle: 'Sleep Well',
    },
    canDeactivate: [PendingChangesGuard],
  },

  {
    path: 'modules/sleep-well/edit-entry/:entryId',
    component: SleepWellEditEntryComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ModulesSleepWellEdit',
      pageTitle: 'Sleep Well',
    },
    canDeactivate: [PendingChangesGuard],
  },

  {
    path: 'modules/sleep-well/resources',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 2,
      sectionId: 9,
      pageId: 22,
      hideSiebar: false,
      animation: 'ModulesSleepWellAdd',
      pageTitle: 'Sleep Well',
    },
  },

  {
    path: 'modules/sleep-well/resources/:pageId',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      areaId: 2,
      sectionId: 9,
      hideSiebar: false,
      animation: 'ModulesSleepWellAdd',
      pageTitle: 'Sleep Well',
    },
  },

  {
    path: 'more',
    component: MoreComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, animation: 'More', pageTitle: 'More' },
  },
  /* {
    path: 'more/:area',
    component: MorePageComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, animation: 'MorePage', pageTitle: 'More' },
  },*/

  {
    path: 'offline',
    component: OfflineComponent,
    data: {
      pageTitle: 'Site Offline',
      hideNavbar: true,
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      fluidContainer: true,
    },
  },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/page-not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
