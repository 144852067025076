import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { UserSmoking } from 'src/app/models/user-smoking.model';
import { UserSmokingService } from 'src/app/services/user-smoking.service';

@Component({
  selector: 'app-i-quit-edit-entry',
  templateUrl: './i-quit-edit-entry.component.html',
  styleUrls: ['./i-quit-edit-entry.component.scss'],
})
export class IQuitEditEntryComponent implements OnInit {
  isLoading = false;
  maxDate = new Date();
  userData: any;
  submitted = false;
  allEntries: any;
  noSmoking = false;
  nicotineReplacement = false;
  smokingCessation = false;
  totalCigarettes = 0;
  entryId: any;
  userSmoking: UserSmoking | undefined;
  entryDate = new Date();

  @ViewChild('entryForm', { static: false }) entryForm?: NgForm;
  constructor(
    private userSmokingService: UserSmokingService,
    private router: Router,
    private userService: UserService,
    private el: ElementRef,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
    });

    this.route.params.subscribe((params) => {
      this.entryId = params.entryId;
      this.isLoading = true;
      forkJoin({
        userSmoking: this.userSmokingService.fetch(this.entryId),
      }).subscribe(
        (responseData) => {
          this.isLoading = false;
          this.userSmoking = responseData.userSmoking;
          this.entryDate = moment(this.userSmoking.date_recorded).toDate();
          if (this.userSmoking.cigarettes > 0) {
            this.noSmoking = false;
          } else {
            this.noSmoking = true;
          }
          if (this.userSmoking.nicotine_replacement) {
            this.nicotineReplacement = true;
          }
          if (this.userSmoking.cessation) {
            this.smokingCessation = true;
          }
          this.totalCigarettes = this.userSmoking.cigarettes;
        },
        (error) => {
          // this.errors.push(error.message);
        }
      );
    });
  }

  onSubmitForm() {
    this.userSmokingService
      .update(
        this.entryId,
        moment(this.entryForm?.value.date).format('YYYY-MM-DD'),
        +this.totalCigarettes,
        +this.nicotineReplacement,
        +this.smokingCessation
      )
      .subscribe((responseData) => {
        window.scroll(0, 0);
        this.submitted = true;
        /*
        // refresh it
        Swal.fire({
          icon: 'success',
          title: 'Workout Added',
          text: 'Your workout was added',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/activity', 'fit', 'level', this.userData.fit_level]));*/
      });
  }

  // validattion stuff
  public scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector('form .ng-invalid');

    if (firstInvalidControl) {
      firstInvalidControl.focus();
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  public getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<any> | any {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if (this.submitted) {
      return true;
    } else if (this.totalCigarettes == 0 && this.noSmoking == false) {
      return true;
    }
  }

  onPlusCigarettes() {
    if (this.totalCigarettes == 0) {
      this.noSmoking = false;
    }
    this.totalCigarettes++;
  }

  onMinusCigarettes() {
    if (this.totalCigarettes > 0) {
      this.totalCigarettes--;
    }
  }

  onToggleNoSmoking() {
    this.noSmoking = !this.noSmoking;
    if (this.noSmoking) {
      this.totalCigarettes = 0;
    }
  }
}
