import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserStrengthWorkout } from '../models/user-strength-workout.model';
import { DateHelperService } from './date-helper.service';

@Injectable({
  providedIn: 'root',
})
export class UserStrengthWorkoutService {
  error = new Subject<string>();
  slug = '/user_strength_workouts';
  resourceName = 'user_strength_workouts';
  weeklyTarget = {
    threshold: 1,
    days: 3,
  };
  allWorkouts?: Observable<UserStrengthWorkout[]> | null = null;
  workoutsBetween?: any;
  allExercises?: any;

  constructor(
    private http: HttpClient,
    private dateHelperService: DateHelperService
  ) {}

  fetchBetween(
    startDate: Date,
    endDate: Date
  ): Observable<UserStrengthWorkout[]> {
    if (!this.workoutsBetween) {
      this.workoutsBetween = {};
    }

    if (
      !this.workoutsBetween[
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
          '_' +
          this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      ]
    ) {
      let searchParams = new HttpParams();

      searchParams = searchParams.append(
        'startDate',
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD')
      );
      searchParams = searchParams.append(
        'endDate',
        this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      );
      this.workoutsBetween[
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
          '_' +
          this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      ] = this.http
        .get<any>(environment.apiUrl + this.slug, {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserStrengthWorkout[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.workoutsBetween[
      this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
        '_' +
        this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
    ];
  }
  fetchAll(): Observable<UserStrengthWorkout[]> {
    if (!this.allWorkouts) {
      this.allWorkouts = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserStrengthWorkout[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                // parse the exercise data
                if (item.exercises) {
                  item.exercises = JSON.parse(item.exercises);
                }
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allWorkouts;
  }

  clearCache() {
    this.allWorkouts = null;
    this.workoutsBetween = null;
    this.allExercises = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const item = new UserStrengthWorkout(
            +responseData.user_strength_workout_id,
            +responseData.user_id,
            responseData.workout_date,
            +responseData.level,
            +responseData.weight,
            responseData.set1_reps,
            responseData.set2_reps,
            responseData.set3_reps,
            +responseData.difficulty,
            responseData.exercises,
            responseData.created,
            responseData.modified
          );
          return item;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    workout_date: string,
    level:number,
    session:string,
    exerciseData: any,
    set1_reps:number,
    set2_reps:number,
    set3_reps:number,
  ) {
    this.clearCache();
    const payload = {
      workout_date: moment(workout_date).format('YYYY-MM-DD'),
      level,
      session,
      exercise_data:exerciseData,
      set1_reps,
      set2_reps,
      set3_reps,
    };
    return this.http.post<UserStrengthWorkout>(
      environment.apiUrl + this.slug,
      payload,
      {
        observe: 'response',
      }
    );
  }

  update(
    id: number,
    workout_date: string,
    weight: number,
    set1_reps: string,
    set2_reps: string,
    set3_reps: string,
    difficulty: number,
    exercises?: any
  ) {
    this.clearCache();
    const payload = {
      workout_date: moment(workout_date).format('YYYY-MM-DD'),
      weight,
      set1_reps,
      set2_reps,
      set3_reps,
      difficulty,
      exercises,
    };
    return this.http.patch<UserStrengthWorkout>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }

  generateWeekArray(currentWeek: any, data: any) {
    const weekArray: any = [];
    currentWeek.forEach((day: any) => {
      let currentDay = {
        date: moment(day).format('YYYY-MM-DD'),
        set1_reps: 0,
        set2_reps: 0,
        set3_reps: 0,
        total_reps: 0,
        level:0,
      };
      data.forEach((item: UserStrengthWorkout) => {
        if (item.workout_date === currentDay.date) {
          currentDay.set1_reps += +item.set1_reps;
          currentDay.set2_reps += +item.set2_reps;
          currentDay.set3_reps += +item.set3_reps;

          currentDay.total_reps +=
            +item.set1_reps + +item.set2_reps + +item.set3_reps;

            currentDay.level = item.level;
        }
      });
      weekArray.push(currentDay);
    });
    return weekArray;
  }

  generateLast4WeeksArray(last4Weeks: any, data: any) {
    const weekArray: any = {
      1: { days: [], daysHit: 0 },
      2: { days: [], daysHit: 0 },
      3: { days: [], daysHit: 0 },
      4: { days: [], daysHit: 0 },
      total: { daysActive: 0 },
    };
    let weekcount = 1;
    let totalDaysActive = 0;
    last4Weeks.forEach((day: any, dayIndex: number) => {
      if (dayIndex > 6 && dayIndex < 14) {
        weekcount = 2;
      }
      if (dayIndex > 13 && dayIndex < 21) {
        weekcount = 3;
      }
      if (dayIndex > 20) {
        weekcount = 4;
      }
      let currentDay = {
        date: this.dateHelperService.formatDate(day, 'YYYY-MM-DD'),
        set1_reps: '',
        set2_reps: '',
        set3_reps: '',
      };
      data.forEach((item: UserStrengthWorkout) => {
        if (item.workout_date === currentDay.date) {
          currentDay.set1_reps = item.set1_reps;
        }
      });
      if (
        currentDay.set1_reps != '' ||
        currentDay.set2_reps != '' ||
        currentDay.set3_reps != ''
      ) {
        totalDaysActive++;
      }
      if (
        currentDay.set1_reps != '' ||
        currentDay.set2_reps != '' ||
        currentDay.set3_reps != ''
      ) {
        weekArray[weekcount].daysHit++;
      }
      weekArray[weekcount].days.push(currentDay);
    });
    weekArray.total.daysActive = totalDaysActive;
    return weekArray;
  }

  getDaysHit(weekArray: any) {
    let daysHit = 0;
    weekArray.forEach((day: any) => {
      if (day.set1_reps != '' || day.set2_reps != '' || day.set3_reps != '') {
        daysHit++;
      }
    });
    return daysHit;
  }

  onlyUnique(value: any, index: number, self: any) {
    return self.indexOf(value) === index;
  }

  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  fetchExercises(): Observable<any[]> {
    if (!this.allExercises) {
      this.allExercises = this.http
        .get<any>(environment.apiUrl + '/strength_exercises', {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData['_embedded']['strength_exercises'];
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allExercises;
  }

  getExercisesForSession(level: number, session: string, exercises: any) {
    const sessionATypes = [
      'Squat',
      'Deadlift',
      'Upper body pressing',
      'Upper body pulling',
      'Chest opening',
      'Core extension',
      'Core flexion',
    ];

    const sessionBTypes = [
      'Squat',
      'Lunge',
      'Upper body pressing',
      'Upper body pulling',
      'Overhead pressing',
      ' Core lateral flexion',
      'Isometric core',
    ];

    let selectedExercises: any = [];

    exercises.forEach((exercise: any) => {
      if (
        exercise.level == level &&
        ((session == 'A' && sessionATypes.indexOf(exercise.category) !== -1) ||
          (session == 'B' && sessionBTypes.indexOf(exercise.category) !== -1))
      ) {
        selectedExercises.push(exercise);
      }
    });
    return selectedExercises;
  }

  generatePastSevenDaysArray(data: any) {
    const weekArray: any = [];
    for (let i = 0; i < 7; i++) {
      let thisDay = moment().subtract(i, 'days').format('YYYY-MM-DD');

      let currentDay = {
        date: thisDay,
        set1_reps: 0,
        set2_reps: 0,
        set3_reps: 0,
        total_reps: 0,
      };
      data.forEach((item: UserStrengthWorkout) => {
        if (item.workout_date === currentDay.date) {
          if (item.set1_reps || item.set2_reps || item.set3_reps ) {
            currentDay.set1_reps += +item.set1_reps;
            currentDay.set2_reps += +item.set2_reps;
            currentDay.set3_reps += +item.set3_reps;
            currentDay.total_reps += +currentDay.set1_reps  + +currentDay.set2_reps  + +currentDay.set3_reps ;
           
          }
        }
      });
      weekArray.push(currentDay);
    }

    return weekArray;
  }


  updateLevel(level:number){
    const payload = {
      level: level,
      area: 'strong'
    };
    return this.http.post<any>(
      environment.apiUrl + '/user_move_well_levels',
      payload,
      {
        observe: 'response',
      }
    );
  }
}
