<app-header-bar [title]="'Please select an option...'"></app-header-bar>
<div class="container  content-wrap">
  <ul class="tracker-list tracker-list-no-bt">
    <li>
      <a [routerLink]="['/more', 'app']">
        <span class="icon-wrap tracker-icon">
          <fa-icon [icon]="['fal', 'mobile']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Install Web App
            <span class="icon-wrap float-end"
              ><fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon></span
          ></span>

          <span class="details"
            >How to as an app on your device.</span
          >
        </span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/more', 'about']">
        <span class="icon-wrap tracker-icon">
          <fa-icon
            [icon]="['fal', 'info-circle']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >About
            <span class="icon-wrap float-end"
              ><fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon></span
          ></span>

          <span class="details"
            >Information about the Programme</span
          >
        </span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/more', 'terms']">
        <span class="icon-wrap tracker-icon">
          <fa-icon [icon]="['fal', 'balance-scale']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Terms and Conditions
            <span class="icon-wrap float-end"
              ><fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon></span
          ></span>

          <span class="details"
            >Our Terms and Conditions.</span
          >
        </span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/more', 'privacy']">
        <span class="icon-wrap tracker-icon">
          <fa-icon [icon]="['fal', 'fingerprint']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Privacy Policy
            <span class="icon-wrap float-end"
              ><fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon></span
          ></span>

          <span class="details"
            >Our Privacy Policy.</span
          >
        </span>
      </a>
    </li>
  </ul>
</div>
