<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <h2>Add Entry</h2>
</nav>

<div class="container container-inner">
  <div class="row">
    <div class="col-12">
      <form
        name="entryForm"
        #entryForm="ngForm"
        class="standard-form mt-4"
        [hidden]="submitted"
      >
        <div class="row">
          <div class="col-12">
            <div class="form-group text-center">
              <label for="date">Date</label>
              <div class="form-group-inner">
                <input
                  type="text"
                  id="date"
                  class="form-control form-control-stub"
                  [ngModel]="entryDate"
                  #date="ngModel"
                  name="date"
                  placeholder="DD/MM/YYYY"
                  required
                  date
                  bsDatepicker
                  autocomplete="off"
                  [maxDate]="maxDate"
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY',
                    containerClass: 'theme-prepwell',
                    showWeekNumbers: false
                  }"
                />
                <div
                  class="alert alert-danger"
                  *ngIf="!date.valid && date.touched"
                >
                  Please enter a date above
                </div>
              </div>
            </div>

            <div class="form-group text-center mb-0">
              <label class="text-center"
                >Did you have any alcoholic drinks today?</label
              >
            </div>
            <div class="form-group-container mb-3">
              <p class="instructions mb-0"></p>
              <div class="text-center">
                <button
                  class="btn btn-white me-2"
                  [ngClass]="{ active: iDrank == true }"
                  (click)="iDrank = true"
                >
                  Yes
                </button>
                <button
                  class="btn btn-white ms-2"
                  [ngClass]="{ active: iDrank == false }"
                  (click)="iDrank = false;resetTotals();"
                >
                  No
                </button>
              </div>
            </div>

            <div [hidden]="iDrank !== true">
              <div class="form-group text-center mb-0">
                <label class="text-center">HOW MANY ALCOHOLIC DRINKS DID YOU HAVE?</label>
              </div>

              <div class="row">

                <!-- start drink types -->

                <div class="col-6 col-md-3 mb-3" *ngFor="let drink of drinkTypes">
                    <div class="form-group-container">
                      <p class="instructions mb-0">
                       {{drink.label}}
                      </p>
                      <div class="text-center">
                        <div class="icon-wrap text-dark-green icon-form-indicator">
                          <fa-icon [icon]="$any([drink.iconWeight, drink.icon])" [fixedWidth]="true"></fa-icon>
                          <span class="sub-icon sub-icon-{{drink.subIconPosition}}" *ngIf="drink.subIcon && drink.subIconWeight"><fa-icon [icon]="[drink.subIconWeight, drink.subIcon]" [fixedWidth]="true"></fa-icon></span>
                        </div>
                      </div>
                      <div class="up-down-scale">
                        <button class="btn btn-sm btn-white" (click)="onMinusDrink(drink)" [disabled]="drinkTotals[drink.id] == 0"> <fa-icon [icon]="['far', 'circle-minus']"></fa-icon></button>
                        <div class="up-down-total">{{drinkTotals[drink.id]}} <div class="up-down-total-subtext"><span *ngIf="drinkTotals[drink.id] != 0">({{drinkTotals[drink.id]*drink.units}} units)</span><span *ngIf="drinkTotals[drink.id] == 0">&nbsp;</span></div></div>
                        <button class="btn btn-sm btn-white" (click)="onPlusDrink(drink)"> <fa-icon [icon]="['far', 'circle-plus']"></fa-icon></button>
                      </div>
                    </div>
                  </div>

                <!-- end drink types -->


              </div>


              <div class="form-group-container d-flex justify-content-center">
                <h3 class="label mx-1">Total:</h3>
                <h3 class="label mx-1">{{totalDrinks}} Drinks</h3>
                <h3 class="label mx-1">{{totalUnits}} Units</h3>
            </div>
            </div>
            <div class="form-group text-center">
              <label>&nbsp;</label>
              <div>
                <button
                  (click)="onSubmitForm()"
                  class="btn btn-primary w-100"
                  [disabled]="date.invalid"
                >
                  Submit Entry
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div [hidden]="!submitted">
        <div class="submit-confirm">
          <h3>Excellent!</h3>
          <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'thumbs-up']"></fa-icon
          ></span>
          <h4>Entry Details</h4>
          <div class="entry-details">
            <div>
              <div class="label">Date</div>
              <div class="data">
                {{ date.value | customDate : "MMMM Do YYYY, h:mm:ss a" }}
              </div>
            </div>
            <div>
              <div class="label">Drinks</div>
              <div class="data">{{ totalDrinks }}</div>
            </div>
            <div>
              <div class="label">Units</div>
              <div class="data">{{ totalUnits }}</div>
            </div>
          </div>
          <button
            class="btn btn-primary w-100 mt-4"
            [routerLink]="['/modules', 'drinking-guide', 'tracker']"
          >
            Back to Drinking Guide
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
