<div class="container-inner">
  <div class="row">
    <div class="col-12 col-md-12">
      <div class="card mt-3 bg-primary text-dark relative">
        <div class="row g-0">
          <div
            class="col-5"
            style="
              border-radius: 0.25rem 0 0 0.25rem;
              background-image: url('/assets/img/placeholder-i-quit.jpg');
              background-size: cover;
              background-position: center;
            "
          ></div>
          <div class="col-7">
            <div class="card-body text-center">
              <div class="card-masthead">
                <h2>
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['far', 'smoking-ban']"></fa-icon
                  ></span>
                  I-Quit
                </h2>
              </div>

              <div class="module-score-container mb-2">
                <div class="module-score">
                  <div class="module-score-title">Smoked</div>
                  <div class="module-score-stat" *ngIf="smokingTotals && smokingChanges">
                    {{ smokingTotals.totalCigarettes }}
                    <div class="module-score-stat-change">
                      <span class="icon-wrap text-danger" *ngIf="smokingChanges.totalCigarettes>0"
                        ><fa-icon [icon]="['fas', 'caret-up']"></fa-icon></span
                      ><span class="icon-wrap text-success" *ngIf="smokingChanges.totalCigarettes<0"
                      ><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span
                    >
                    <span class="change" *ngIf="smokingChanges.totalCigarettes>0">{{smokingChanges.totalCigarettes}}</span>
                    <span class="change" *ngIf="smokingChanges.totalCigarettes<0">{{smokingChanges.totalCigarettes*-1}}</span>
                    <span class="change" *ngIf="smokingChanges.totalCigarettes==0">-</span>
                    </div>
                  </div>
                  <div class="module-score-subtitle">Cigarettes</div>
                </div>
                <div class="module-score">
                  <div class="module-score-title">Smoke Free Days</div>
                  <div class="module-score-stat" *ngIf="smokingTotals">
                    {{ smokingTotals.smokeFreeDays }}
                    <div class="module-score-stat-change">
                      <span class="icon-wrap text-success" *ngIf="smokingChanges.smokeFreeDays>0"
                        ><fa-icon [icon]="['fas', 'caret-up']"></fa-icon></span
                      ><span class="icon-wrap text-danger" *ngIf="smokingChanges.smokeFreeDays<0"
                      ><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span
                    >
                    <span class="change" *ngIf="smokingChanges.smokeFreeDays>0">{{smokingChanges.smokeFreeDays}}</span>
                    <span class="change" *ngIf="smokingChanges.smokeFreeDays<0">{{smokingChanges.smokeFreeDays*-1}}</span>
                    <span class="change" *ngIf="smokingChanges.smokeFreeDays==0">-</span>
                    </div>
                  </div>
                  <div class="module-score-subtitle">Days</div>
                </div>
              </div>

              <p class="card-text-module">Compared to last week</p>

              <div class="card-btns">
                <button
                  class="btn btn-lighten btn-sm"
                  [routerLink]="['/modules', 'i-quit']"
                >
                  Contents
                  <span class="icon-wrap text-white"
                    ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                  ></span>
                </button>
                <button
                  class="btn btn-lighten btn-sm"
                  [routerLink]="['/modules', 'i-quit', 'tracker']"
                >
                  Tracker
                  <span class="icon-wrap text-white"
                    ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-3 bg-primary text-dark relative">
        <div class="row g-0">
          <div
            class="col-5"
            style="
              border-radius: 0.25rem 0 0 0.25rem;
              background-image: url('/assets/img/placeholder-drink-guide.jpg');
              background-size: cover;
              background-position: center;
            "
          ></div>
          <div class="col-7">
            <div class="card-body text-center">
              <div class="card-masthead">
                <h2>
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'martini-glass']"></fa-icon
                  ></span>
                  Drinking Guide
                </h2>
              </div>

              <div class="module-score-container mb-2">
                <div class="module-score">
                  <div class="module-score-title">Alcohol Consumed</div>
                  <div class="module-score-stat" *ngIf="drinkTotals && drinkChanges">
                    {{ drinkTotals.totalUnits }}
                    <div class="module-score-stat-change">
                      <span class="icon-wrap text-danger" *ngIf="drinkChanges.totalUnits>0"
                        ><fa-icon [icon]="['fas', 'caret-up']"></fa-icon></span
                      ><span class="icon-wrap text-success" *ngIf="drinkChanges.totalUnits<0"
                      ><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span
                    >
                    <span class="change" *ngIf="drinkChanges.totalUnits>0">{{drinkChanges.totalUnits}}</span>
                    <span class="change" *ngIf="drinkChanges.totalUnits<0">{{drinkChanges.totalUnits*-1}}</span>
                    <span class="change" *ngIf="drinkChanges.totalUnits==0">-</span>
                    </div>
                  </div>
                  <div class="module-score-subtitle">Units</div>
                </div>
                <div class="module-score">
                  <div class="module-score-title">Alcohol Free Days</div>
                  <div class="module-score-stat" *ngIf="drinkTotals">
                    {{ drinkTotals.drinkFreeDays }}
                    <div class="module-score-stat-change">
                      <span class="icon-wrap text-success" *ngIf="drinkChanges.drinkFreeDays>0"
                        ><fa-icon [icon]="['fas', 'caret-up']"></fa-icon></span
                      ><span class="icon-wrap text-danger" *ngIf="drinkChanges.drinkFreeDays<0"
                      ><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span
                    >
                    <span class="change" *ngIf="drinkChanges.drinkFreeDays>0">{{drinkChanges.drinkFreeDays}}</span>
                    <span class="change" *ngIf="drinkChanges.drinkFreeDays<0">{{drinkChanges.drinkFreeDays*-1}}</span>
                    <span class="change" *ngIf="drinkChanges.drinkFreeDays==0">-</span>
                    </div>
                  </div>
                  <div class="module-score-subtitle">Days</div>
                </div>
              </div>

              <p class="card-text-module">Compared to last week</p>

              <div class="card-btns">
                <button
                  class="btn btn-lighten btn-sm"
                  [routerLink]="['/modules', 'drinking-guide']"
                >
                  Contents
                  <span class="icon-wrap text-white"
                    ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                  ></span>
                </button>
                <button
                  class="btn btn-lighten btn-sm"
                  [routerLink]="['/modules', 'drinking-guide', 'tracker']"
                >
                  Tracker
                  <span class="icon-wrap text-white"
                    ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-3 bg-primary text-dark relative">
        <div class="row g-0">
          <div
            class="col-5"
            style="
              border-radius: 0.25rem 0 0 0.25rem;
              background-image: url('/assets/img/placeholder-feel-well.jpg');
              background-size: cover;
              background-position: center;
            "
          ></div>
          <div class="col-7">
            <div class="card-body text-center">
              <div class="card-masthead">
                <h2>
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'face-smile-relaxed']"></fa-icon
                  ></span>
                  Feel Well
                </h2>
              </div>

              <div class="module-score-container mb-2">
                <div class="module-score">
                  <div class="module-score-title">Average Feel Score</div>
                  <div class="module-score-stat" *ngIf="feelTotal">
                    {{feelTotal.average}}/10
                    <div
                      class="module-score-stat-change module-score-stat-change-inline"
                      *ngIf="feelChange"
                    >
                      <span class="icon-wrap text-success" *ngIf="feelChange>0"
                        ><fa-icon [icon]="['fas', 'caret-up']"></fa-icon></span
                      ><span class="icon-wrap text-danger" *ngIf="feelChange<0"
                        ><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span
                      ><span class="change" *ngIf="feelChange>0">{{feelChange}}</span>
                      <span class="change" *ngIf="feelChange<0">{{feelChange*-1}}</span>
                      <span class="change" *ngIf="feelChange==0">-</span>
                    </div>
                  </div>
                </div>
              </div>

              <p class="card-text-module">Compared to last week</p>

              <div class="card-btns">
                <button
                  class="btn btn-lighten btn-sm"
                  [routerLink]="['/modules', 'feel-well']"
                >
                  Contents
                  <span class="icon-wrap text-white"
                    ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                  ></span>
                </button>
                <button
                  class="btn btn-lighten btn-sm"
                  [routerLink]="['/modules', 'feel-well', 'tracker']"
                >
                  Tracker
                  <span class="icon-wrap text-white"
                    ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-3 bg-primary text-dark relative">
        <div class="row g-0">
          <div
            class="col-5"
            style="
              border-radius: 0.25rem 0 0 0.25rem;
              background-image: url('/assets/img/placeholder-sleep-well.jpg');
              background-size: cover;
              background-position: right;
            "
          ></div>
          <div class="col-7">
            <div class="card-body text-center">
              <div class="card-masthead">
                <h2>
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'face-sleeping']"></fa-icon
                  ></span>
                  Sleep Well
                </h2>
              </div>

              <div class="module-score-container mb-2">
                <div class="module-score">
                  <div class="module-score-title">Average Sleep Score</div>
                  <div class="module-score-stat" *ngIf="sleepTotal">
                    {{sleepTotal.average}}/10
                    <div
                      class="module-score-stat-change module-score-stat-change-inline"
                      *ngIf="sleepChange"
                    >
                      <span class="icon-wrap text-success" *ngIf="sleepChange>0"
                        ><fa-icon [icon]="['fas', 'caret-up']"></fa-icon></span
                      ><span class="icon-wrap text-danger" *ngIf="sleepChange<0"
                        ><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span
                      ><span class="change" *ngIf="sleepChange>0">{{sleepChange}}</span>
                      <span class="change" *ngIf="sleepChange<0">{{sleepChange*-1}}</span>
                      <span class="change" *ngIf="sleepChange==0">-</span>
                    </div>
                  </div>
                </div>
              </div>

              <p class="card-text-module">Compared to last week</p>

              <div class="card-btns">
                <button
                  class="btn btn-lighten btn-sm"
                  [routerLink]="['/modules', 'sleep-well']"
                >
                  Contents
                  <span class="icon-wrap text-white"
                    ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                  ></span>
                </button>
                <button
                  class="btn btn-lighten btn-sm"
                  [routerLink]="['/modules', 'sleep-well']"
                >
                  Tracker
                  <span class="icon-wrap text-white"
                    ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-3 bg-primary text-dark relative">
        <div class="row g-0">
          <div
            class="col-5"
            style="
              border-radius: 0.25rem 0 0 0.25rem;
              background-image: url('/assets/img/placeholder-eat-well.jpg');
              background-size: cover;
              background-position: center;
            "
          ></div>
          <div class="col-7">
            <div class="card-body text-center">
              <div class="card-masthead">
                <h2>
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'cutlery']"></fa-icon
                  ></span>
                  Eat Well
                </h2>
              </div>

              <div class="days-indicator days-indicator-md text-center">
                <div class="day" *ngFor="let day of eatWellWeekData">
                  <div class="day-label">
                    {{ day.date | customDate : "dddd" | slice : 0 : 1 }}
                  </div>
                  <div
                    class="day-status-lg day-status-circle bg-white text-secondary"
                    *ngIf="day.date <= today && day.score > 5"
                  >
                    <div class="day-status-title">{{ day.score }}</div>
                  </div>
                  <div
                    class="day-status-lg day-status-circle bg-lighten text-white"
                    *ngIf="day.date <= today && day.score > 0"
                  >
                    <div class="day-status-title">{{ day.score }}</div>
                  </div>
                  <div
                    class="day-status-lg day-status-circle bg-lighten text-white"
                    *ngIf="day.date <= today && day.score < 1"
                  >
                    <div class="day-status-title">-</div>
                  </div>
                  <div
                    class="day-status-lg day-status-circle bg-lighten text-white"
                    *ngIf="day.date > today"
                  ></div>
                </div>
              </div>

              <p class="card-text-module mt-3">
                Daily scores this week out 5 from the five reported groups
              </p>

              <div class="card-btns">
                <button
                  class="btn btn-lighten btn-sm"
                  [routerLink]="['/modules', 'eat-well']"
                >
                  Contents
                  <span class="icon-wrap text-white"
                    ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                  ></span>
                </button>
                <button
                  class="btn btn-lighten btn-sm"
                  [routerLink]="['/modules', 'eat-well', 'tracker']"
                >
                  Tracker
                  <span class="icon-wrap text-white"
                    ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
