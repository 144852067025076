import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserSleep } from '../models/user-sleep.model';
import { DateHelperService } from './date-helper.service';

@Injectable({
  providedIn: 'root'
})
export class UserSleepsService {
  error = new Subject<string>();
  slug = '/user_sleeps';
  resourceName = 'user_sleeps';
  weeklyTarget = {
    threshold: 1,
    days: 3,
  };
  allWorkouts?: Observable<UserSleep[]> | null = null;
  workoutsBetween?: any;

  constructor(
    private http: HttpClient,
    private dateHelperService: DateHelperService
  ) {}

  fetchBetween(startDate: Date, endDate: Date): Observable<UserSleep[]> {
    if (!this.workoutsBetween) {
      this.workoutsBetween = {};
    }

    if (
      !this.workoutsBetween[
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
          '_' +
          this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      ]
    ) {
      let searchParams = new HttpParams();

      searchParams = searchParams.append(
        'startDate',
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD')
      );
      searchParams = searchParams.append(
        'endDate',
        this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      );
      this.workoutsBetween[
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
          '_' +
          this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      ] = this.http
        .get<any>(environment.apiUrl + this.slug, {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserSleep[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.workoutsBetween[
      this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
        '_' +
        this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
    ];
  }
  fetchAll(): Observable<UserSleep[]> {
    if (!this.allWorkouts) {
      this.allWorkouts = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserSleep[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                // parse the exercise data
                if (item.exercises) {
                  item.exercises = JSON.parse(item.exercises);
                }
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allWorkouts;
  }

  clearCache() {
    this.allWorkouts = null;
    this.workoutsBetween = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const item = new UserSleep(
            +responseData.user_sleep_id,
            +responseData.user_id,
            responseData.date_recorded,
            +responseData.mood,
            +responseData.worry,
            responseData.stress,
            responseData.created,
            responseData.modified
          );
          return item;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    date_recorded: string,
    quality: number,
    getting_sleep: number,
    awakening: number,
  ) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      quality,
      getting_sleep,
      awakening,
    };
    return this.http.post<UserSleep>(
      environment.apiUrl + this.slug,
      payload,
      {
        observe: 'response',
      }
    );
  }

  update(
    id: number,
    date_recorded: string,
    quality: number,
    getting_sleep: number,
    awakening: number,
  ) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      quality,
      getting_sleep,
      awakening,
    };
    return this.http.patch<UserSleep>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }

  generateWeekArray(currentWeek: any, data: any) {
    const weekArray: any = [];
    currentWeek.forEach((day: any) => {
      let currentDay = {
        date: moment(day).format('YYYY-MM-DD'),
        quality: 0,
        getting_sleep: 0,
        awakening: 0,
        hasData: 0,
        score:0,
        entryId:0
      };
      data.forEach((item: UserSleep) => {
        if (item.date_recorded === currentDay.date) {
          currentDay.quality = +item.quality;
          currentDay.getting_sleep = +item.getting_sleep;
          currentDay.awakening = +item.awakening;
          currentDay.hasData = 1;
          currentDay.entryId = item.user_sleep_id;
          currentDay.score = Math.round(((+item.quality + +item.awakening + +item.getting_sleep)/3)*10)/10;
        }
      });
      weekArray.push(currentDay);
    });
    return weekArray;
  }

  

  getWeekScore(weekArray: any) {
    let weekScore:any = {
      quality:0,
      getting_sleep:0,
      awakening:0,
      totalDays:0,
      average:0
    }
    weekArray.forEach((day:any) => {
      if(day.hasData == 1){
        weekScore.quality+= +day.quality;
        weekScore.getting_sleep+= +day.getting_sleep;
        weekScore.awakening+= +day.awakening;
        weekScore.totalDays++;
      }
    });

    if(weekScore.totalDays > 0){
      weekScore.average = Math.round(((+weekScore.quality + +weekScore.getting_sleep + +weekScore.awakening)/3) / weekScore.totalDays*10)/10;
    }
    return weekScore;
  }

  getWeekAvg(weekArray:any[], name:string){
    let score = 0;
    let entries = 0;
    let average = 0;
    weekArray.forEach((day:any) => {
      if(day.hasData == 1 && day[name]){
        entries++;
        score += +day[name];
      }
    });
    if(entries>0){
      average =  Math.round((score/entries)*10)/10;
    }
    return average;
  }
  onlyUnique(value: any, index: number, self: any) {
    return self.indexOf(value) === index;
  }

  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
