export class UserSleep {
    constructor(
      public user_sleep_id: number,
      public user_id: number,
      public date_recorded: string,
      public quality: number,
      public getting_sleep: number,
      public awakening: number,
      public created: string,
      public modified: string,
    ) {}
  }
