import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { UserBreathWorkout } from 'src/app/models/user-breath-workout.model';
import { UserTarget } from 'src/app/models/user-target.model';
import { UserBreathWorkoutService } from 'src/app/services/user-breath-workout.service';
import { UserTargetService } from 'src/app/services/user-target.service';

@Component({
  selector: 'app-programme-progress',
  templateUrl: './programme-progress.component.html',
  styleUrls: ['./programme-progress.component.scss'],
})
export class ProgrammeProgressComponent implements OnInit {
  allTargets: any;
  stepTarget?: UserTarget;
  firstTarget?: UserTarget;
  stepTargetProgress: any;
  firstTargetProgress: any;
  stepTargetIncrease: any;
  userData: any;

  allBreathWorkouts?: UserBreathWorkout[];
  breatheWeekData: any;
  breatheLast7Days: any;
  currentBreathLevel: any;
  breathLevelChange: any;
  breathLevelProgress:any;
  breathLoadIncrease:any;
  startingLoad:any;

  constructor(
    private userTargetService: UserTargetService,
    private userService: UserService,
    private userBreathWorkoutService: UserBreathWorkoutService
  ) {}

  ngOnInit(): void {
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
    });

    forkJoin({
      userTargets: this.userTargetService.fetchAll(),
      allBreathWorkouts: this.userBreathWorkoutService.fetchAll(),
    }).subscribe((responseData) => {
      this.allTargets = responseData.userTargets;
      this.stepTarget = this.userTargetService.getLatestTarget(
        this.allTargets,
        'step'
      );

      if (this.allTargets.length > 1) {
        this.firstTarget = this.userTargetService.getFirstTarget(
          this.allTargets,
          'step'
        );
        if (this.firstTarget) {
          this.firstTargetProgress = (+this.firstTarget.target / 10000) * 100;
          if (this.firstTargetProgress > 100) {
            this.firstTargetProgress = 100;
          }
          if (this.stepTarget) {
            this.stepTargetIncrease =
              +this.stepTarget.target - +this.firstTarget.target;
          }
        }
      }

      if (this.stepTarget) {
        this.stepTargetProgress = (+this.stepTarget.target / 10000) * 100;
        if (this.stepTargetProgress > 100) {
          this.stepTargetProgress = 100;
        }
      }

      this.allBreathWorkouts = responseData.allBreathWorkouts;
     

      this.currentBreathLevel = this.userBreathWorkoutService.getCurrentLevel(
        this.allBreathWorkouts
      );
      this.breathLevelChange = this.userBreathWorkoutService.getLevelChange(
        this.allBreathWorkouts
      );

      if(this.currentBreathLevel){
        this.breathLevelProgress = this.currentBreathLevel/106*100;
      }
      if(this.allBreathWorkouts.length>1){
        this.startingLoad = this.allBreathWorkouts[this.allBreathWorkouts.length-1].resistance;
        this.breathLoadIncrease = +this.currentBreathLevel - +this.startingLoad;
      }
    });
  }
}
