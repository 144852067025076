<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <h2>Add Entry</h2>
</nav>

<div class="container container-inner">
  <div class="row">
    <div class="col-12">
      <form
        name="entryForm"
        #entryForm="ngForm"
        class="standard-form mt-4"
        [hidden]="submitted"
      >
        <div class="row">
          <div class="col-12">
            <div class="form-group text-center">
              <label for="date">Date</label>
              <div class="form-group-inner">
                <input
                  type="text"
                  id="date"
                  class="form-control form-control-stub"
                  [ngModel]="bsDate"
                  #date="ngModel"
                  name="date"
                  placeholder="DD/MM/YYYY"
                  required
                  date
                  bsDatepicker
                  autocomplete="off"
                  [maxDate]="maxDate"
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY',
                    containerClass: 'theme-prepwell',
                    showWeekNumbers: false
                  }"
                />
                <div
                  class="alert alert-danger"
                  *ngIf="!date.valid && date.touched"
                >
                  Please enter a date above
                </div>
              </div>
            </div>

            <div class="form-group text-center">
              <label for="intensity">How is Your Mood?</label>
              <div class="form-group-inner">
                <p class="card-text mt-2 mb-0">
                  Please select on the scale below.
                </p>
                <div class="temp-slider">
                  <ngx-slider [(value)]="mood" [options]="options"></ngx-slider>
                  <div class="slider-custom-ticks">
                    <div class="slider-custom-tick">
                      <div class="custom-tick-icons">
                        <div class="icon-arrow">
                          <fa-icon [icon]="['far', 'arrow-up']"></fa-icon>
                        </div>
                        <div class="icon-emoji text-dark-green">
                          <fa-icon [icon]="['far', 'face-sad-cry']"></fa-icon>
                        </div>
                      </div>
                      <div class="tick-label">Sad, depressed, down</div>
                    </div>
                    <div class="slider-custom-tick">
                        <div class="custom-tick-icons">
                            <div class="icon-arrow">
                              <fa-icon [icon]="['far', 'arrow-up']"></fa-icon>
                            </div>
                            <div class="icon-emoji text-dark-green">
                              <fa-icon [icon]="['far', 'face-meh']"></fa-icon>
                            </div>
                          </div>
                      <div class="tick-label">
                        In the middle, Not happy nor sad
                      </div>
                    </div>
                    <div class="slider-custom-tick">
                        <div class="custom-tick-icons">
                            <div class="icon-arrow">
                              <fa-icon [icon]="['far', 'arrow-up']"></fa-icon>
                            </div>
                            <div class="icon-emoji text-dark-green">
                              <fa-icon [icon]="['far', 'face-laugh']"></fa-icon>
                            </div>
                          </div>
                      <div class="tick-label">Happy, high, awesome, great</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="form-group text-center">
                <label for="intensity">Are you feeling worried?</label>
                <div class="form-group-inner">
                  <p class="card-text mt-2 mb-0">
                    Please select on the scale below.
                  </p>
                  <div class="temp-slider">
                    <ngx-slider [(value)]="worry" [options]="options"></ngx-slider>
                    <div class="slider-custom-ticks">
                      <div class="slider-custom-tick">
                        <div class="custom-tick-icons">
                          <div class="icon-arrow">
                            <fa-icon [icon]="['far', 'arrow-up']"></fa-icon>
                          </div>
                          <div class="icon-emoji text-dark-green">
                            <fa-icon [icon]="['far', 'face-worried']"></fa-icon>
                          </div>
                        </div>
                        <div class="tick-label">Panic, overwhelmed, terrified</div>
                      </div>
                      <div class="slider-custom-tick">
                          <div class="custom-tick-icons">
                              <div class="icon-arrow">
                                <fa-icon [icon]="['far', 'arrow-up']"></fa-icon>
                              </div>
                              <div class="icon-emoji text-dark-green">
                                <fa-icon [icon]="['far', 'face-pensive']"></fa-icon>
                              </div>
                            </div>
                        <div class="tick-label">
                            Nervous, worried, afraid, anxious, scared
                        </div>
                      </div>
                      <div class="slider-custom-tick">
                          <div class="custom-tick-icons">
                              <div class="icon-arrow">
                                <fa-icon [icon]="['far', 'arrow-up']"></fa-icon>
                              </div>
                              <div class="icon-emoji text-dark-green">
                                <fa-icon [icon]="['far', 'face-smile-relaxed']"></fa-icon>
                              </div>
                            </div>
                        <div class="tick-label">Calm,
                            relaxed, confident</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            <div class="form-group text-center">
                <label for="intensity">Are you feeling stressed?</label>
                <div class="form-group-inner">
                  <p class="card-text mt-2 mb-0">
                    Please select on the scale below.
                  </p>
                  <div class="temp-slider">
                    <ngx-slider [(value)]="stress" [options]="options"></ngx-slider>
                    <div class="slider-custom-ticks">
                      <div class="slider-custom-tick">
                        <div class="custom-tick-icons">
                          <div class="icon-arrow">
                            <fa-icon [icon]="['far', 'arrow-up']"></fa-icon>
                          </div>
                          <div class="icon-emoji text-dark-green">
                            <fa-icon [icon]="['far', 'face-fearful']"></fa-icon>
                          </div>
                        </div>
                        <div class="tick-label">Completely distressed, overwhelmed or stressed out!</div>
                      </div>
                      <div class="slider-custom-tick">
                          <div class="custom-tick-icons">
                              <div class="icon-arrow">
                                <fa-icon [icon]="['far', 'arrow-up']"></fa-icon>
                              </div>
                              <div class="icon-emoji text-dark-green">
                                <fa-icon [icon]="['far', 'face-flushed']"></fa-icon>
                              </div>
                            </div>
                        <div class="tick-label">
                            Somewhat stressed
                        </div>
                      </div>
                      <div class="slider-custom-tick">
                          <div class="custom-tick-icons">
                              <div class="icon-arrow">
                                <fa-icon [icon]="['far', 'arrow-up']"></fa-icon>
                              </div>
                              <div class="icon-emoji text-dark-green">
                                <fa-icon [icon]="['far', 'face-smile-relaxed']"></fa-icon>
                              </div>
                            </div>
                        <div class="tick-label">Calm, relaxed, confident, no distress or stress</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            <div class="form-group text-center">
              <label>&nbsp;</label>
              <div>
                <button
                  (click)="onSubmitForm()"
                  class="btn btn-primary w-100"
                  [disabled]="date.invalid"
                >
                  Submit Entry
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div [hidden]="!submitted">
        <div class="submit-confirm">
          <h3>Excellent!</h3>
          <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'thumbs-up']"></fa-icon
          ></span>
          <h4>Entry Details</h4>
          <div class="entry-details">
            <div>
              <div class="label">Date</div>
              <div class="data">
                {{ date.value | customDate : "MMMM Do YYYY, h:mm:ss a" }}
              </div>
            </div>
            <div>
              <div class="label">Mood</div>
              <div class="data">{{mood}}</div>
            </div>
            <div>
              <div class="label">Stress</div>
              <div class="data" >{{stress}}
              
              </div>
            </div>
            <div>
              <div class="label">Worry</div>
              <div class="data">{{worry}}</div>
            </div>
          </div>
          <button
            class="btn btn-primary w-100 mt-4"
            [routerLink]="['/modules', 'feel-well', 'tracker']"
          >
            Back to Feel Well
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
