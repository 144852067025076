<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <h2>Overview</h2>
</nav>

<div class="container container-inner">
  <div class="row">
    <div class="col-12 col-md-12">
      <div class="card mt-3 bg-dark text-white mb-3">
        <img
          src="/assets/img/placeholder-feel-well.jpg"
          class="card-img-top card-img-top-fh"
          alt="..."
        />
        <div class="card-body text-center">
          <h3 class="card-title card-title-lg">Feel Well For Surgery</h3>
          <p class="card-text">-</p>
        </div>
      </div>
      <button
        class="btn btn-primary w-100"
        [routerLink]="['/modules', 'feel-well', 'tracker']"
      >
        Feel Well Tracker
      </button>
    </div>
    <div class="col-12 col-md-6" *ngIf="userData">
      <h4 class="text-center mt-4 mb-2">Module Task List</h4>

      <div class="card bg-light">
        <div class="card-body">
          <ul class="link-list">
            <li>
              <a>
                <span class="circle-num">1</span>
                <span class="icon-wrap more-link text-dark-green"
                  ><fa-icon [icon]="['fas', 'play-circle']"></fa-icon
                ></span>
                <span class="link-label"
                  >Nutrition, healthy eating and surgery</span
                >
                <span class="icon-wrap more-link text-secondary"
                  ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                ></span>
              </a>
            </li>
            <li>
              <a>
                <span class="circle-num">2</span>
                <span class="icon-wrap more-link text-dark-green"
                  ><fa-icon [icon]="['fas', 'play-circle']"></fa-icon
                ></span>
                <span class="link-label">Putting it all together</span>
                <span class="icon-wrap more-link text-secondary"
                  ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                ></span>
              </a>
            </li>
            <li>
              <a>
                <span class="circle-num">3</span>
                <span class="icon-wrap more-link text-dark-green"
                  ><fa-icon [icon]="['fas', 'play-circle']"></fa-icon
                ></span>
                <span class="link-label">How to eat healthily</span>
                <span class="icon-wrap more-link text-secondary"
                  ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                ></span>
              </a>
            </li>
            <li>
              <a>
                <span class="circle-num">4</span>
                <span class="icon-wrap more-link text-dark-green"
                  ><fa-icon [icon]="['fas', 'play-circle']"></fa-icon
                ></span>
                <span class="link-label">Healthy eating monitoring</span>
                <span class="icon-wrap more-link text-secondary"
                  ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                ></span>
              </a>
            </li>
            <li>
              <a>
                <span class="circle-num">5</span>
                <span class="icon-wrap more-link text-dark-green"
                  ><fa-icon [icon]="['far', 'file-chart-pie']"></fa-icon
                ></span>
                <span class="link-label">Infographic</span>
                <span class="icon-wrap more-link text-dark-green"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span>
              </a>
            </li>
            <li>
              <a>
                <span class="circle-num">6</span>
                <span class="icon-wrap more-link text-dark-green"
                  ><fa-icon [icon]="['far', 'ballot-check']"></fa-icon
                ></span>
                <span class="link-label">Goal setting and planning</span>
                <span class="icon-wrap more-link text-secondary"
                  ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                ></span>
              </a>
            </li>
            <li>
              <a>
                <span class="circle-num"
                  ><fa-icon [icon]="['far', 'repeat']"></fa-icon
                ></span>
                <span class="icon-wrap more-link text-dark-green"
                  ><fa-icon [icon]="['fas', 'cutlery']"></fa-icon
                ></span>
                <span class="link-label"
                  >Add Entries to the nutrition tracker</span
                >
                <span class="icon-wrap more-link text-secondary"
                  ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                ></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
