export class Message {
  public message_id?: number;
  public sender_id?: number;
  public recipient_id?: number;
  public message?: string;
  public category?: string;
  public is_admin?: number;
  public created?: string;
  public modified?: string;

  constructor(
    message_id?: number,
    sender_id?: number,
    recipient_id?: number,
    message?: string,
    category?: string,
    is_admin?: number,
    created?: string,
    modified?: string
  ) {
    this.message_id = message_id;
    this.sender_id = sender_id;
    this.recipient_id = recipient_id;
    this.message = message;
    this.category = category;
    this.is_admin = is_admin;
    this.created = created;
    this.modified = modified;
  }
}
