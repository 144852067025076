<nav
      class="navbar fixed-bottom navbar-expand navbar-light navbar-footer"
      aria-label="Main footer navigation"
    >
      <div class="d-flex justify-content-center" style="margin: 0 auto;">
        <ul class="navbar-nav">
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/dashboard']">
              <span class="icon-wrap"><fa-icon [icon]="['fas', 'home']"></fa-icon></span>
              <span class="nav-label">Home</span>
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/programme']">
              <span class="icon-wrap"><fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon></span>
              <span class="nav-label">Programme</span>
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/activity']">
              <span class="icon-wrap"><fa-icon [icon]="['fas', 'heartbeat']"></fa-icon></span>
              <span class="nav-label">Move&nbsp;Well</span>
            </a>
          </li>

          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/modules']">
              <span class="icon-wrap"><fa-icon [icon]="['fas', 'user-chart']"></fa-icon></span>
              <span class="nav-label">Modules</span>
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/social']">
              <span class="icon-wrap"><fa-icon [icon]="['fas', 'comments-alt']"></fa-icon></span>
              <span class="nav-label">Social</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
