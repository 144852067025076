import { ForumCategory } from './../forumCategory.model';
import { ForumService } from './../forum.service';
import { ForumTopic } from './../formTopic.model';
import { Component, OnInit } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-forum-category',
  templateUrl: './forum-category.component.html',
  styleUrls: ['./forum-category.component.scss']
})
export class ForumCategoryComponent implements OnInit {

  isLoading = false;
  error:any;
  private errorSub!: Subscription;
  topics: ForumTopic[] = [];
  categoryId!: number;
  category?: ForumCategory;
  avatarBase:any;

  constructor(private forumService: ForumService, private route: ActivatedRoute) {

   }

  ngOnInit(): void {
    this.avatarBase = environment.apiUrl + '/assets/';
    this.categoryId = this.route.snapshot.params.id;
    this.errorSub = this.forumService.error.subscribe((errorMessage) => {
      this.error = errorMessage;
    });

    this.isLoading = true;
    forkJoin({
      forumTopics: this.forumService.fetchTopics(this.categoryId),
      category: this.forumService.fetchCategory(this.categoryId),
    }
    ).subscribe(
      (responseData) => {

        this.isLoading = false;
        this.topics = responseData.forumTopics;
        this.category = responseData.category;
      },
      (error) => {
        this.error = error.message;
      }
    );
  }
}

