<ng-container [ngSwitch]="difficulty">
  <span class="icon-wrap text-difficulty-1" *ngSwitchCase="1">
    <fa-icon [icon]="['far', 'grin-stars']"></fa-icon>
  </span>
  <span class="icon-wrap text-difficulty-2" *ngSwitchCase="2">
    <fa-icon [icon]="['far', 'smile']"></fa-icon>
  </span>
  <span class="icon-wrap text-difficulty-3" *ngSwitchCase="3">
    <fa-icon [icon]="['far', 'meh']"></fa-icon>
  </span>
  <span class="icon-wrap text-difficulty-4" *ngSwitchCase="4">
    <fa-icon [icon]="['far', 'frown']"></fa-icon>
  </span>
  <span class="icon-wrap text-difficulty-5" *ngSwitchCase="5">
    <fa-icon [icon]="['far', 'tired']"></fa-icon>
  </span>
</ng-container>
