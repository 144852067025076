import { ForumService } from './../forum.service';
import { ForumTopic } from './../formTopic.model';
import { ForumCategory } from './../forumCategory.model';
import { ForumPost } from './../forumPost.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from './../../../../environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forum-topic',
  templateUrl: './forum-topic.component.html',
  styleUrls: ['./forum-topic.component.scss'],
})
export class ForumTopicComponent implements OnInit {
  isLoading = false;
  error:any;
  private errorSub?: Subscription;
  posts: ForumPost[] = [];
  categoryId!: number;
  topicId!: number;
  category!: ForumCategory;
  topic!: ForumTopic;
  avatarBase:any;

  @ViewChild('form', { static: false }) postForm!: NgForm;

  constructor(
    private forumService: ForumService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.avatarBase = environment.apiUrl + '/assets/';
    this.topicId = this.route.snapshot.params.id;
    this.errorSub = this.forumService.error.subscribe((errorMessage) => {
      this.error = errorMessage;
    });

    this.isLoading = true;
    forkJoin({
      forumPosts: this.forumService.fetchPosts(this.topicId),
      topic: this.forumService.fetchTopic(this.topicId),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        this.topic = responseData.topic;
        this.category = responseData.topic.category;
        this.posts = responseData.forumPosts;
      },
      (error) => {
        this.error = error.message;
      }
    );
  }

  onSubmit() {
    this.forumService
      .postReply(this.postForm.value.post, this.topicId)
      .subscribe(
        (responseData) => {
          // grab the latest posts
          // this.posts.push(responseData);

          this.postForm.reset();
          this.forumService.fetchPosts(this.topicId).subscribe(
            (postsResponseData) => {
              this.posts = postsResponseData;
            },
            (error) => {
              this.error = error.message;
            }
          );
        },
        (error) => {
          this.error = error.message;
        }
      );
  }

  onReportPost(event:any, post:any) {
    Swal.fire({
      title: 'Report post?',
      icon: 'info',
      html: 'Are you sure you want to report this post?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Confirm',
      confirmButtonAriaLabel: 'Report the post',
      cancelButtonText: 'Cancel',
      cancelButtonAriaLabel: 'Cancel',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.forumService.reportPost(post).subscribe(
          (response) => {
            // report it
            Swal.fire('Reported', '', 'success');
            post.status = 2;
          },
          (error) => {
            Swal.fire(
              'Error',
              'There was an error reporting the post',
              'error'
            );
          }
        );
      }
    });
  }
  onReply(event:any, post:any) {
    const element = document.querySelector('#postForm');
    element?.scrollIntoView();
  }
}
