<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <div class="prev">
    <a href=""
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-left']"></fa-icon></span
    ></a>
  </div>
  <h2>Overview</h2>
  <div class="next">
    <a [routerLink]="['/activity', 'fit', 'level', '1']">
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-right']"></fa-icon></span
    ></a>
  </div>
</nav>

<div class="container container-inner" *ngIf="userData">
  <div class="row">
    <div class="col-12 col-md-12">
      <div class="card mt-3 bg-dark text-white mb-3">
        <img
          src="/assets/img/placeholder-fit.jpg"
          class="card-img-top card-img-top-fh"
          alt="..."
        />
        <div class="card-body text-center">
          <h3 class="card-title card-title-lg">Get Fitter For Surgery</h3>
          <p class="card-text">
            Do aerobic training sessions to unlock and complete each of the
            three programme levels.
          </p>
          <hr />
          <h4 class="card-subtitle card-subtitle-lg">Current Level</h4>
          <div class="card-total card-total-light card-total-lg card-total-nb">
            <span>{{ userData.fit_level }}</span> /
            <span class="opacity-75">3</span>
          </div>
        </div>
      </div>
      <button
        class="btn btn-primary w-100"
        [routerLink]="['/activity', 'fit', 'add-entry']"
      >
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'circle-plus']"></fa-icon
        ></span>
        Add Entry
      </button>
    </div>
    <div class="col-12 col-md-6" *ngIf="userData">
      <div
        class="card bg-primary mt-3"
        *ngIf="userData.fit_level == 1 && canProgress"
      >
        <div class="card-body">
          <h3 class="card-title card-title-lg text-center">
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'lock-keyhole-open']"></fa-icon
            ></span>
            Level 2 Unlocked
          </h3>
          <p class="card-text text-center text-dark">
            Would you like to proceed to the next level of the Prep Fit?
          </p>
        </div>
        <div class="card-footer text-center relative text-dark bg-primary">
          <a (click)="onProgress(2)" class="stretched-link"
            >Proceed &nbsp;
            <span class="icon-wrap more-link text-white"
              ><fa-icon
                [icon]="['fas', 'circle-chevron-right']"
              ></fa-icon></span
          ></a>
        </div>
      </div>

      <div
      class="card bg-primary mt-3"
      *ngIf="userData.fit_level == 2 && canProgress"
    >
      <div class="card-body">
        <h3 class="card-title card-title-lg text-center">
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'lock-keyhole-open']"></fa-icon
          ></span>
          Level 3 Unlocked
        </h3>
        <p class="card-text text-center text-dark">
          Would you like to proceed to the next level of the Prep Fit?
        </p>
      </div>
      <div class="card-footer text-center relative text-dark bg-primary">
        <a (click)="onProgress(3)" class="stretched-link"
          >Proceed &nbsp;
          <span class="icon-wrap more-link text-white"
            ><fa-icon
              [icon]="['fas', 'circle-chevron-right']"
            ></fa-icon></span
        ></a>
      </div>
    </div>

      <h4 class="text-center mt-4 mb-2">
        Level {{ userData.fit_level }} - In Progress
      </h4>
      <div class="card bg-light">
        <div class="card-body">
          <div class="card-progress">
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                style="width: {{ progress }}%"
                [attr.aria-valuenow]="progress"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>

          <div class="text-center card-strapline">
            <div>{{ cardioMinsWeekForTarget }} MINS COMPLETED</div>
          </div>
          <hr />

          <div class="card-icon text-center">
            <span class="icon-wrap text-dark-green"
              ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
            ></span>
          </div>


          <p class="card-text text-center text-dark" *ngIf="userData.fit_level==1">
            At least <strong>150 minutes</strong> in total of
            <strong>moderate intensity</strong> aerobic training over a
            <strong>7 day</strong> period.
          </p>
          <p class="card-text text-center text-dark" *ngIf="userData.fit_level==2">

            At least <strong>75 minutes</strong> of <strong>vigorous intensity</strong> aerobic training logged over <strong>7 days</strong>.
            
          </p>
          <p class="card-text text-center text-dark" *ngIf="userData.fit_level==3">

            <strong>Three sessions</strong> of <strong>45-50 minutes vigorous</strong> aerobic training per week.

          </p>


        </div>
        <div class="card-footer text-center relative text-dark bg-primary">
          <a
            [routerLink]="['/activity', 'fit', 'level', userData.fit_level]"
            class="stretched-link"
            >View &nbsp;
            <span class="icon-wrap more-link text-dark"
              ><fa-icon
                [icon]="['fas', 'circle-chevron-right']"
              ></fa-icon></span
          ></a>
        </div>
      </div>


      <ng-container *ngIf="userData.fit_level !== 1">
        <h4 class="text-center mt-4 mb-2">Level 1 - Completed</h4>

        <div class="card bg-dark">
          <div class="card-body">
            <div class="card-icon text-center">
              <span class="icon-wrap text-white"
                ><fa-icon [icon]="['far', 'check']"></fa-icon
              ></span>
            </div>
            <p class="card-text text-center text-white">
              At least <strong>150 minutes</strong> in total of
            <strong>moderate intensity</strong> aerobic training over a
            <strong>7 day</strong> period.
            </p>
          </div>
          <div class="card-footer text-center relative text-dark bg-primary">
            <a [routerLink]="['/activity','fit','level','1']" class="stretched-link"
              >View &nbsp;
              <span class="icon-wrap more-link text-dark"
                ><fa-icon
                  [icon]="['fas', 'circle-chevron-right']"
                ></fa-icon></span
            ></a>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="userData.fit_level == 1 && !canProgress">
        <h4 class="text-center mt-4 mb-2">Level 2 - Locked</h4>

        <div class="card bg-light">
          <div class="card-body">
            <div class="card-icon text-center">
              <span class="icon-wrap text-dark-green"
                ><fa-icon [icon]="['far', 'lock']"></fa-icon
              ></span>
            </div>
            <p class="card-text text-center text-dark">
              At least 75 minutes of vigorous intensity aerobic training logged
              over 7 days.
            </p>
          </div>
          <div class="card-footer text-center relative text-dark bg-primary">
            <a href="" class="stretched-link"
              >View &nbsp;
              <span class="icon-wrap more-link text-dark"
                ><fa-icon
                  [icon]="['fas', 'circle-chevron-right']"
                ></fa-icon></span
            ></a>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="userData.fit_level == 3">
        <h4 class="text-center mt-4 mb-2">Level 2 - Completed</h4>

        <div class="card bg-primary">
          <div class="card-body">
            <div class="card-icon text-center">
              <span class="icon-wrap text-dark-green"
                ><fa-icon [icon]="['far', 'check']"></fa-icon
              ></span>
            </div>
            <p class="card-text text-center text-dark">
              At least 75 minutes of vigorous intensity aerobic training logged
              over 7 days.
            </p>
          </div>
        </div>
      </ng-container>

      <h4 class="text-center mt-4 mb-2">Level 3 - Locked</h4>
      <div class="card bg-light">
        <div class="card-body">
          <div class="card-icon text-center">
            <span class="icon-wrap text-dark-green"
              ><fa-icon [icon]="['far', 'lock']"></fa-icon
            ></span>
          </div>
          <p class="card-text text-center text-dark">
            Three sessions of 45-50 minutes vigorous aerobic training per week.
          </p>
        </div>
        <div class="card-footer text-center relative text-dark bg-primary">
          <a href="" class="stretched-link"
            >View &nbsp;
            <span class="icon-wrap more-link text-dark"
              ><fa-icon
                [icon]="['fas', 'circle-chevron-right']"
              ></fa-icon></span
          ></a>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <h4 class="text-center mt-4 mb-2">What is Prep Fit?</h4>
      <div class="card mt-3 bg-primary text-dark">
        <img
          src="/assets/img/placeholder-steps.jpg"
          class="card-img-top"
          alt="..."
        />

        <div class="card-footer text-center relative text-dark">
          <a
            [routerLink]="['/activity', 'fit', 'resources']"
            class="stretched-link"
            >View &nbsp;
            <span class="icon-wrap more-link text-dark"
              ><fa-icon
                [icon]="['fas', 'circle-chevron-right']"
              ></fa-icon></span
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>
