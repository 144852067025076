export class UserTarget {
    constructor(
      public user_target_id: number,
      public user_id: number,
      public target_type: string,
      public target: number,
      public target_unit: string,
      public created: string,
      public modified: string,
    ) {}
  }