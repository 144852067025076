<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <h2>Add Entry</h2>
</nav>

<div class="container container-inner">
  <div class="row">
    <div class="col-12">
      <form
        name="entryForm"
        #entryForm="ngForm"
        class="standard-form mt-4"
        [hidden]="submitted"
      >
        <div class="row">
          <div class="col-12">
            <div class="form-group text-center">
              <label for="date">Date</label>
              <div class="form-group-inner">
                <input
                  type="text"
                  id="date"
                  class="form-control form-control-stub"
                  [ngModel]="bsDate"
                  #date="ngModel"
                  name="date"
                  placeholder="DD/MM/YYYY"
                  required
                  date
                  bsDatepicker
                  autocomplete="off"
                  [maxDate]="maxDate"
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY',
                    containerClass: 'theme-prepwell',
                    showWeekNumbers: false
                  }"
                />
                <div
                  class="alert alert-danger"
                  *ngIf="!date.valid && date.touched"
                >
                  Please enter a date above
                </div>
              </div>
            </div>

            <div class="form-group text-center mb-0">
              <label class="text-center">Today's Smoking Behaviour</label>
            </div>

            <div class="row">
              <div class="col-6 mb-3">
                <div class="form-group-container">
                  <p class="instructions mb-0">
                    I did NOT <br />
                    smoke today
                  </p>

                  <div class="text-center">
                    <div class="icon-wrap text-dark-green icon-form-indicator">
                      <fa-icon [icon]="['far', 'ban-smoking']"></fa-icon>
                    </div>
                    <button class="btn btn-white" [ngClass]="{'active':noSmoking == true}" (click)="onToggleNoSmoking()">SELECT</button>
                  </div>
                </div>
              </div>
              <div class="col-6 mb-3">
                <div class="form-group-container">
                  <p class="instructions mb-0">
                    Number of <br />
                    cigarettes smoked
                  </p>
                  <div class="text-center">
                    <div class="icon-wrap text-dark-green icon-form-indicator">
                      <fa-icon [icon]="['far', 'smoking']"></fa-icon>
                    </div>
                  </div>
                  <div class="up-down-scale">
                    <button class="btn btn-sm btn-white" (click)="onMinusCigarettes()" [disabled]="totalCigarettes == 0"> <fa-icon [icon]="['far', 'circle-minus']"></fa-icon></button>
                    <div class="up-down-total">{{totalCigarettes}}</div>
                    <button class="btn btn-sm btn-white" (click)="onPlusCigarettes()"> <fa-icon [icon]="['far', 'circle-plus']"></fa-icon></button>
                  </div>
                </div>
              </div>

              <div class="col-6 mb-3">
                <div class="form-group-container">
                  <p class="instructions mb-0">
                    I used nicotine replacement therapy
                  </p>
                  <div class="text-center">
                    <div class="icon-wrap text-dark-green icon-form-indicator">
                      <fa-icon [icon]="['far', 'note-sticky']"></fa-icon>
                    </div>
                    <button class="btn btn-white" [ngClass]="{'active':nicotineReplacement == true}" (click)="nicotineReplacement = !nicotineReplacement">SELECT</button>
                  </div>
                </div>
              </div>

              <div class="col-6 mb-3">
                <div class="form-group-container">
                  <p class="instructions mb-0">
                    I contacted smoking <br />
                    cessation services
                  </p>
                  <div class="text-center">
                    <div class="icon-wrap text-dark-green icon-form-indicator">
                      <fa-icon [icon]="['far', 'comments']"></fa-icon>
                    </div>
                    <button class="btn btn-white" [ngClass]="{'active':smokingCessation == true}" (click)="smokingCessation = !smokingCessation">SELECT</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group text-center">
              <label>&nbsp;</label>
              <div>
                <button
                  (click)="onSubmitForm()"
                  class="btn btn-primary w-100"
                  [disabled]="date.invalid"
                >
                  Submit Entry
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div [hidden]="!submitted">
        <div class="submit-confirm">
          <h3>Excellent!</h3>
          <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'thumbs-up']"></fa-icon
          ></span>
          <h4>Entry Details</h4>
          <div class="entry-details">
            <div>
              <div class="label">Date</div>
              <div class="data">
                {{ date.value | customDate : "MMMM Do YYYY, h:mm:ss a" }}
              </div>
            </div>
            <div>
              <div class="label">Cigarettes</div>
              <div class="data">{{totalCigarettes}}</div>
            </div>
          </div>
          <button
            class="btn btn-primary w-100 mt-4"
            [routerLink]="['/modules', 'i-quit', 'tracker']"
          >
            Back to I-Quit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
