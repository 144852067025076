<nav class="subnav">
  <div class="prev">
    <a [routerLink]="['/programme', 'task-list']"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-left']"></fa-icon></span
    ></a>
  </div>
  <h2>Progress</h2>
  <div class="next">
    <a [routerLink]="['/programme', 'achievements']">
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-right']"></fa-icon></span
    ></a>
  </div>
</nav>
<div class="container-inner">
  <div class="card bg-light mt-3">
    <div class="card-body">
      <tabset [justified]="true">
        <tab id="tab1">
          <ng-template tabHeading> Activity </ng-template>

          <div class="card bg-white mt-4" *ngIf="stepTarget">
            <div class="card-body">
              <h3 class="text-center">
                <span class="icon-wrap text-dark-green"
                  ><fa-icon [icon]="['fas', 'walking']"></fa-icon
                ></span>
                PREP STEPS - DAILY TARGET
              </h3>
              <div style="flex-grow: 1" class="relative">
                <span
                  style="left: {{ stepTargetProgress }}%"
                  class="icon-wrap bg-primary text-dark d-flex justify-content-center align-items-center rounded-circle progress-tracker-icon progress-tracker-icon-sm"
                  ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
                ></span>
                <div class="progress" style="height: 12px; width: 100%">
                  <div
                    class="progress-bar bg-primary rounded progress-outline"
                    role="progressbar"
                    style="width: {{ stepTargetProgress }}%;"
                    [attr.aria-valuenow]="stepTargetProgress"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div class="target-pointers">
                <div
                  *ngIf="firstTarget"
                  class="target-pointer"
                  style="left: {{ firstTargetProgress }}%"
                >
                  <div class="target-pointer-icon icon-wrap">
                    <fa-icon [icon]="['fas', 'caret-up']"></fa-icon>
                  </div>
                  <div class="target-pointer-label">
                    START<br />
                    {{ firstTarget.target | number }} Steps
                  </div>
                </div>

                <div
                  class="target-pointer"
                  style="left: {{ stepTargetProgress }}%"
                >
                  <div class="target-pointer-icon icon-wrap">
                    <fa-icon [icon]="['fas', 'caret-up']"></fa-icon>
                  </div>
                  <div class="target-pointer-label">
                    CURRENT<br />
                    {{ stepTarget.target | number }} Steps
                  </div>
                </div>
              </div>

              <div class="card-text text-center">
                <strong>Current target:</strong>
                {{ stepTarget.target | number }} steps a day
              </div>
              <div
                class="card-info text-center"
                *ngIf="stepTargetIncrease && firstTarget"
              >
                <span class="text-dark-green"
                  >+{{ stepTargetIncrease | number }}</span
                >
                step increase from starting at
                {{ firstTarget.target | number }} steps
              </div>
            </div>
          </div>

          <div class="card bg-white mt-3" *ngIf="userData">
            <div class="card-body">
              <h3 class="text-center">
                <span class="icon-wrap text-dark-green"
                  ><fa-icon [icon]="['fas', 'running']"></fa-icon
                ></span>
                PREP FIT - Levels
              </h3>

              <div
                class="days-indicator days-indicator-lg days-indicator-tight text-center"
              >
                <div class="day">
                  <div class="day-status-lg bg-light">
                    <div class="day-status-title-sm">Level</div>
                    <div class="day-status-title">1</div>
                  </div>
                  <span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'caret-up']"></fa-icon>
                  </span>
                </div>
                <div class="day">
                  <div class="day-status-lg bg-light">
                    <div class="day-status-title-sm">
                      Level
                      <span class="icon-wrap">
                        <fa-icon [icon]="['fas', 'lock']"></fa-icon>
                      </span>
                    </div>
                    <div class="day-status-title">2</div>
                  </div>
                  <span class="icon-wrap"> &nbsp; </span>
                </div>
                <div class="day">
                  <div class="day-status-lg bg-light">
                    <div class="day-status-title-sm">
                      Level
                      <span class="icon-wrap">
                        <fa-icon [icon]="['fas', 'lock']"></fa-icon>
                      </span>
                    </div>
                    <div class="day-status-title">3</div>
                  </div>
                  <span class="icon-wrap"> &nbsp; </span>
                </div>
              </div>

              <div class="card-text text-center">
                <strong>Current:</strong> Level {{ userData.fit_level }}
              </div>
              <div class="card-info text-center">
                Started level on
                {{ userData.date_registered | customDate : "DD/MM/YYYY" }}
              </div>
            </div>
          </div>

          <div class="card bg-white mt-3" *ngIf="userData">
            <div class="card-body">
              <h3 class="text-center">
                <span class="icon-wrap text-dark-green"
                  ><fa-icon [icon]="$any(['fac', 'lunge'])"></fa-icon
                  ></span>
                PREP Strong - Levels
              </h3>

              <div
                class="days-indicator days-indicator-lg days-indicator-tight text-center"
              >
                <div class="day">
                  <div class="day-status-lg bg-light">
                    <div class="day-status-title-sm">Level</div>
                    <div class="day-status-title">1</div>
                  </div>
                  <span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'caret-up']"></fa-icon>
                  </span>
                </div>
                <div class="day">
                  <div class="day-status-lg bg-light">
                    <div class="day-status-title-sm">
                      Level
                      <span class="icon-wrap">
                        <fa-icon [icon]="['fas', 'lock']"></fa-icon>
                      </span>
                    </div>
                    <div class="day-status-title">2</div>
                  </div>
                  <span class="icon-wrap"> &nbsp; </span>
                </div>
                <div class="day">
                  <div class="day-status-lg bg-light">
                    <div class="day-status-title-sm">
                      Level
                      <span class="icon-wrap">
                        <fa-icon [icon]="['fas', 'lock']"></fa-icon>
                      </span>
                    </div>
                    <div class="day-status-title">3</div>
                  </div>
                  <span class="icon-wrap"> &nbsp; </span>
                </div>
              </div>

              <div class="card-text text-center">
                <strong>Current:</strong> Level {{ userData.move_level }}
              </div>
              <div class="card-info text-center">
                Started level on
                {{ userData.date_registered | customDate : "DD/MM/YYYY" }}
              </div>
            </div>
          </div>

          <div class="card bg-white mt-3" *ngIf="currentBreathLevel">
            <div class="card-body">
              <h3 class="text-center">
                <span class="icon-wrap text-dark-green"
                  ><fa-icon [icon]="['fas', 'lungs']"></fa-icon
                ></span>
                Breathe Well - Load Levels
              </h3>
              <div style="flex-grow: 1" class="relative">
                
                <div class="progress" style="height: 12px; width: 100%">
                  <div
                    class="progress-bar bg-primary rounded progress-outline"
                    role="progressbar"
                    style="width: {{ breathLevelProgress }}%;"
                    [attr.aria-valuenow]="breathLevelProgress"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div class="target-pointers">
                <!--div
                  *ngIf="firstTarget"
                  class="target-pointer"
                  style="left: {{ firstTargetProgress }}%"
                >
                  <div class="target-pointer-icon icon-wrap">
                    <fa-icon [icon]="['fas', 'caret-up']"></fa-icon>
                  </div>
                  <div class="target-pointer-label">
                    START<br />
                    {{ firstTarget.target | number }} Steps
                  </div>
                </div-->

                <div
                *ngIf="breathLevelProgress"
                  class="target-pointer"
                  style="left: {{ breathLevelProgress }}%"
                >
                  <div class="target-pointer-icon icon-wrap">
                    <fa-icon [icon]="['fas', 'caret-up']"></fa-icon>
                  </div>
                  <div class="target-pointer-label">
                    CURRENT<br />
                    {{ currentBreathLevel | number }} Load
                  </div>
                </div>

                <div
                  class="target-pointer"
                  style="left: 100%"
                >
                  <div class="target-pointer-icon icon-wrap">
                    <fa-icon [icon]="['fas', 'caret-up']"></fa-icon>
                  </div>
                  <div class="target-pointer-label">
                    FINAL<br />
                    106 Load
                  </div>
                </div>
              </div>

              <div class="card-text text-center">
                <strong>Current:</strong>
                {{ currentBreathLevel | number }} Load
              </div>
              <div
                class="card-info text-center"
                *ngIf="breathLoadIncrease"
              >
                <span class="text-dark-green"
                  >+{{ breathLoadIncrease | number }}</span
                >
                load increase from starting at
                {{ startingLoad | number }} load
              </div>
            </div>
          </div>


        </tab>
        <tab id="tab2">
          <ng-template tabHeading>Modules </ng-template>
        </tab>
      </tabset>
    </div>
  </div>
</div>
