<div class="container-inner">
  <h2 class="text-center my-3 title-light">
    <span class="lighter">Please select an area...</span>
  </h2>
  <div class="row" *ngIf="userData">
    <div class="col-6">
      <div class="card mb-4 rounded bg-dark text-white">
        <img
          src="/assets/img/placeholder-steps.jpg"
          class="card-img-top"
          alt="..."
        />

        <div class="card-body text-center pb-0">
          <div class="card-masthead">
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'walking']"></fa-icon
            ></span>
            <h2>Prep Steps</h2>
          </div>

          <ng-container *ngIf="!isLoading">
            <ng-container *ngIf="stepTarget">
              <h4 class="card-subtitle">Today's Steps</h4>
              <div class="card-total">
                <span class="text-primary">{{ todaySteps | number }}</span> /
                {{ stepTarget.target | number }}
              </div>
              <span class="icon-wrap card-icon-sm"
                ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
              ></span>
              <div class="days-indicator days-indicator-sm">
                <div class="day" *ngFor="let day of stepWeekData">
                  <div class="day-label">
                    {{ day.date | customDate : "dddd" | slice : 0 : 1 }}
                  </div>
                  <div
                    class="day-status"
                    [ngClass]="{
                      'bg-danger':
                        +day.steps < +stepTarget.target && day.date < today,
                      'bg-primary': +day.steps > +stepTarget.target
                    }"
                  ></div>
                </div>
              </div>

              <p class="card-info mt-2 mb-0">
                Hit your daily steps target every day
              </p>
            </ng-container>
            <ng-container *ngIf="!stepTarget">
              <p class="card-info mb-2">Please set a daily step target.</p>
              <form name="stepTargetForm" #stepTargetForm="ngForm">
                <div class="row">
                  <div class="col-md-4 offset-md-1">
                    <div class="form-group">
                      <label for="mins">Steps Per Day</label>
                      <input
                        type="number"
                        name="targetSteps"
                        class="form-control form-control-sm"
                        placeholder="e.g. 8000"
                        id="targetSteps"
                        [ngModel]
                        #targetSteps="ngModel"
                        [min]="1"
                        [max]="15000"
                        autocomplete="off"
                      />

                      <div
                        class="alert alert-danger"
                        *ngIf="targetSteps.touched && targetSteps.invalid"
                      >
                        This must be a number between 1 and 15000.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <button
                    (click)="submitStepTarget()"
                    class="btn btn-primary btn-sm w-100"
                    [disabled]="targetSteps.invalid"
                  >
                    SET TARGET
                  </button>
                </div>
              </form>
            </ng-container>
            <a [routerLink]="['/activity', 'steps']" class="stretched-link"
              ><span class="icon-wrap more-link text-primary"
                ><fa-icon
                  [icon]="['fas', 'circle-chevron-right']"
                ></fa-icon></span
            ></a>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card mb-4 rounded bg-dark text-white">
        <img
          src="/assets/img/placeholder-steps.jpg"
          class="card-img-top"
          alt="..."
        />
        <div class="card-body text-center pb-0">
          <div class="card-masthead">
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'running']"></fa-icon
            ></span>
            <h2>Prep Fit</h2>
          </div>
          <h4 class="card-subtitle">Level</h4>
          <div class="card-total">
            <span class="text-primary">{{ userData.fit_level }}</span> / 3
          </div>
          <span class="icon-wrap card-icon-sm"
            ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
          ></span>

          <div class="card-progress">
            <div class="card-progress-title">
              {{ cardioMinsWeek }} MINS COMPLETED
            </div>
            <div class="progress progress-sm">
              <div
                class="progress-bar"
                role="progressbar"
                style="width: {{ cardioProgress }}%"
                [attr.aria-valuenow]="cardioProgress"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>

          <p class="card-info mt-2 mb-0">
            150 minutes of moderate exercise over 7 days
          </p>
          <a [routerLink]="['/activity', 'fit']" class="stretched-link"
            ><span class="icon-wrap more-link text-primary"
              ><fa-icon
                [icon]="['fas', 'circle-chevron-right']"
              ></fa-icon></span
          ></a>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="userData">
    <div class="col-6">
      <div class="card mb-4 rounded bg-dark text-white">
        <img
          src="/assets/img/placeholder-steps.jpg"
          class="card-img-top"
          alt="..."
        />
        <div class="card-body text-center pb-0">
          <div class="card-masthead">
            <span class="icon-wrap"
              ><fa-icon [icon]="$any(['fac', 'lunge'])"></fa-icon
            ></span>
            <h2>Prep Strong</h2>
          </div>

          <h4 class="card-subtitle">Level</h4>
          <div class="card-total">
            <span class="text-primary">{{ userData.move_level }}</span> / 3
          </div>
          <span class="icon-wrap card-icon-sm"
            ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
          ></span>

          <div class="days-indicator days-indicator-sm">
            <div class="day" *ngFor="let day of moveWeekData">
              <div class="day-label">
                {{ day.date | customDate : "dddd" | slice : 0 : 1 }}
              </div>
              <div
                class="day-status"
                [ngClass]="{ 'bg-primary': day.total_reps >= 144 }"
              >
                <span
                  class="dot"
                  *ngIf="day.total_reps > 0 && day.total_reps < 144"
                ></span>
              </div>
            </div>
          </div>

          <p class="card-info mt-2 mb-0">2 or more sessions per week</p>
          <a [routerLink]="['/activity', 'move']" class="stretched-link"
            ><span class="icon-wrap more-link text-primary"
              ><fa-icon
                [icon]="['fas', 'circle-chevron-right']"
              ></fa-icon></span
          ></a>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card mb-4 rounded bg-dark text-white">
        <img
          src="/assets/img/placeholder-steps.jpg"
          class="card-img-top"
          alt="..."
        />
        <div class="card-body text-center pb-0">
          <div class="card-masthead">
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'lungs']"></fa-icon
            ></span>
            <h2>Breathe Well</h2>
          </div>
          <ng-container *ngIf="currentBreathLevel">
            <h4 class="card-subtitle">Load</h4>
            <div class="card-total">
              <span class="text-primary">{{ currentBreathLevel }}</span> / 106
            </div>
            <span class="icon-wrap card-icon-sm"
              ><fa-icon [icon]="['far', 'bullseye-arrow']"></fa-icon
            ></span>
          </ng-container>
          <div class="days-indicator days-indicator-sm">
            <div class="day" *ngFor="let day of breatheWeekData">
              <div class="day-label">
                {{ day.date | customDate : "dddd" | slice : 0 : 1 }}
              </div>
              <div
                class="day-status mb-1"
                [ngClass]="{
                  'bg-primary': day.am.breaths > 0,
                  'bg-white': day.am.breaths < 1
                }"
              ></div>
              <div
                class="day-status"
                [ngClass]="{
                  'bg-primary': day.pm.breaths > 0,
                  'bg-white': day.pm.breaths < 1
                }"
              ></div>
            </div>
          </div>

          <p class="card-info mt-2 mb-0">2 sessions every day</p>
          <a [routerLink]="['/activity', 'breathe']" class="stretched-link"
            ><span class="icon-wrap more-link text-primary"
              ><fa-icon
                [icon]="['fas', 'circle-chevron-right']"
              ></fa-icon></span
          ></a>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6" *ngIf="userData">
      <h4 class="text-center mt-4 mb-2">More Information</h4>
      <div class="card bg-light">
        <div class="card-body">
          <ul class="link-list">
            <li>
              <a [routerLink]="['/activity','resources', 1]">
               
                <span class="icon-wrap more-link text-dark"
                  ><fa-icon [icon]="['fas', 'play-circle']"></fa-icon
                ></span>
                <span class="link-label"
                  >Welcome to Move Well</span
                >
                <span class="icon-wrap more-link text-primary"
                  ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                ></span>
              </a>
            </li>
            <li>
              <a [routerLink]="['/activity','resources', 2]">
                <span class="icon-wrap more-link text-dark"
                  ><fa-icon [icon]="['fas', 'play-circle']"></fa-icon
                ></span>
                <span class="link-label">How to Move Safely</span>
                <span class="icon-wrap more-link text-primary"
                  ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                ></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
