import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { UserSmokingService } from 'src/app/services/user-smoking.service';
import { UserTaskStatusService } from 'src/app/services/user-task-status.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-i-quit-tracker',
  templateUrl: './i-quit-tracker.component.html',
  styleUrls: ['./i-quit-tracker.component.scss']
})
export class IQuitTrackerComponent implements OnInit {
  isLoading = false;
  userData: any;
  allEntries: any;
  smokingWeekData: any;
  allTimeStats: any;
  allWeeksData: any = [];
  weekScore = 0;
  showBreakdown = false;
  userWeek = 0;
  showWeek = 0;
  allTaskStatuses:any;

  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public today = this.dateHelperService.getToday();
  constructor(
    private dateHelperService: DateHelperService,
    private userService: UserService,
    private router: Router,
    public userSmokingService:UserSmokingService,
    private userTaskStatusService:UserTaskStatusService
  ) {

   
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        this.isLoading = false;
      }
    });

    forkJoin({
      allEntries: this.userSmokingService.fetchAll(),
      allUserTaskStatuses: this.userTaskStatusService.fetchAll()
    }).subscribe(
      (responseData) => {
        this.allEntries = responseData.allEntries;

        this.smokingWeekData = this.userSmokingService.generateWeekArray(
          this.currentWeek,
          this.allEntries
        );


       // this.allTimeStats = this.userSmokingService.getStats(this.allEntries);

        this.allWeeksData.push(this.smokingWeekData);
        let endOfWeek = this.currentWeek[6];
        let weeksRun = 0;
        this.userService.userData.subscribe((userData) => {
          if (userData) {
            while (moment(endOfWeek).subtract(1, 'week') > moment(this.userData.date_registered) && weeksRun<100) {
              weeksRun++;
              let prevWeek = this.dateHelperService.getWeekFor(
                moment(endOfWeek).subtract(1, 'week')
              );
              let prevWeekData = this.userSmokingService.generateWeekArray(
                prevWeek,
                this.allEntries
              );
              this.allWeeksData.push(prevWeekData);
              endOfWeek = prevWeek[6];
            }
            this.userWeek = this.allWeeksData.length;
            this.showWeek = this.allWeeksData.length;
          }
        });

        this.weekScore = this.userSmokingService.getWeekScore(
          this.allWeeksData[this.userWeek - this.showWeek]
        );
        this.allTaskStatuses = responseData.allUserTaskStatuses;
        let readResources = this.userTaskStatusService.checkTaskStatus(
          this.allTaskStatuses,
          'IQuitIntro'
        );
        if (!readResources) {
          this.router.navigate(['/modules', 'i-quit', 'resources']);
        }

        this.isLoading = false;
      },
      (error) => {}
    );
  }

  
  getWeekCigarettes(weekArray:any){
    let cigarettes = 0;
    weekArray.forEach((day:any)=>{
      cigarettes+= day.cigarettes;
    })
    return cigarettes;
  }

  getWeekFreeDays(weekArray:any){
    let freeDays = 0;
    weekArray.forEach((day:any)=>{
      if(day.cigarettes == 0 && day.hasData){
        freeDays++;
      }
    })
    return freeDays;
  }

  getWeekCessation(weekArray:any){
    let cessation = 0;
    weekArray.forEach((day:any)=>{
      if(day.cessation == 1 && day.hasData){
        cessation++;
      }
    })
    return cessation;
  }

  getWeekNicotine(weekArray:any){
    let nicotine = 0;
    weekArray.forEach((day:any)=>{
      if(day.nicotine_replacement == 1 && day.hasData){
        nicotine++;
      }
    })
    return nicotine;
  }
  onPrevWeek(){

    if(this.showWeek>1){
      this.showWeek--;
      this.weekScore = this.userSmokingService.getWeekScore(
        this.allWeeksData[this.userWeek - this.showWeek]
      );
    }
    
  }

  onNextWeek(){
    if(this.showWeek<this.userWeek){
      this.showWeek++;
      this.weekScore = this.userSmokingService.getWeekScore(
        this.allWeeksData[this.userWeek - this.showWeek]
      );
    }
  }


  onDeleteEntry(entryId: number) {
    Swal.fire({
      title: "Are you sure?",
      text:  "You won't be able to undo this",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText:'Cancel',
      confirmButtonText:'Yes, delete it',
    }).then((result) => {
      if (result.value) {
        this.userSmokingService.delete(entryId).subscribe(
          (responseData) => {
            Swal.fire("Your entry has been deleted", "success");

            let deleteIndex = -1;
            this.allEntries.forEach((entry:any, workoutIndex:any) => {
              if (+entry.user_eat_well_id === +entryId) {
                deleteIndex = workoutIndex;
              }
            });

            if (deleteIndex != -1) {
              // splice it
              this.allEntries.splice(deleteIndex, 1);
            }

            // redirect
            this.router.navigateByUrl('/nothing', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/modules', 'i-quit', 'tracker']);
          })


          },
          (error) => {
            this.isLoading = false;
            Swal.fire('Error', 'An unknown error has occured, please contact us', 'error');
          }
        );
      }
    });
  }

}
