import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { ActivityTrackerService } from 'src/app/services/activity-tracker.service';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { UserStepService } from 'src/app/services/user-step.service';
import { UserTargetService } from 'src/app/services/user-target.service';
import { UserTaskStatusService } from 'src/app/services/user-task-status.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-steps-index',
  templateUrl: './steps-index.component.html',
  styleUrls: ['./steps-index.component.scss'],
})
export class StepsIndexComponent implements OnInit {
  isLoading = false;
  allSteps: any;
  todaySteps = 0;
  stepTarget: any;
  stepWeekData: any;
  daysToSurgery = 0;
  currentWeekNumber = 0;
  userData: any;
  stepDaysHit = 0;
  totalSteps = 0;
  bestDay:any;
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public today = this.dateHelperService.getToday();
  @ViewChild('stepForm', { static: false }) stepForm?: NgForm;
  submitted = false;
  bsDate = new Date();
  maxDate = new Date();
  allTaskStatuses: any;


  constructor(
    private dateHelperService: DateHelperService,
    private userService: UserService,
    private userStepService: UserStepService,
    private userTargetService: UserTargetService,
    private router: Router,
    private userTaskStatusService:UserTaskStatusService,
    private activityTrackerService: ActivityTrackerService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        this.currentWeekNumber = +userData.current_week;
        if (userData.surgery_date) {
          var a = moment(moment().format('YYYY-MM-DD'));
          var b = moment(userData.surgery_date);
          this.daysToSurgery = b.diff(a, 'days');
        }

        if(this.userData.device_type == 'garmin'){
          this.activityTrackerService.syncTracker('garmin').subscribe((responseData)=>{
            //console.log('activity checked')
          });
          }

      }
    });

    //
    forkJoin({
      userTargets: this.userTargetService.fetchAll(),
      allSteps: this.userStepService.fetchAll(),
      allUserTaskStatuses: this.userTaskStatusService.fetchAll(),
    }).subscribe(
      (responseData) => {
        this.allSteps = responseData.allSteps;
        this.stepTarget = this.userTargetService.getLatestTarget(
          responseData.userTargets,
          'step'
        );
        this.stepWeekData = this.userStepService.generateWeekArray(
          this.currentWeek,
          this.allSteps
        );
        this.todaySteps = this.userStepService.getDaySteps(
          moment().format('YYYY-MM-DD'),
          this.allSteps
        );
        this.stepDaysHit = 0;
        this.stepWeekData.forEach((day:any) => {
          if(+day.steps >= +this.stepTarget.target){
            this.stepDaysHit++;
          }
        });
        this.totalSteps = this.userStepService.getTotalSteps(this.allSteps);
        this.bestDay = this.userStepService.getBestDay(this.allSteps);

        this.allTaskStatuses = responseData.allUserTaskStatuses;
        // have they read the move well activity?
        let readMoveWell = this.userTaskStatusService.checkTaskStatus(
          this.allTaskStatuses,
          'MoveWellIntro'
        );

        if (!readMoveWell) {
          // forward on
          this.router.navigate(['/activity', 'resources', 1]);
        }

        // have they read the move well activity?
        let readPrepSteps = this.userTaskStatusService.checkTaskStatus(
          this.allTaskStatuses,
          'PrepStepsIntro'
        );

        if (!readPrepSteps) {
          // forward on
          this.router.navigate(['/activity', 'steps', 'resources']);
        }

        this.isLoading = false;
      },
      (error) => {}
    );
  }

  submitStepForm(){
    this.userStepService
      .create(this.stepForm?.value.steps, moment(this.stepForm?.value.date).format('YYYY-MM-DD'))
      .subscribe((responseData) => {
        // refresh it
        Swal.fire({
          icon: 'success',
          title: 'Steps Updated',
          text: 'Your steps were updated',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/activity', 'steps']));
      });

  }
}
