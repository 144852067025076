<div class="container-inner">
    <div class="row">
        <div class="col-12 col-md-12">
            <div class="card mt-3 bg-primary text-dark relative">
                <img
                  src="/assets/img/placeholder-steps.jpg"
                  class="card-img-top"
                  alt="..."
                />
        
                <div class="card-footer text-white  text-center">
                  <a [routerLink]="['/social','forum']" class="stretched-link"
                    >Share your story &nbsp;
                    <span class="icon-wrap more-link text-white"
                      ><fa-icon
                        [icon]="['fas', 'circle-chevron-right']"
                      ></fa-icon></span
                  ></a>
                </div>
              </div>
        </div>
        <div class="col-12 col-md-12">
            <div class="card mt-3 bg-primary text-dark">
                <img
                  src="/assets/img/placeholder-steps.jpg"
                  class="card-img-top"
                  alt="..."
                />
        
                <div class="card-footer text-center relative text-dark">
                  <a [routerLink]="['/social', 'healthcare-professional']" class="stretched-link"
                    >Ask a Healthcare Professional &nbsp;
                    <span class="icon-wrap more-link text-white"
                      ><fa-icon
                        [icon]="['fas', 'circle-chevron-right']"
                      ></fa-icon></span
                  ></a>
                </div>
              </div>
        </div>
        <div class="col-12 col-md-12">
            <div class="card mt-3 bg-primary text-dark">
                <img
                  src="/assets/img/placeholder-steps.jpg"
                  class="card-img-top"
                  alt="..."
                />
        
                <div class="card-footer text-center relative text-dark">
                  <a [routerLink]="['/social', 'support']" class="stretched-link"
                    >Technical Support &nbsp;
                    <span class="icon-wrap more-link text-white"
                      ><fa-icon
                        [icon]="['fas', 'circle-chevron-right']"
                      ></fa-icon></span
                  ></a>
                </div>
              </div>
        </div>
    </div>
</div>