<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <div class="prev">
    <a [routerLink]="prev" *ngIf="prev"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-left']"></fa-icon></span
    ></a>
  </div>
  <h2>Level {{ level }}</h2>
  <div class="next">
    <a [routerLink]="next" *ngIf="next">
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-right']"></fa-icon></span
    ></a>
  </div>
</nav>

<div class="container container-inner container-content">
  <div class="row">
    <div class="col">

        <video
       
        width="100%"
        style="max-height:80vh;"
        controls
        preload
        class="mt-4 fade-in mb-4"
        controlsList="nodownload"
      >
        <source src="./assets/video/placeholder-video.mp4" type="video/mp4" />
        <!--track
          src="{{ page.content.captionsUrl }}"
          kind="subtitles"
          srclang="en"
          label="English"
          default
          *ngIf="page.content.captionsUrl"
        /-->
      </video>


      <h3 class="text-center mt-4 mb-3">Lower Body: Chair Sit-to-stand</h3>
      <p>
        <strong
          >This exercise is something you do on a daily basis as a functional
          movement, from standing up from a chair, your bed or when in the
          bathroom.</strong
        >
      </p>
      <p>
        It is an exercise that can maximise your independence and whilst
        increasing your hip and leg strength it also has an effect on your core
        muscles, hip control and balance.
      </p>
      <hr />
      <ul>
        <li>Use a sturdy chair.</li>

        <li>
          Sit towards the front of the chair with your feet flat and about hip
          width apart.
        </li>

        <li>Lean forwards slightly.</li>

        <li>Push through your feet to stand up.</li>

        <li>Hold for 2 seconds.</li>

        <li>Slowly lower back to the chair.</li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <h3 class="text-center mt-4 mb-3">Move Level 1 Exercies</h3>
      <div class="row">
        <div class="col-md-6 col-12">
          <h4 class="text-center">Level 1 - Lower Body</h4>
          <div class="card mt-3 mb-4 bg-light rounded">
            <div class="card-body">
              <ul class="link-list">
                <li>
                  <a href="">
                    <span class="icon-wrap more-link text-dark-green"
                      ><fa-icon [icon]="['fas', 'circle-play']"></fa-icon
                    ></span>
                    <span class="link-label">Hip hinge to chair</span>
                    <span class="icon-wrap more-link text-secondary"
                      ><fa-icon
                        [icon]="['fas', 'circle-chevron-right']"
                      ></fa-icon
                    ></span>
                  </a>
                </li>
                <li>
                  <a href="">
                    <span class="icon-wrap more-link text-dark-green"
                      ><fa-icon [icon]="['fas', 'circle-play']"></fa-icon
                    ></span>
                    <span class="link-label">Hip hinge to chair</span>
                    <span class="icon-wrap more-link text-secondary"
                      ><fa-icon
                        [icon]="['fas', 'circle-chevron-right']"
                      ></fa-icon
                    ></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <h4 class="text-center">Level 1 - Upper Body</h4>
          <div class="card mt-3 bg-light rounded">
            <div class="card-body">
              <ul class="link-list">
                <li>
                  <a href="">
                    <span class="icon-wrap more-link text-dark-green"
                      ><fa-icon [icon]="['fas', 'circle-play']"></fa-icon
                    ></span>
                    <span class="link-label">Wall press-up</span>
                    <span class="icon-wrap more-link text-secondary"
                      ><fa-icon
                        [icon]="['fas', 'circle-chevron-right']"
                      ></fa-icon
                    ></span>
                  </a>
                </li>
                <li>
                  <a href="">
                    <span class="icon-wrap more-link text-dark-green"
                      ><fa-icon [icon]="['fas', 'circle-play']"></fa-icon
                    ></span>
                    <span class="link-label">Banded Seated row</span>
                    <span class="icon-wrap more-link text-secondary"
                      ><fa-icon
                        [icon]="['fas', 'circle-chevron-right']"
                      ></fa-icon
                    ></span>
                  </a>
                </li>

                <li>
                  <a href="">
                    <span class="icon-wrap more-link text-dark-green"
                      ><fa-icon [icon]="['fas', 'circle-play']"></fa-icon
                    ></span>
                    <span class="link-label">Unbanded reverse fly</span>
                    <span class="icon-wrap more-link text-secondary"
                      ><fa-icon
                        [icon]="['fas', 'circle-chevron-right']"
                      ></fa-icon
                    ></span>
                  </a>
                </li>
                <li>
                  <a href="">
                    <span class="icon-wrap more-link text-dark-green"
                      ><fa-icon [icon]="['fas', 'circle-play']"></fa-icon
                    ></span>
                    <span class="link-label">Unbanded overhead press</span>
                    <span class="icon-wrap more-link text-secondary"
                      ><fa-icon
                        [icon]="['fas', 'circle-chevron-right']"
                      ></fa-icon
                    ></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
