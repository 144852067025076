<div class="fh bg-light pb-10">
  <img
    *ngIf="page?.image_filename"
    src="{{ page.image_filename | getThumbnail : 'wide' }}"
    alt="Placeholder image"
    class="rounded-lg img-fluid fade-in d-none d-sm-block mb-4"
  />

  <img
    *ngIf="page?.image_filename"
    src="{{ page.image_filename | getThumbnail : 'medium-landscape' }}"
    alt="Placeholder image"
    class="rounded-lg img-fluid fade-in d-block d-sm-none mb-4"
  />

  <div *ngIf="page?.page_type_id == '2' && page" class="video-container">
    <video
      *ngIf="page.content.videoUrl"
      width="100%"
      style="max-height: 80vh"
      poster="{{ page.content.posterUrl }}"
      controls
      preload
      class="rounded-lg img-fluid fade-in mb-4"
      controlsList="nodownload"
      autoplay="true"
    >
      <source src="{{ page.content.videoUrl }}" type="video/mp4" />
      <track
        src="{{ page.content.captionsUrl }}"
        kind="subtitles"
        srclang="en"
        label="English"
        default
        *ngIf="page.content.captionsUrl"
      />
    </video>
  </div>
  <div class="content-container mt-0">
    <div class="container-resource-page">
      <div class="row" *ngIf="page">
        <div class="col">
          
          <div class="start-buttons mt-0 mb-5" *ngIf="!nextPage && (+resourceId>=2 && +resourceId<=11)">
            <!-- start  buttons -->
            <div *ngIf="resourceId == 6 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStartProgramme()"
              >
                Start iPrepWell
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>
            <div *ngIf="resourceId == 1 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStartMoveWell()"
              >
                Start Move Well
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>

            <div *ngIf="resourceId == 2 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStartPrepSteps()"
              >
                Start Prep Steps
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>

            <div *ngIf="resourceId == 3 && !nextPage" class="text-center">
              <button class="btn btn-primary btn-lg" (click)="onStartPrepFit()">
                Start Prep Fit
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>

            <div *ngIf="resourceId == 4 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStartPrepStrong()"
              >
                Start Prep Strong
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>

            <div *ngIf="resourceId == 5 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStartBreatheWell()"
              >
                Start Breathe Well
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>
            <div *ngIf="resourceId == 7 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStartIQuit()"
              >
                Start I-Quit
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>

            <div *ngIf="resourceId == 8 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStart('EatWellIntro',['/modules','eat-well'])"
              >
                Start Eat Well
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>

            <div *ngIf="resourceId == 9 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStart('SleepWellIntro',['/modules','sleep-well'])"
              >
                Start Sleep Well
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>

            <div *ngIf="resourceId == 10 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStart('FeelWellIntro',['/modules','feel-well'])"
              >
                Start Feel Well
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>

            <div *ngIf="resourceId == 11 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStart('DrinkGuideIntro',['/modules','drinking-guide'])"
              >
                Start Drink Guide
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>
            <!-- end start buttons -->
          </div>
          <h2 class="inline-title sm-title text-center mb-4 has-prev-next">
            <a
              (click)="onPrev()"
              class="prev pointer"
              *ngIf="prevPage?.content_page_id"
              [routerLink]="['']"
            >
              <span class="sr-only">Previous Page</span>
              <span class="icon-wrap">
                <fa-icon
                  [icon]="['far', 'arrow-alt-left']"
                  [fixedWidth]="true"
                ></fa-icon>
              </span>
            </a>
            {{ page?.title }}
            <a
              (click)="onNext()"
              class="next pointer"
              *ngIf="nextPage?.content_page_id"
              [routerLink]="['']"
            >
              <span class="sr-only">Next Page</span>
              <span class="icon-wrap">
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </span>
            </a>
          </h2>
          <div (click)="onContentClick($event)">
            <div
              class="read-content"
              [innerHTML]="page?.content | safeHtml"
              *ngIf="page?.page_type_id == '1'"
            ></div>
            <div *ngIf="page?.page_type_id == '2'">
              <div
                class="read-content"
                [innerHTML]="page?.content.content | safeHtml"
              ></div>
            </div>
            <div class="start-buttons">
            <!-- start  buttons -->
            <div *ngIf="resourceId == 6 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStartProgramme()"
              >
                Start iPrepWell
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>
            <div *ngIf="resourceId == 1 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStartMoveWell()"
              >
                Start Move Well
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>

            <div *ngIf="resourceId == 2 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStartPrepSteps()"
              >
                Start Prep Steps
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>

            <div *ngIf="resourceId == 3 && !nextPage" class="text-center">
              <button class="btn btn-primary btn-lg" (click)="onStartPrepFit()">
                Start Prep Fit
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>

            <div *ngIf="resourceId == 4 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStartPrepStrong()"
              >
                Start Prep Strong
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>

            <div *ngIf="resourceId == 5 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStartBreatheWell()"
              >
                Start Breathe Well
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>
            <div *ngIf="resourceId == 7 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStartIQuit()"
              >
                Start I-Quit
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>

            <div *ngIf="resourceId == 8 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStart('EatWellIntro',['/modules','eat-well'])"
              >
                Start Eat Well
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>

            <div *ngIf="resourceId == 9 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStart('SleepWellIntro',['/modules','sleep-well'])"
              >
                Start Sleep Well
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>

            <div *ngIf="resourceId == 10 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStart('FeelWellIntro',['/modules','feel-well'])"
              >
                Start Feel Well
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>

            <div *ngIf="resourceId == 11 && !nextPage" class="text-center">
              <button
                class="btn btn-primary btn-lg"
                (click)="onStart('DrinkGuideIntro',['/modules','drinking-guide'])"
              >
                Start Drink Guide
                <fa-icon
                  [icon]="['far', 'arrow-alt-right']"
                  [fixedWidth]="true"
                ></fa-icon>
              </button>
            </div>
            <!-- end start buttons -->
          </div>
          </div>
          <div class="row prev-next">
            <div class="col text-start">
              <a
                (click)="onPrev()"
                class="btn btn-primary float-start pointer"
                *ngIf="prevPage?.content_page_id"
                [routerLink]="['']"
              >
                <span class="icon-wrap">
                  <fa-icon
                    [icon]="['far', 'arrow-alt-left']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                Previous
              </a>
            </div>
            <div class="col text-end">
              <a
                (click)="onNext()"
                class=" float-end pointer btn btn-primary"
                *ngIf="nextPage?.content_page_id"
                [routerLink]="['']"
              >
                Next
                <span class="icon-wrap">
                  <fa-icon
                    [icon]="['far', 'arrow-alt-right']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
