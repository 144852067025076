<div class="container-inner">
  <h1 class="text-center my-3 welcome">Welcome back Darren</h1>

  <div
    class="bg-dark text-white px-4 py-2 rounded text-center"
    *ngIf="daysToSurgery && daysToSurgery > 0"
  >
    <h2 class="masthead-title mb-1 mt-2">{{ daysToSurgery }} days remaining</h2>
    <div class="d-flex align-items-center">
      <div>
        <span class="icon-wrap me-2"
          ><fa-icon [icon]="['fal', 'hourglass-start']"></fa-icon
        ></span>
      </div>
      <div style="flex-grow: 1" class="relative">
        <span
          style="left: {{ sugeryDateProgress }}%"
          class="icon-wrap bg-primary text-dark d-flex justify-content-center align-items-center rounded-circle progress-tracker-icon"
          ><fa-icon [icon]="['fas', 'user']"></fa-icon
        ></span>
        <div class="progress" style="height: 6px; width: 100%">
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            style="width: {{ sugeryDateProgress }}%"
            [attr.aria-valuenow]="sugeryDateProgress"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
      <div>
        <span class="icon-wrap ms-2"
          ><fa-icon [icon]="['fal', 'flag-checkered']"></fa-icon
        ></span>
      </div>
    </div>
    <div class="masthead-info mt-1" *ngIf="userData && userData.surgery_date">
      Surgery Date: {{ userData.surgery_date | customDate : "ddd Do MMM" }}
    </div>
  </div>

  <h2 class="text-center my-3">
    <span class="icon-wrap icon-circle bg-primary text-dark"
      ><fa-icon [icon]="['fas', 'heartbeat']"></fa-icon
    ></span>
    Move Well<span *ngIf="userData">: <span class="lighter">Week {{ currentWeekNumber }}</span> </span>
  </h2>

  <div class="row">
    <div class="col">
      <div
        class="db-widget"
        style="background-image: url(/assets/img/placeholder-move-well.jpg)"
      >
        <div class="db-widget-content">
          <div class="db-widget-content-item">
            <h3>
              <span class="icon-wrap me-1"
                ><fa-icon [icon]="['fas', 'walking']" [fixedWidth]="true"></fa-icon
              ></span>
              PREP STEPS
              <a
                [routerLink]="['/activity', 'steps']"
                class="stretched-link float-end"
                ><span class="icon-wrap more-link text-primary"
                  ><fa-icon
                    [icon]="['fas', 'circle-chevron-right']"
                  ></fa-icon></span
              ></a>
            </h3>
            <div *ngIf="isLoading">
              <app-loading-spinner [inline]="1" [size]="'small'"></app-loading-spinner>
            </div>
            <ng-container *ngIf="!isLoading">
              <ng-container *ngIf="stepTarget">
                <div class="days-indicator days-indicator-sm">
                  <div class="day" *ngFor="let day of stepWeekData">
                    <div
                      class="day-status"
                      [ngClass]="{
                        'bg-danger':
                          +day.steps < +stepTarget.target && day.date < today,
                        'bg-primary': +day.steps > +stepTarget.target
                      }"
                    ></div>
                    <div class="day-label text-center">
                      {{ day.date | customDate : "dddd" | slice : 0 : 1 }}
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!stepTarget"> Get Started </ng-container>
            </ng-container>
            <hr />
          </div>

          <div class="db-widget-content-item">
            <h3>
              <span class="icon-wrap me-1"
                ><fa-icon [icon]="$any(['fac', 'lunge'])" [fixedWidth]="true"></fa-icon
              ></span>
              PREP STRONG
              <a
                [routerLink]="['/activity', 'move']"
                class="stretched-link float-end"
                ><span class="icon-wrap more-link text-primary"
                  ><fa-icon
                    [icon]="['fas', 'circle-chevron-right']"
                  ></fa-icon></span
              ></a>
            </h3>
            <div *ngIf="isLoading">
              <app-loading-spinner [inline]="1" [size]="'small'"></app-loading-spinner>
            </div>
            <ng-container *ngIf="!isLoading">
              <div class="card-progress">
                <div class="progress progress-sm">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: {{ moveProgress }}%"
                    [attr.aria-valuenow]="moveProgress"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div class="card-progress-title">
                  {{ moveWeekWorkouts }} Of 2 Workouts Complete
                </div>
              </div>
            </ng-container>
            <hr />
          </div>

          <div class="db-widget-content-item">
            <h3>
              <span class="icon-wrap me-1"
                ><fa-icon [icon]="['fas', 'running']" [fixedWidth]="true"></fa-icon
              ></span>
              PREP FIT
              <a
                [routerLink]="['/activity', 'fit']"
                class="stretched-link float-end"
                ><span class="icon-wrap more-link text-primary"
                  ><fa-icon
                    [icon]="['fas', 'circle-chevron-right']"
                  ></fa-icon></span
              ></a>
            </h3>
            <div *ngIf="isLoading">
              <app-loading-spinner [inline]="1" [size]="'small'"></app-loading-spinner>
            </div>
            <ng-container *ngIf="!isLoading">
              <div class="card-progress">
                <div class="progress progress-sm">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: {{ cardioProgress }}%"
                    [attr.aria-valuenow]="cardioProgress"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div class="card-progress-title">
                  {{ cardioMinsWeek }} MINS COMPLETED
                </div>
              </div>
            </ng-container>
            <hr />
          </div>

          <div class="db-widget-content-item">
            <h3>
              <span class="icon-wrap me-1"
                ><fa-icon [icon]="['fas', 'lungs']" [fixedWidth]="true"></fa-icon
              ></span>
              BREATHE WELL
              <a
                [routerLink]="['/activity', 'breathe']"
                class="stretched-link float-end"
                ><span class="icon-wrap more-link text-primary"
                  ><fa-icon
                    [icon]="['fas', 'circle-chevron-right']"
                  ></fa-icon></span
              ></a>
            </h3>
            <div *ngIf="isLoading">
              <app-loading-spinner [inline]="1" [size]="'small'"></app-loading-spinner>
            </div>
            <ng-container *ngIf="!isLoading">
              <div class="days-indicator days-indicator-sm">
                <div class="day" *ngFor="let day of breatheWeekData">
                  <div
                    class="day-status mb-1"
                    [ngClass]="{
                      'bg-primary': day.am.breaths > 0,
                      'bg-white': day.am.breaths < 1
                    }"
                  ></div>
                  <div
                    class="day-status"
                    [ngClass]="{
                      'bg-primary': day.pm.breaths > 0,
                      'bg-white': day.pm.breaths < 1
                    }"
                  ></div>
                  <div class="day-label text-center">
                    {{ day.date | customDate : "dddd" | slice : 0 : 1 }}
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <h2 class="text-center my-3 mt-5">
    <span class="icon-wrap text-dark bg-primary icon-circle"
      ><fa-icon [icon]="['fas', 'user-chart']"></fa-icon
    ></span>
    Module Progress <span *ngIf="userData">: <span class="lighter">Week {{ currentWeekNumber }}</span></span>
  </h2>

  <div class="row">
    <div class="col-md-6">
      <div
        class="db-widget mb-3"
        style="background-image: url(/assets/img/placeholder-i-quit.jpg)"
      >
        <div class="db-widget-content">
          <div class="db-widget-content-item">
            <h3>
              <span class="icon-wrap me-1"
                ><fa-icon [icon]="['far', 'smoking-ban']"></fa-icon
              ></span>
              I-Quit
              <a
                [routerLink]="['/modules', 'i-quit']"
                class="stretched-link float-end"
                ><span class="icon-wrap more-link text-primary"
                  ><fa-icon
                    [icon]="['fas', 'circle-chevron-right']"
                  ></fa-icon></span
              ></a>
            </h3>
            <div *ngIf="isLoading">
              <app-loading-spinner [inline]="1" [size]="'small'"></app-loading-spinner>
            </div>
            <ng-container *ngIf="!isLoading">
              <div class="db-widget-content-title">
                Smoked:
                <span class="text-primary">{{
                  this.smokingTotals.totalCigarettes
                }}</span>
                Cigarettes
              </div>
              <div class="db-widget-content-title">
                Smoke Free:
                <span class="text-primary">{{
                  this.smokingTotals.smokeFreeDays
                }}</span>
                Days
              </div>
              <div class="db-widget-content-title">
                Entered:
                <span class="text-primary">{{
                  this.smokingTotals.daysEntered
                }}</span>
                Days
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      </div>

    <div class="col-md-6">
      <div
        class="db-widget mb-3"
        style="background-image: url(/assets/img/placeholder-drink-guide.jpg)"
      >
        <div class="db-widget-content">
          <div class="db-widget-content-item">
            <h3>
              <span class="icon-wrap me-1"
                ><fa-icon [icon]="['fas', 'martini-glass']"></fa-icon
              ></span>
              Drink Guide
              <a
                [routerLink]="['/modules', 'drinking-guide']"
                class="stretched-link float-end"
                ><span class="icon-wrap more-link text-primary"
                  ><fa-icon
                    [icon]="['fas', 'circle-chevron-right']"
                  ></fa-icon></span
              ></a>
            </h3>
            <div *ngIf="isLoading">
              <app-loading-spinner [inline]="1" [size]="'small'"></app-loading-spinner>
            </div>
            <ng-container *ngIf="!isLoading">
              <div class="db-widget-content-title">
                Alcohol Consumed:
                <span class="text-primary">{{
                  this.drinkTotals.totalUnits
                }}</span>
                Units
              </div>
              <div class="db-widget-content-title">
                Alcohol Free:
                <span class="text-primary">{{
                  this.drinkTotals.drinkFreeDays
                }}</span>
                Days
              </div>
              <div class="db-widget-content-title">
                Entered:
                <span class="text-primary">{{
                  this.drinkTotals.daysEntered
                }}</span>
                Days
              </div>
            </ng-container>
          </div>
        </div>
      </div>


    </div>

    <div class="col-md-6">

      <div
        class="db-widget mb-3"
        style="background-image: url(/assets/img/placeholder-eat-well.jpg)"
      >
        <div class="db-widget-content">
          <div class="db-widget-content-item">
            <h3>
              <span class="icon-wrap me-1"
                ><fa-icon [icon]="['fas', 'cutlery']"></fa-icon
              ></span>
              Eat Well
              <a
                [routerLink]="['/modules', 'eat-well']"
                class="stretched-link float-end"
                ><span class="icon-wrap more-link text-primary"
                  ><fa-icon
                    [icon]="['fas', 'circle-chevron-right']"
                  ></fa-icon></span
              ></a>
            </h3>
            <div *ngIf="isLoading">
              <app-loading-spinner [inline]="1" [size]="'small'"></app-loading-spinner>
            </div>
            <ng-container *ngIf="!isLoading">
              <div class="db-widget-content-title">
                Score:
                <span class="text-primary"
                  >{{ this.eatWellTotals.weekScore }}/35</span
                >
              </div>
              <div class="db-widget-content-title">
                Entered:
                <span class="text-primary">{{
                  this.eatWellTotals.daysEntered
                }}</span>
                Days
              </div>
            </ng-container>
          </div>
        </div>
      </div>


    </div>

    <div class="col-md-6">

      <div
        class="db-widget mb-3"
        style="background-image: url(/assets/img/placeholder-sleep-well.jpg)"
      >
        <div class="db-widget-content">
          <div class="db-widget-content-item">
            <h3>
              <span class="icon-wrap me-1"
                ><fa-icon [icon]="['fas', 'face-sleeping']"></fa-icon
              ></span>
              Sleep Well
              <a
                [routerLink]="['/modules', 'sleep-well']"
                class="stretched-link float-end"
                ><span class="icon-wrap more-link text-primary"
                  ><fa-icon
                    [icon]="['fas', 'circle-chevron-right']"
                  ></fa-icon></span
              ></a>
            </h3>
            <div *ngIf="isLoading">
              <app-loading-spinner [inline]="1" [size]="'small'"></app-loading-spinner>
            </div>
            <ng-container *ngIf="!isLoading">
              <div class="db-widget-content-title">
                Avg. Score:
                <span class="text-primary"
                  >{{ this.sleepTotal.average  }}/10</span
                >
              </div>
              <div class="db-widget-content-title">
                Entered:
                <span class="text-primary">{{
                  this.sleepTotal.totalDays
                }}</span>
                Days
              </div>
            </ng-container>
          </div>
        </div>
      </div>


    </div>

    <div class="col-md-6">


      <div
        class="db-widget mb-3"
        style="background-image: url(/assets/img/placeholder-feel-well.jpg)"
      >
        <div class="db-widget-content">
          <div class="db-widget-content-item">
            <h3>
              <span class="icon-wrap me-1"
                ><fa-icon [icon]="['fas', 'face-smile-relaxed']"></fa-icon
              ></span>
              Feel Well
              <a
                [routerLink]="['/modules', 'feel-well']"
                class="stretched-link float-end"
                ><span class="icon-wrap more-link text-primary"
                  ><fa-icon
                    [icon]="['fas', 'circle-chevron-right']"
                  ></fa-icon></span
              ></a>
            </h3>
            <div *ngIf="isLoading">
              <app-loading-spinner [inline]="1" [size]="'small'"></app-loading-spinner>
            </div>
            <ng-container *ngIf="!isLoading">
              <div class="db-widget-content-title">
                Avg. Score:
                <span class="text-primary"
                  >{{ this.feelTotal.average  }}/10</span
                >
              </div>
              <div class="db-widget-content-title">
                Entered:
                <span class="text-primary">{{
                  this.feelTotal.totalDays
                }}</span>
                Days
              </div>
            </ng-container>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
