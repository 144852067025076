import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { UserDrinksService } from 'src/app/services/user-drinks.service';


@Component({
  selector: 'app-drinking-guide-add-entry',
  templateUrl: './drinking-guide-add-entry.component.html',
  styleUrls: ['./drinking-guide-add-entry.component.scss']
})
export class DrinkingGuideAddEntryComponent implements OnInit {

  isLoading = false;
  bsDate = new Date();
  maxDate = new Date();
  userData: any;
  submitted = false;
  allEntries:any;
  iDrank:boolean | null = null;
  totalUnits = 0;
  totalDrinks = 0;


  

  drinkTypes:any;
  drinkTotals:any = {};


  noDrink = false;
  nicotineReplacement = false;
  drinkCessation = false;
  totalCigarettes = 0;

  @ViewChild('entryForm', { static: false }) entryForm?: NgForm;
  constructor(
    private userDrinkService: UserDrinksService,
    private router: Router,
    private userService: UserService,
    private el: ElementRef
  ) {

    this.drinkTypes = userDrinkService.drinkTypes;

  this.drinkTypes.forEach((drink:any)=>{
    this.drinkTotals[drink.id] = 0;
  });
  }

  ngOnInit(): void {
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
    });
  }

  onSubmitForm() {
    this.userDrinkService
      .create(
        moment(this.entryForm?.value.date).format('YYYY-MM-DD'),
        +this.totalDrinks,
        +this.totalUnits,
        JSON.stringify(this.drinkTotals),
      )
      .subscribe((responseData) => {
        window.scroll(0, 0);
        this.submitted = true;
        /*
        // refresh it
        Swal.fire({
          icon: 'success',
          title: 'Workout Added',
          text: 'Your workout was added',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/activity', 'fit', 'level', this.userData.fit_level]));*/
      });
  }

    // validattion stuff
    public scrollToFirstInvalidControl() {
      const firstInvalidControl: HTMLElement =
        this.el.nativeElement.querySelector('form .ng-invalid');
  
      if (firstInvalidControl) {
        firstInvalidControl.focus();
        window.scroll({
          top: this.getTopOffset(firstInvalidControl),
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  
    public getTopOffset(controlEl: HTMLElement): number {
      const labelOffset = 150;
      return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }
  
    @HostListener('window:beforeunload')
    canDeactivate(): Observable<any> | any {
      // insert logic to check if there are pending changes here;
      // returning true will navigate without confirmation
      // returning false will show a confirm dialog before navigating away
      if (this.submitted) {
        return true;
      } else if (
        this.totalCigarettes == 0 && this.noDrink == false
      ) {
        return true;
      }
    }

    onPlusDrink(drink:any){
      this.drinkTotals[drink.id]++;
      this.totalDrinks++;
      this.totalUnits += drink.units;

    }

    onMinusDrink(drink:any){

      if( this.drinkTotals[drink.id] >0){
        this.drinkTotals[drink.id]--;
        this.totalDrinks--;
        this.totalUnits -= drink.units;
      }
    }

    resetTotals(){
      this.drinkTypes.forEach((drink: any) => {
        this.drinkTotals[drink.id] = 0;
      });
      this.totalDrinks = 0;
      this.totalUnits = 0;
    }

}
