<nav class="subnav">
  <div class="prev">
    <a [routerLink]="['/programme']"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-left']"></fa-icon></span
    ></a>
  </div>
  <h2>Task List</h2>
  <div class="next">
    <a [routerLink]="['/programme', 'progress']">
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'circle-arrow-right']"></fa-icon></span
    ></a>
  </div>
</nav>
<div class="container-inner">
  <div class="card bg-light mt-3">
    <div class="card-body">
      <tabset [justified]="true">
        <tab id="tab1">
          <ng-template tabHeading
            >
            Activity
            <span class="circle-num text-white bg-dark">8</span></ng-template
          >
          <ul class="link-list mt-3">
            <li>
              <a [routerLink]="['/activity', 'steps']">
                <span class="circle-num">1</span>
                <span class="icon-wrap more-link text-dark-green"
                  ><fa-icon [icon]="['fas', 'walking']"></fa-icon
                ></span>
                <span class="link-label"
                  ><strong class="text-secondary">Prep Steps:</strong> Build up
                  steps to 10,000 a day</span
                >
                <span class="icon-wrap more-link text-secondary"
                  ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                ></span>
              </a>
            </li>
            <li>
              <a [routerLink]="['/activity', 'fit']">
                <span class="circle-num">2</span>
                <span class="icon-wrap more-link text-dark-green"
                  ><fa-icon [icon]="['fas', 'running']"></fa-icon
                ></span>
                <span class="link-label"
                  ><strong class="text-secondary">Prep Fit:</strong> Complete
                  Level 1</span
                >
                <span class="icon-wrap more-link text-dark-green"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span>
              </a>
            </li>
            <li>
              <a [routerLink]="['/activity', 'move']">
                <span class="circle-num">2</span>
                <span class="icon-wrap more-link text-dark-green"
                  ><fa-icon [icon]="$any(['fac', 'lunge'])"></fa-icon
                ></span>
                <span class="link-label"
                  ><strong class="text-secondary">Move Well:</strong> Complete
                  Level 1</span
                >
                <span class="icon-wrap more-link text-secondary"
                  ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                ></span>
              </a>
            </li>
          </ul>

          <div class="stat p-2">
            <div class="progress bg-light mb-1">
              <div
                class="progress-bar bg-primary"
                role="progressbar"
                style="width: 25%"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div class="stat-title-subtext">
              <strong>1 of 8 Activity Tasks</strong> completed
            </div>
          </div>
        </tab>
        <tab id="tab2">
          <ng-template tabHeading
            >Modules
            <span class="circle-num text-white bg-dark">5</span></ng-template
          >
        </tab>
        <tab id="tab3">
          <ng-template tabHeading
            >Other
            <span class="circle-num text-white bg-dark">3</span></ng-template
          >
        </tab>
      </tabset>
    </div>
  </div>
</div>
