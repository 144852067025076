import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserFeel } from '../models/user-feel.model';
import { DateHelperService } from './date-helper.service';

@Injectable({
  providedIn: 'root'
})
export class UserFeelsService {
  error = new Subject<string>();
  slug = '/user_feels';
  resourceName = 'user_feels';
  weeklyTarget = {
    threshold: 1,
    days: 3,
  };
  allWorkouts?: Observable<UserFeel[]> | null = null;
  workoutsBetween?: any;

  constructor(
    private http: HttpClient,
    private dateHelperService: DateHelperService
  ) {}

  fetchBetween(startDate: Date, endDate: Date): Observable<UserFeel[]> {
    if (!this.workoutsBetween) {
      this.workoutsBetween = {};
    }

    if (
      !this.workoutsBetween[
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
          '_' +
          this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      ]
    ) {
      let searchParams = new HttpParams();

      searchParams = searchParams.append(
        'startDate',
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD')
      );
      searchParams = searchParams.append(
        'endDate',
        this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      );
      this.workoutsBetween[
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
          '_' +
          this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      ] = this.http
        .get<any>(environment.apiUrl + this.slug, {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserFeel[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.workoutsBetween[
      this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
        '_' +
        this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
    ];
  }
  fetchAll(): Observable<UserFeel[]> {
    if (!this.allWorkouts) {
      this.allWorkouts = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserFeel[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                // parse the exercise data
                if (item.exercises) {
                  item.exercises = JSON.parse(item.exercises);
                }
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allWorkouts;
  }

  clearCache() {
    this.allWorkouts = null;
    this.workoutsBetween = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const item = new UserFeel(
            +responseData.user_feel_id,
            +responseData.user_id,
            responseData.date_recorded,
            +responseData.mood,
            +responseData.worry,
            responseData.stress,
            responseData.created,
            responseData.modified
          );
          return item;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    date_recorded: string,
    mood: number,
    worry: number,
    stress: number,
  ) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      mood,
      worry,
      stress,
    };
    return this.http.post<UserFeel>(
      environment.apiUrl + this.slug,
      payload,
      {
        observe: 'response',
      }
    );
  }

  update(
    id: number,
    date_recorded: string,
    mood: number,
    worry: number,
    stress: number,
  ) {
    this.clearCache();
    const payload = {
      date_recorded: moment(date_recorded).format('YYYY-MM-DD'),
      mood,
      worry,
      stress,
    };
    return this.http.patch<UserFeel>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }

  generateWeekArray(currentWeek: any, data: any) {
    const weekArray: any = [];
    currentWeek.forEach((day: any) => {
      let currentDay = {
        date: moment(day).format('YYYY-MM-DD'),
        mood: 0,
        worry: 0,
        stress: 0,
        hasData:0,
        score:0,
        entryId:0
      };
      data.forEach((item: UserFeel) => {
        if (item.date_recorded === currentDay.date) {
          currentDay.mood = +item.mood;
          currentDay.worry = +item.worry;
          currentDay.stress = +item.stress;
          currentDay.hasData = 1;
          currentDay.entryId = item.user_feel_id;
          currentDay.score = Math.round(((+item.mood + +item.worry + +item.stress)/3)*10)/10;
        }
      });
      weekArray.push(currentDay);
    });
    return weekArray;
  }

  getWeekScore(weekArray: any) {
    let weekScore:any = {
      mood:0,
      worry:0,
      stress:0,
      totalDays:0,
      average:0
    }
    weekArray.forEach((day:any) => {
      if(day.hasData == 1){
        weekScore.mood+= +day.mood;
        weekScore.worry+= +day.worry;
        weekScore.stress+= +day.stress;
        weekScore.totalDays++;
      }
    });

    if(weekScore.totalDays > 0){
      weekScore.average = Math.round(((+weekScore.mood + +weekScore.stress + +weekScore.worry)/3) / weekScore.totalDays*10)/10;
    }
    return weekScore;
  }
  
  getWeekAvg(weekArray:any[], name:string){
    let score = 0;
    let entries = 0;
    let average = 0;
    weekArray.forEach((day:any) => {
      if(day.hasData == 1 && day[name]){
        entries++;
        score += +day[name];
      }
    });
    if(entries>0){
      average =  Math.round((score/entries)*10)/10;
    }
    return average;
  }

  onlyUnique(value: any, index: number, self: any) {
    return self.indexOf(value) === index;
  }

  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
