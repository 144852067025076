<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <h2>Overview</h2>
</nav>

<div class="container container-inner">
  <div class="row">
    <div class="col-12 col-md-12">
      <div class="card mt-3 bg-dark text-white mb-3">
        <img
          src="/assets/img/placeholder-eat-well.jpg"
          class="card-img-top card-img-top-fh"
          alt="..."
        />
        <div class="card-body text-center">
          <h3 class="card-title card-title-lg">Healthy Eating For Surgery</h3>
          <p class="card-text">
            Track and improve your nutritional intake by consuming a balanced
            diet of core food groups
          </p>
        </div>
      </div>
     
      <button
        class="btn btn-primary w-100"
        [routerLink]="['/modules', 'eat-well', 'tracker']"
      >
       
        Nutrition Tracker
      </button>
    </div>
    <div class="col-12 col-md-6" *ngIf="userData">
      <!-- Moved 
      <h4 class="text-center mt-4 mb-2">Nutrition Tracker</h4>
      <div class="card bg-light">
        <div class="card-body">
          <div class="stat">
            <h5 class="stat-title text-center">Daily Scores This Week</h5>
            <div class="days-indicator days-indicator-md text-center">
              <div class="day" *ngFor="let day of eatWellWeekData">
                <div class="day-label">
                  {{ day.date | customDate : "dddd" | slice : 0 : 1 }}
                </div>

                <div
                  class="day-status-lg day-status-circle bg-dark text-white"
                  *ngIf="day.date <= today && day.score > 0"
                >
                  <div class="day-status-title">{{ day.score }}</div>
                </div>
                <div
                  class="day-status-lg day-status-circle bg-dark text-white"
                  *ngIf="day.date <= today && day.score < 1"
                >
                  <div class="day-status-title">-</div>
                </div>
                <div
                  class="day-status-lg day-status-circle bg-dark text-white"
                  *ngIf="day.date > today"
                ></div>
              </div>
            </div>
            <div class="card-total card-total-inline mt-3 mb-2 card-total-light card-total-lg card-total-nb bg-dark">
                <span>{{weekScore}}</span> / <span class="opacity-75">35</span>
              </div>
          </div>

          
        </div>
        <div class="card-footer text-center relative text-dark bg-secondary">
          <a
            [routerLink]="['/activity', 'fit', 'level', '1']"
            class="stretched-link"
            >View &nbsp;
            <span class="icon-wrap more-link text-dark"
              ><fa-icon
                [icon]="['fas', 'circle-chevron-right']"
              ></fa-icon></span
          ></a>
        </div>
      </div>
-->
      <div class="col-12 col-md-6">
        <h4 class="text-center mt-4 mb-2">Module Task List</h4>

        <div class="card bg-light">
          <div class="card-body">
            <ul class="link-list">
              <li>
                <a >
                  <span class="circle-num">1</span>
                  <span class="icon-wrap more-link text-dark-green"
                    ><fa-icon [icon]="['fas', 'play-circle']"></fa-icon
                  ></span>
                  <span class="link-label"
                    >Nutrition, healthy eating and surgery</span
                  >
                  <span class="icon-wrap more-link text-secondary"
                    ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                  ></span>
                </a>
              </li>
              <li>
                <a>
                  <span class="circle-num">2</span>
                  <span class="icon-wrap more-link text-dark-green"
                    ><fa-icon [icon]="['fas', 'play-circle']"></fa-icon
                  ></span>
                  <span class="link-label"
                    >Putting it all together</span
                  >
                  <span class="icon-wrap more-link text-secondary"
                    ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                  ></span>
                </a>
              </li>
              <li>
                <a>
                  <span class="circle-num">3</span>
                  <span class="icon-wrap more-link text-dark-green"
                    ><fa-icon [icon]="['fas', 'play-circle']"></fa-icon
                  ></span>
                  <span class="link-label"
                    >How to eat healthily</span
                  >
                  <span class="icon-wrap more-link text-secondary"
                    ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                  ></span>
                </a>
              </li>
              <li>
                <a>
                  <span class="circle-num">4</span>
                  <span class="icon-wrap more-link text-dark-green"
                    ><fa-icon [icon]="['fas', 'play-circle']"></fa-icon
                  ></span>
                  <span class="link-label"
                    >Healthy eating monitoring</span
                  >
                  <span class="icon-wrap more-link text-secondary"
                    ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                  ></span>
                </a>
              </li>
              <li>
                <a>
                  <span class="circle-num">5</span>
                  <span class="icon-wrap more-link text-dark-green"
                    ><fa-icon [icon]="['far', 'file-chart-pie']"></fa-icon
                  ></span>
                  <span class="link-label"
                    >Infographic</span
                  >
                  <span class="icon-wrap more-link text-dark-green"
                    ><fa-icon [icon]="['fas', 'check']"></fa-icon
                  ></span>
                </a>
              </li>
              <li>
                <a >
                  <span class="circle-num">6</span>
                  <span class="icon-wrap more-link text-dark-green"
                    ><fa-icon [icon]="['far', 'ballot-check']"></fa-icon
                  ></span>
                  <span class="link-label"
                    >Goal setting and planning</span
                  >
                  <span class="icon-wrap more-link text-secondary"
                    ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                  ></span>
                </a>
              </li>
              <li>
                <a >
                  <span class="circle-num"><fa-icon [icon]="['far', 'repeat']"></fa-icon
                    ></span>
                  <span class="icon-wrap more-link text-dark-green"
                    ><fa-icon [icon]="['fas', 'cutlery']"></fa-icon
                  ></span>
                  <span class="link-label"
                    >Add Entries to the nutrition tracker</span
                  >
                  <span class="icon-wrap more-link text-secondary"
                    ><fa-icon [icon]="['fas', 'circle-chevron-right']"></fa-icon
                  ></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
