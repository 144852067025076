import { ForumCategory } from './forumCategory.model';
import { ForumService } from './forum.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.scss']
})
export class ForumComponent implements OnInit {

  isLoading = false;
  error:any;
  private errorSub!: Subscription;
  categories: ForumCategory[] = [];

  constructor(private forumService: ForumService) { }

  ngOnInit(): void {
    this.errorSub = this.forumService.error.subscribe((errorMessage) => {
      this.error = errorMessage;
    });
    this.forumService.fetchCategories().subscribe(
      (forumCategories) => {
        this.categories = forumCategories;
      },
      (error) => {
        this.error = error.message;
      });


  }

}


