import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { UserDrink } from 'src/app/models/user-drink.model';
import { UserDrinksService } from 'src/app/services/user-drinks.service';

@Component({
  selector: 'app-drinking-guide-edit-entry',
  templateUrl: './drinking-guide-edit-entry.component.html',
  styleUrls: ['./drinking-guide-edit-entry.component.scss'],
})
export class DrinkingGuideEditEntryComponent implements OnInit {
  isLoading = false;
  maxDate = new Date();
  userData: any;
  submitted = false;
  allEntries: any;
  iDrank: boolean | null = null;
  totalUnits = 0;
  totalDrinks = 0;

  drinkTypes: any;
  drinkTotals: any = {};

  noDrink = false;
  nicotineReplacement = false;
  drinkCessation = false;
  totalCigarettes = 0;
  entryId: any;
  userDrink: UserDrink | undefined;
  entryDate = new Date();

  @ViewChild('entryForm', { static: false }) entryForm?: NgForm;
  constructor(
    private userDrinkService: UserDrinksService,
    private router: Router,
    private userService: UserService,
    private el: ElementRef,
    private route: ActivatedRoute
  ) {
    this.drinkTypes = userDrinkService.drinkTypes;

    this.drinkTypes.forEach((drink: any) => {
      this.drinkTotals[drink.id] = 0;
    });
  }

  ngOnInit(): void {
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
    });

    this.route.params.subscribe((params) => {
      this.entryId = params.entryId;
      this.isLoading = true;
      forkJoin({
        userDrink: this.userDrinkService.fetch(this.entryId),
      }).subscribe(
        (responseData) => {
          this.isLoading = false;
          this.userDrink = responseData.userDrink;
          this.entryDate = moment(this.userDrink.date_recorded).toDate();
          if (this.userDrink.units > 0) {
            this.iDrank = true;
          } else {
            this.iDrank = false;
          }
          let userDrinks = JSON.parse(this.userDrink.drink_details);
          for (let key in userDrinks) {
            this.drinkTotals[key] = userDrinks[key];
            this.totalDrinks += userDrinks[key];
            this.totalUnits += +(+this.userDrinkService.getDrinkTypeVal(key, 'units')* +userDrinks[key]);
          }
        },
        (error) => {
          // this.errors.push(error.message);
        }
      );
    });
  }

  onSubmitForm() {
    this.userDrinkService
      .update(
        this.entryId,
        moment(this.entryForm?.value.date).format('YYYY-MM-DD'),
        +this.totalDrinks,
        +this.totalUnits,
        JSON.stringify(this.drinkTotals)
      )
      .subscribe((responseData) => {
        window.scroll(0, 0);
        this.submitted = true;
        /*
        // refresh it
        Swal.fire({
          icon: 'success',
          title: 'Workout Added',
          text: 'Your workout was added',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/activity', 'fit', 'level', this.userData.fit_level]));*/
      });
  }

  // validattion stuff
  public scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector('form .ng-invalid');

    if (firstInvalidControl) {
      firstInvalidControl.focus();
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  public getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<any> | any {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if (this.submitted) {
      return true;
    } else if (this.totalCigarettes == 0 && this.noDrink == false) {
      return true;
    }
  }

  onPlusDrink(drink: any) {
    this.drinkTotals[drink.id]++;
    this.totalDrinks++;
    this.totalUnits += drink.units;
  }

  onMinusDrink(drink: any) {
    if (this.drinkTotals[drink.id] > 0) {
      this.drinkTotals[drink.id]--;
      this.totalDrinks--;
      this.totalUnits -= drink.units;
    }
  }

  resetTotals(){
    this.drinkTypes.forEach((drink: any) => {
      this.drinkTotals[drink.id] = 0;
    });
    this.totalDrinks = 0;
    this.totalUnits = 0;
  }
}
