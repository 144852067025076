import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-difficulty-icon',
  templateUrl: './difficulty-icon.component.html',
  styleUrls: ['./difficulty-icon.component.scss']
})
export class DifficultyIconComponent implements OnInit {
  @Input() difficulty:number = 0;
  constructor() { }

  ngOnInit(): void {
    this.difficulty = Math.round(this.difficulty);
  }

}
