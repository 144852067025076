import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.scss']
})
export class MfaComponent implements OnInit {
  isLoading = false;
  error = null;
  testSite = false;

  constructor() { }

  ngOnInit(): void {
    // are we logged in?
    this.testSite = environment.testSite;
    
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
  }

}
