<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<nav class="subnav">
  <h2>Add Entry</h2>
</nav>

<div class="container container-inner">
  <div class="row">
    <div class="col-12">
      <form
        name="entryForm"
        #entryForm="ngForm"
        class="standard-form mt-4"
        [hidden]="submitted"
      >
        <div class="row">
          <div class="col-12">
            <div class="form-group text-center">
              <label for="date">Date</label>
              <div class="form-group-inner">
                <input
                  type="text"
                  id="date"
                  class="form-control form-control-stub"
                  [ngModel]="entryDate"
                  #date="ngModel"
                  name="date"
                  placeholder="DD/MM/YYYY"
                  required
                  date
                  bsDatepicker
                  autocomplete="off"
                  [maxDate]="maxDate"
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY',
                    containerClass: 'theme-prepwell',
                    showWeekNumbers: false
                  }"
                />
                <div
                  class="alert alert-danger"
                  *ngIf="!date.valid && date.touched"
                >
                  Please enter a date above
                </div>
              </div>
            </div>

            <div class="form-group text-center">
              <label for="intensity">Quality of Sleep</label>
              <div class="form-group-inner">
                <p class="card-text mt-2 mb-0">
                  Please rate the quality of your sleep by dragging the cursor
                  below.
                </p>
                <div class="temp-slider">
                  <ngx-slider [(value)]="quality" [options]="options"></ngx-slider>

                  <div class="slider-custom-indicators">
                    <div class="indicator">
                      <span class="icon-arrow">
                        <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                      </span>
                      A bad night's sleep
                    </div>
                    <div class="indicator">
                      A good night's sleep
                      <span class="icon-arrow">
                        <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group text-center">
                <label for="intensity">Getting to sleep</label>
                <div class="form-group-inner">
                  <p class="card-text mt-2 mb-0">
                    Please rate the difficulty of getting to sleep below.
                  </p>
                  <div class="temp-slider">
                    <ngx-slider [(value)]="gettingToSleep" [options]="options"></ngx-slider>
  
                    <div class="slider-custom-indicators">
                      <div class="indicator">
                        <span class="icon-arrow">
                          <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                        </span>
                        Just could never fall asleep
                      </div>
                      <div class="indicator">
                        Fell asleep almost immediately
                        <span class="icon-arrow">
                          <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group text-center">
                <label for="intensity">Awakening from sleep in the night</label>
                <div class="form-group-inner">
                  <p class="card-text mt-2 mb-0">
                    Please rate how awake you were in the night after getting to sleep.
                  </p>
                  <div class="temp-slider">
                    <ngx-slider [(value)]="awakening" [options]="options"></ngx-slider>
  
                    <div class="slider-custom-indicators">
                      <div class="indicator">
                        <span class="icon-arrow">
                          <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                        </span>
                        Awake all night long
                      </div>
                      <div class="indicator">
                        Awake very little
                        <span class="icon-arrow">
                          <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            
            

            
            

            <div class="form-group text-center">
              <label>&nbsp;</label>
              <div>
                <button
                  (click)="onSubmitForm()"
                  class="btn btn-primary w-100"
                  [disabled]="date.invalid"
                >
                  Submit Entry
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div [hidden]="!submitted">
        <div class="submit-confirm">
          <h3>Excellent!</h3>
          <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'thumbs-up']"></fa-icon
          ></span>
          <h4>Entry Details</h4>
          <div class="entry-details">
            <div>
              <div class="label">Date</div>
              <div class="data">
                {{ date.value | customDate : "MMMM Do YYYY, h:mm:ss a" }}
              </div>
            </div>
            <div>
              <div class="label">Quality</div>
              <div class="data">{{ quality }}</div>
            </div>
            <div>
              <div class="label">Getting to Sleep</div>
              <div class="data">{{ gettingToSleep }}</div>
            </div>
            <div>
              <div class="label">Awakening</div>
              <div class="data">{{ awakening }}</div>
            </div>
          </div>
          <button
            class="btn btn-primary w-100 mt-4"
            [routerLink]="['/modules', 'sleep-well', 'tracker']"
          >
            Back to Sleep Well
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
