import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { AuthService } from './../service/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  isLoading = false;
  error: string = '';
  changed = false;
  password1 = '';
  password2 = '';

  constructor(private authService: AuthService, private router: Router) {}
  ngOnInit(): void {}

  onSubmit(form: NgForm) {
    this.error = '';
    if (!form.valid) {
      return;
    }
    const currentPassword = form.value.currentPassword;
    // const password = form.value.password;
    // const passwordConfirm = form.value.passwordConfirm;
    const newPassword = this.password1;
    this.isLoading = true;
    const changePasswordObs = this.authService.changePassword(
      currentPassword,
      newPassword
    );
    changePasswordObs.subscribe(
      (resData) => {
        this.isLoading = false;
        this.changed = true;
        this.router.navigate(['/dashboard']);
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Details updated',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          toast: true
        });
      },
      (errorMessage) => {
        this.error = errorMessage;
        this.isLoading = false;
      }
    );
  }

  checkPassword(password:string, check:string) {
    return this.authService.checkPassword(password, check);
  }
}
